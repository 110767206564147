import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignDetailsThunk } from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import CampaignDetails from "./CampaignDetails";
import CampaignReviewDetails from "./CampaignReviewDetails";

const CampaignDetailsParent = (props: any) => {
  document.title = "Signal House Portal Campaign";
  const dispatch = useDispatch<any>();

  const [isInit, setIsInit] = useState<boolean>(true);

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (campaigns) => ({
      loading: campaigns.loading,
      campaignDetails: campaigns.campaignDetails,
    })
  );

  const { loading, campaignDetails } = useSelector(selectProfile);

  useEffect(() => {
    if (props?.router?.params?.id) {
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false));
    }
  }, [props?.router?.params?.id, props.router.location]);

  useEffect(() => {
    if (isInit) {
      setIsInit(loading);
    }
  }, [loading]);

  return isInit ? (
    <div className="page-content">
      <div className="position-relative wrapper">
        <img
          src={Loader}
          className={`position-absolute top-50 start-50 translate-middle`}
          alt="loading"
        />
      </div>
    </div>
  ) : campaignDetails?.campaignId?.toLowerCase() === "pending" ||
    campaignDetails?.campaignId?.toLowerCase() === "rejected" ||
    campaignDetails?.campaignId?.toLowerCase() === "approved" ? (
    <CampaignReviewDetails
      isRejected={campaignDetails?.campaignId?.toLowerCase() === "rejected"}
    />
  ) : campaignDetails?.campaignId ? (
    <CampaignDetails />
  ) : null;
};

export default withRouter(CampaignDetailsParent);
