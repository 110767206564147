import { Col, Row, Button, Spinner } from "react-bootstrap";
import withRouter from "Common/withRouter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { submitLoadingSubject } from "Common/modals/Modal";
import Loader from "assets/images/spinner-dark.svg";
import {
  openModal,
  releasePhoneNumberThunk,
  getDetailsOfOwnNumber,
  setOwnNumberDetailInitialState,
  configureNumbers,
  getCampaignListThunk,
} from "slices/thunk";
import Tooltip from "rc-tooltip";
import { dateFormat, removeEmptyAndNullValues } from "helpers/common";
import { createSelector } from "reselect";
import { brandListApi, subgroupListApi } from "helpers/apiroute_helper";
import { getLocal } from "helpers/services/auth/auth";

const NumberProperties = ({ router }: any) => {
  const dispatch = useDispatch<any>();
  const { phoneNumber, sid } = useParams();

  const [details, setDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [returnsEnabled, setReturnsEnabled] = useState(false);
  const [pendingEnableState, setPendingEnableState] = useState(false);
  const [subgrpData, setSubgrpData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const phoneNumberDetails = useSelector(
    (state: any) => state?.Numbers?.numberDetails
  );

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (campaigns) => ({
      campaignsData: campaigns.campaignsData,
    })
  );

  const { campaignsData } = useSelector(selectProfile);

  useEffect(() => {
    dispatch(setOwnNumberDetailInitialState());
  }, []);

  useEffect(() => {
    if (phoneNumberDetails) {
      setDetails(phoneNumberDetails);
      setReturnsEnabled(
        phoneNumberDetails.numberStatus === "Active" ? false : true
      );
    }
  }, [phoneNumberDetails]);

  useEffect(() => {
    getOwnPhoneNumber();
  }, [phoneNumber]);

  useEffect(() => {
    details?.subGroupId?.length > 0 && fetchSubGroupNames(details?.subGroupId);
  }, [details?.subGroupId]);

  useEffect(() => {
    details?.brandId && fetchBrandNames(details?.brandId);
  }, [details?.brandId]);

  useEffect(() => {
    details?.campaignId &&
      dispatch(
        getCampaignListThunk(1, 2, {
          campaignId: details?.campaignId,
        })
      );
  }, [details?.campaignId]);

  const getOwnPhoneNumber = () => {
    setLoading(true);
    dispatch(
      getDetailsOfOwnNumber({
        phoneNumber: phoneNumber,
      })
    ).then(() => setLoading(false));
  };

  const handleShow = () => {
    dispatch(
      openModal({
        path: "EditTags",
        data: {
          title: "Tags",
          outSideData: false,
          detailsTags: details.tags,
          phoneNumber,
          getOwnPhoneNumber,
          footer: false,
        },
      })
    );
  };

  const handleShowfriendlyName = () => {
    dispatch(
      openModal({
        path: "AddFriendlyName",
        data: {
          title: "Add Friendly Name",
          detailsfriendlyName: details.friendlyName,
          outSideData: false,
          phoneNumber,
          getOwnPhoneNumber,
          footer: false,
        },
      })
    );
  };

  const handleReleaseClick = () => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to release this number?",
        data: {
          title: "Release Number",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Release",
          },
          onSubmit: ({ onClose }: any) => {
            const cb = () => {
              getLocal("numberSortingRoute")
                ? router.navigate(getLocal("numberSortingRoute"))
                : router.navigate("/mynumbers");
            };

            submitLoadingSubject.next(true);
            dispatch(
              releasePhoneNumberThunk(
                {
                  phoneNumbers: [`${phoneNumber}`],
                },
                cb
              )
            ).then(() => {
              submitLoadingSubject.next(false);
              onClose();
            });
          },
        },
      })
    );
  };

  const handleEnableDisableClick = () => {
    setPendingEnableState(!returnsEnabled);

    dispatch(
      openModal({
        modalbody: `Are you sure you want to ${
          returnsEnabled ? "activate" : "deactivate"
        } this number?`,
        data: {
          title: "Change Status",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: returnsEnabled ? "Activate" : "Deactivate",
          },
          onSubmit: ({ onClose }: any) => {
            const cb = () => {
              dispatch(
                getDetailsOfOwnNumber({
                  phoneNumber: phoneNumber,
                })
              );
            };

            submitLoadingSubject.next(true);
            dispatch(
              configureNumbers(
                {
                  numberStatus: returnsEnabled ? "Active" : "Inactive",
                  phoneNumber: phoneNumber,
                  sid: sid,
                },
                cb
              )
            ).then(() => {
              submitLoadingSubject.next(false);
              onClose();
            });
          },
        },
      })
    );
  };

  const fetchSubGroupNames = async (ids: any) => {
    if (ids.length > 0) {
      try {
        let data: any = await subgroupListApi(
          1,
          100,
          removeEmptyAndNullValues({
            groupId: details?.groupId || "",
            subGroupId: ids.length > 0 ? ids : "",
          })
        );

        if (!data?.code && !data?.errorCode) {
          setSubgrpData(data?.records || []);
        }
      } catch (error: any) {
        console.error("Error fetching sub group names:", error);
      }
    } else {
      setSubgrpData([]);
    }
  };

  const fetchBrandNames = async (ids: any) => {
    if (ids.length > 0) {
      try {
        let data: any = await brandListApi(
          1,
          100,
          removeEmptyAndNullValues({
            brandId: ids,
          })
        );

        if (!data?.code && !data?.errorCode) {
          setBrandData(data?.records || []);
        }
      } catch (error: any) {
        console.error("Error fetching brand names:", error);
      }
    } else {
      setBrandData([]);
    }
  };

  return (
    <div className="container-fluid">
      <div style={{ minHeight: "550px" }}>
        {loading ? (
          <div>
            <img
              src={Loader}
              className={`position-absolute top-50 start-50 translate-middle`}
            />
          </div>
        ) : (
          <>
            <div className="mb-1">
              <h5>Number Properties</h5>
            </div>
            <Col lg={12} className="my-3">
              <Row className="border bg-light-gray rounded-3 p-3">
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">
                    Tags:
                    <i
                      className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
                      style={{
                        WebkitTextStroke: "0.5px",
                      }}
                      onClick={handleShow}
                    />
                  </h6>
                  <div className="d-flex flex-wrap gap-2">
                    {details.tags?.map((tag: string, i: number) => (
                      <span className="badge bg-secondary text-white" key={i}>
                        {tag}
                      </span>
                    ))}
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">
                    Friendly Name:
                    <i
                      className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
                      style={{
                        WebkitTextStroke: "0.5px",
                      }}
                      onClick={handleShowfriendlyName}
                    />
                  </h6>
                  <p className="mb-0">{details.friendlyName || "N/A"}</p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Phone Number SID:</h6>
                  <p className="mb-0">{details?.signalHouseSID || "N/A"}</p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Capabilities:</h6>
                  <div className="d-flex flex-row gap-3">
                    {details?.capabilities?.voice && (
                      <Tooltip placement="bottom" overlay={"Voice"}>
                        <i className="bi bi-telephone fs-4 text-secondary"></i>
                      </Tooltip>
                    )}
                    {details?.capabilities?.sms && (
                      <Tooltip placement="bottom" overlay={"SMS"}>
                        <i className="bi bi-chat-left-text fs-4 text-secondary"></i>
                      </Tooltip>
                    )}
                    {details?.capabilities?.mms && (
                      <Tooltip placement="bottom" overlay={"MMS"}>
                        <i className="bi bi-file-image fs-4 text-secondary"></i>
                      </Tooltip>
                    )}
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Purchase Date:</h6>
                  <p className="mb-0">
                    {details?.purchaseDate
                      ? dateFormat(details.purchaseDate)
                      : "N/A"}
                  </p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Renewal Date:</h6>
                  <p className="mb-0">
                    {details?.renewDate ? dateFormat(details.renewDate) : "N/A"}
                  </p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Brand Id:</h6>
                  {brandData?.length > 0 ? (
                    brandData.map((dt: any, index: any) => (
                      <a
                        key={index}
                        className="my-1 me-3 text-secondary"
                        href={`/brands/${dt?.brandId}`}
                      >
                        <b>
                          {dt?.brandId} - {dt?.displayName}
                        </b>
                      </a>
                    ))
                  ) : (
                    <p className="mb-0">N/A</p>
                  )}
                </Col>
                <Col md={6}>
                  <h6 className="mb-1">Campaign Id:</h6>
                  {details?.campaignId ? (
                    <a
                      href={`/campaigns/${details?.campaignId}`}
                      className="cursor-pointer text-secondary"
                    >
                      <b>{details?.campaignId}</b>
                    </a>
                  ) : (
                    <p className="mb-0">N/A</p>
                  )}
                </Col>
                <Col md={6}>
                  <h6 className="mb-1">Group Id:</h6>
                  <p className="mb-0">{details?.groupId || "N/A"}</p>
                </Col>
                <Col md={6}>
                  <h6 className="mb-1">Sub Groups:</h6>
                  <div className="d-flex align-items-center">
                    {subgrpData?.length > 0 ? (
                      subgrpData.map((dt: any, index: any) => (
                        <a
                          key={index}
                          className="my-1 me-3 text-secondary"
                          href={
                            details?.groupId
                              ? `/groups/${dt?.group_id}/subgroups/${
                                  dt?.sub_group_id
                                }/${dt?.sub_group_name
                                  ?.replace(/\s+/g, "-")
                                  .toLowerCase()}`
                              : "#"
                          }
                        >
                          <b>
                            {dt?.sub_group_id} - {dt?.sub_group_name}
                          </b>
                        </a>
                      ))
                    ) : (
                      <p className="mb-0">N/A</p>
                    )}
                  </div>
                </Col>

                <Col md={6} className="mb-3 mt-2">
                  <h6 className="mb-1">Phone Number Type:</h6>
                  <p className="mb-0">Local</p>
                </Col>
              </Row>
            </Col>

            <Row>
              <Col xl={6} lg={6}>
                <div className="mb-1 mt-3">
                  <h5>Change Number Status</h5>
                </div>
                <Col lg={12} className="mt-3">
                  <div className="border bg-light-gray rounded-3 h-8 p-4">
                    <h6>Number Status</h6>
                    <p className="text-muted" style={{ marginBottom: "35px" }}>
                      Currently, the number is{" "}
                      {returnsEnabled ? "inactive" : "active"}. Press the button
                      to {returnsEnabled ? "activate" : "deactivate"} the
                      number.
                    </p>
                    <Button
                      variant={loading ? "primary" : ""}
                      className="btn btn-subtle-primary"
                      disabled={loading}
                      onClick={handleEnableDisableClick}
                    >
                      {loading && <Spinner size="sm" animation="border" />}{" "}
                      {returnsEnabled ? "Activate" : "Deactivate"}
                    </Button>
                  </div>
                </Col>
              </Col>

              <Col xl={6} lg={6}>
                <div className="mb-1 mt-3">
                  <h5>Actions</h5>
                </div>
                <Col lg={12} className="mt-3">
                  <div className="border bg-light-gray rounded-3 h-8 p-4">
                    <h6 className="">Release phone number</h6>
                    <p className="text-muted">
                      Release this phone number from the account. After release,
                      phone number can be repurchased up to 10 days from the
                      time of release.
                    </p>
                    <Button
                      variant={loading ? "primary" : ""}
                      className="btn btn-subtle-primary"
                      disabled={loading}
                      onClick={handleReleaseClick}
                    >
                      {loading && <Spinner size="sm" animation="border" />}{" "}
                      Release phone number
                    </Button>
                  </div>
                </Col>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(NumberProperties);
