import {
  getWebhooksListApi,
  getWebhookDetailsByIdApi,
  getWebhookLogsApi,
  getWebhookEventsApi,
  createWebhookApi,
  editWebhookApi,
  deleteWebhookApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading4,
  webhooksListSuccessful,
  webhooksListFailed,
  webhookDetailsSuccessful,
  webhookDetailsFailed,
  resetWebhookDetailsByIdData,
  webhookLogsSuccessful,
  webhookLogsFailed,
  webhookEventsSuccessful,
  webhookEventsFailed,
  createWebhookSuccessful,
  createWebhookFailed,
  editWebhookSuccessful,
  editWebhookFailed,
  deleteWebhookSuccessful,
  deleteWebhookFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getWebhooksListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getWebhooksListApi(
        page,
        recordsPerPage,
        otherParams
      );
      if (data?.code || data?.errorCode) {
        dispatch(webhooksListFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(webhooksListSuccessful(data));
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(webhooksListFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getWebhookDetailsByIdThunk =
  (id: string) => async (dispatch: any) => {
    dispatch(apiLoading4());

    try {
      const data: any = await getWebhookDetailsByIdApi(id);

      if (data?.code || data?.errorCode) {
        dispatch(webhookDetailsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(webhookDetailsSuccessful(data));
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(webhookDetailsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const resetWebhookDetailsById = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(resetWebhookDetailsByIdData(null));
};

export const getWebhookLogsThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await getWebhookLogsApi(
        page,
        recordsPerPage,
        otherParams
      );
      if (data?.code || data?.errorCode) {
        dispatch(webhookLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(webhookLogsSuccessful(data));
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(webhookLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getWebhookEventsThunk =
  (webhookId?: string) => async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await getWebhookEventsApi({ webhookId: webhookId });

      if (data?.code || data?.errorCode) {
        dispatch(webhookEventsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(webhookEventsSuccessful(data));
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(webhookEventsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const createWebhookThunk =
  (payload: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await createWebhookApi({
        ...payload,
      });

      if (data?.code || data?.errorCode) {
        dispatch(createWebhookFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(createWebhookSuccessful(data));
        toast.success(data?.message || "Webhook created successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(createWebhookFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const editWebhookThunk =
  (webhookId: any, payload: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await editWebhookApi(webhookId, {
        ...payload,
      });

      if (data?.code || data?.errorCode) {
        dispatch(editWebhookFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(editWebhookSuccessful(data));
        toast.success(data?.message || "Webhook edited successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(editWebhookFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const deleteWebhookThunk =
  (webhookId: string, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await deleteWebhookApi(webhookId);

      if (data?.code || data?.errorCode) {
        dispatch(deleteWebhookFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(deleteWebhookSuccessful(data));
        toast.success(data?.message || "Webhook deleted successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(deleteWebhookFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
