import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Spinner,
  Collapse,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandDetailsThunk,
  getCampaignListThunk,
  getUsecaseListThunk,
  BrandSuspendedMnoThunk,
  checkBrandAppealStatusThunk,
  getBrandAppealHistoryThunk,
  uploadEvidenceThunk,
  getUploadedEvidenceThunk,
  deleteBrandCampaignEvidenceThunk,
  appealCategoryOptionsThunk,
  revertBrandThunk,
  applyVettingThunk,
  applyVettingEvpThunk,
  submitVettingDataThunk,
  importVattingSubmitThunk,
  getVattingListThunk,
  getDownloadVattingAttachmentThunk,
  getCspWebhookEventsListThunk,
  openModal,
  getActiveCampaignListThunk,
  fatchAccountPricingThunk,
  getSubgroupDetailsThunk,
} from "slices/thunk";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  customSelectTheme,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import { useFormik } from "formik";
import Select from "react-select";
import Tooltip from "rc-tooltip";
// import moment from "moment";
import { useSearchParams } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import { submitLoadingSubject } from "Common/modals/Modal";

interface appealFormTypes {
  evpUid: string;
  appealCategories: string[];
  explanation: string;
  files: any[];
}

const BrandDetails = (props: any) => {
  document.title = "Signal House Portal Brands";
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const [isEditMode, setIsEditMode] = useState(false);

  const MAX_WORDS = 1024;

  const selectProfile = createSelector(
    (state: any) => state.Brands,
    (state: any) => state.Campaigns,
    (state: any) => state.Enums,
    (state: any) => state.AccountPricing,
    (state: any) => state.Groups,
    (brands, campaigns, enums, accountPricing, groups) => ({
      loading: brands.loading,
      loading2: campaigns.loading,
      loading3: campaigns.loading3,
      brandDetails: brands.brandDetails,
      appeals: brands.appeals,
      evidence: brands.evidence,
      brandsData: brands.brandsData,
      evidenceUploading: brands.evidenceUploading,
      evidenceUploadError: brands.evidenceUploadError,
      vettingBrand: brands.vettingBrand,
      vettingBrandOptions: brands.vettingBrandOptions,
      vettingBrandList: brands.vettingBrandList,
      campaignsData: campaigns.campaignsData,
      activeCampaignsData: campaigns.activeCampaignsData,
      cspWebhookEventsData: campaigns.cspWebhookEventsData,
      usecaseObj: enums.usecaseObj,
      walletPricingData: accountPricing.walletPricingData,
      error: brands.error,
      subgroupDetails: groups.subgroupDetails,
    })
  );

  const {
    loading,
    loading2,
    loading3,
    brandDetails,
    appeals,
    evidence,
    brandsData,
    evidenceUploading,
    evidenceUploadError,
    vettingBrand,
    vettingBrandOptions,
    vettingBrandList,
    campaignsData,
    activeCampaignsData,
    cspWebhookEventsData,
    usecaseObj,
    walletPricingData,
    subgroupDetails,
  } = useSelector(selectProfile);

  const filteredBrand = subgroupDetails?.data?.brands?.filter(
    (dt: any) => dt?.brandId === props?.router?.params?.id
  );
  const campaigns = filteredBrand?.flatMap((dt: any) => dt.campaigns || []);
  const ownNumbers = campaigns?.flatMap((dt: any) => dt.ownNumbers || []);

  const columns1 = [
    {
      name: <span className="font-weight-bold fs-sm">Vetting Status</span>,
      minWidth: "160px",
      selector: (row: { appealStatus: string }) => row.appealStatus,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Request Date</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status Update Date</span>,
      minWidth: "200px",
      selector: (row: { appealStatusUpdateDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.appealStatusUpdateDate)}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Details</span>,
      minWidth: "150px",
      cell: (row: any) => (
        <Button
          style={{
            background: "none",
            border: "none",
            color: "#e41cfd",
          }}
          onClick={() => handleDetailsClick(row)}
        >
          Show Details
        </Button>
      ),
    },
  ];

  const columns2 = [
    {
      name: <span className="font-weight-bold fs-sm">Vetting Type</span>,
      minWidth: "160px",
      selector: (row: { vettingClass: any }) => row.vettingClass,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Vetting Partner</span>,
      minWidth: "160px",
      selector: (row: { evpId: any }) => row.evpId,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Vetting Status</span>,
      minWidth: "160px",
      selector: (row: { vettingStatus: any }) => row.vettingStatus,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Outcome</span>,
      minWidth: "200px",
      selector: (row: { vettedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.vettedDate)}
        </span>
      ),
      sortable: true,
    },
  ];

  const columns3 = [
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      minWidth: "160px",
      selector: (row: { campaignId: string }) => (
        <span
          data-tag="allowRowEvents"
          className="text-primary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.router.navigate(`/campaigns/${row.campaignId}`);
          }}
        >
          <b data-tag="allowRowEvents">{row.campaignId}</b>
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      minWidth: "200px",
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.usecase
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Registered On</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-sm">Campaign Description</span>
      ),
      minWidth: "250px",
      grow: 2,
      wrap: true,
      style: { textAlign: "left" },
      selector: (row: { description: string }) => (
        <span data-tag="allowRowEvents" className="text-truncate-two-lines">
          {row.description || "-"}
        </span>
      ),
      sortable: true,
    },
  ];

  const columns4 = [
    {
      name: <span className="font-weight-bold fs-sm">CSP Id</span>,
      minWidth: "110px",
      selector: (row: { eventData: { cspId: string } }) => row.eventData.cspId,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">CSP Name</span>,
      minWidth: "150px",
      selector: (row: { eventData: { cspName: string } }) =>
        row.eventData.cspName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Description</span>,
      selector: (row: { eventData: { description: string } }) => (
        <Tooltip
          placement="bottom"
          overlay={<span>{row.eventData.description}</span>}
        >
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer" />
        </Tooltip>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Event Type</span>,
      minWidth: "230px",
      selector: (row: { eventData: { eventType: string } }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.eventData.eventType
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      minWidth: "150px",
      selector: (row: { eventData: { brandIdentityStatus?: string } }) => {
        switch (row.eventData.brandIdentityStatus?.toLowerCase()) {
          case "re-open":
            return (
              <span className="d-flex align-items-center text-info">
                <span className="badge border border-2 border-white rounded-circle bg-info p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "on-hold":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "closed":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "failed":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "Inprogress":
            return (
              <span className="d-flex align-items-center text-dark">
                <span className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "in_progress":
            return (
              <span className="d-flex align-items-center text-dark">
                <span className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "open":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "new":
            return (
              <span className="d-flex align-items-center text-success">
                <span className="badge border border-2 border-white rounded-circle bg-success p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "verified":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-dark">
                <span className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString("In Progress")}
              </span>
            );
        }
      },
      sortable: true,
    },
  ];

  const [isInit, setIsInit] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [modal3, setModal3] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [currPage2, setCurrPage2] = useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [modal4, setModal4] = useState<any>({});
  const [modal5, setModal5] = useState<boolean>(false);
  const [appealForm, setAppealForm] = useState<appealFormTypes>({
    evpUid: "",
    appealCategories: [],
    explanation: "",
    files: [],
  });
  const [vettingImportForm, setVettingImportForm] = useState({
    vettingType: "",
  });
  const [ventingForm, setVettingForm] = useState({
    vettingType: "",
    vettingPartner: "",
  });
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [isOpenVideoLightBox, setIsOpenVideoLightBox] = useState(false);

  const vettingTypeOptions = vettingBrand
    ?.filter(
      (dt: any) => dt.enabled && (dt.id === "ENHANCED" || dt.id === "STANDARD")
    )
    ?.map((item: any) => {
      return {
        label: item.displayName,
        value: item.id,
      };
    });

  const vettingPartnerOptions = vettingBrandOptions?.filter(
    (item: any) =>
      ventingForm.vettingType &&
      item.vettingClasses.some((x: string) => x === ventingForm.vettingType)
  );

  const vettingImportFields =
    vettingBrandOptions &&
    vettingImportForm.vettingType &&
    JSON.parse(
      vettingBrandOptions?.filter(
        (vett: { evpId: string }) =>
          vett.evpId === vettingImportForm?.vettingType
      )[0]?.vettingInstruction
    );

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      usecase: "",
      description: "",
    },
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({ ...values });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  useEffect(() => {
    dispatch(fatchAccountPricingThunk());
    dispatch(getUsecaseListThunk());
    dispatch(appealCategoryOptionsThunk());
    dispatch(applyVettingThunk());
    dispatch(applyVettingEvpThunk());
    dispatch(BrandSuspendedMnoThunk());
  }, []);

  useEffect(() => {
    if (props?.router?.params?.id) {
      dispatch(
        getActiveCampaignListThunk(1, 100, {
          brandId: props?.router?.params?.id,
          status: "ACTIVE",
        })
      );
      dispatch(getBrandDetailsThunk(props?.router?.params?.id)).then(() => {
        setIsInit(false);
      });
      dispatch(getBrandAppealHistoryThunk(props?.router?.params?.id));
      dispatch(getUploadedEvidenceThunk(props?.router?.params?.id));
      dispatch(getVattingListThunk(props?.router?.params?.id));
      handleGetData(currPage, rowsPerPage);
      handleGetData2(currPage2, rowsPerPage2);
    }
  }, [props?.router?.params?.id]);

  useEffect(() => {
    brandDetails?.subGroupId?.[0] &&
      dispatch(getSubgroupDetailsThunk(brandDetails?.subGroupId?.[0]));
  }, [brandDetails?.subGroupId]);

  useEffect(() => {
    if (!evidenceUploadError && !evidenceUploading) {
      dispatch(getUploadedEvidenceThunk(props?.router?.params?.id));
    }
  }, [evidenceUploading]);

  const handleSubmitAppeal = () => {
    const updatedPayload = {
      evpUid: "AEGIS",
      appealCategories: appealForm.appealCategories,
      explanation: appealForm.explanation,
      attachmentUuids: [],
    };
    dispatch(
      checkBrandAppealStatusThunk(props?.router?.params?.id, updatedPayload)
    );
  };

  const handleDetailsClick = (row: any) => {
    setModal4(row);
  };

  const handleFileChange = async (event: any) => {
    const files: any = Array.from(event?.target?.files);
    if (!files || files.length === 0) {
      return;
    }

    // Validate number of uploads
    if (files.length > 10) {
      console.error("Exceeded maximum number of uploads (max: 10)");
      return;
    }

    const totalSize = files.reduce(
      (acc: number, file: { size: number }) => acc + file.size,
      0
    );
    // Validate total size
    if (totalSize > 30 * 1024 * 1024) {
      // Convert MB to bytes
      console.error("Exceeded maximum total size (max: 30MB)");
      return;
    }

    const formData = new FormData();
    files?.map((file: any) => {
      formData.append("file", file);
    });

    event.target.value = null;
    dispatch(uploadEvidenceThunk(props?.router?.params?.id, formData));
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resubmitBrand = () => {
    dispatch(revertBrandThunk(props?.router?.params?.id));
    setModal5(false);
  };

  const handleExplanationChange = (event: any) => {
    setAppealForm({
      ...appealForm,
      explanation: event.target.value,
    });
  };

  const handleDeleteAttachment = (
    selectedProductUuid: string,
    onClose: any
  ) => {
    if (selectedProductUuid) {
      submitLoadingSubject.next(true);
      let sanitizedUid = selectedProductUuid.replaceAll("/", "%2F");
      sanitizedUid = encodeURI(sanitizedUid);
      dispatch(deleteBrandCampaignEvidenceThunk(sanitizedUid)).then(() => {
        submitLoadingSubject.next(false);
        onClose();
        dispatch(getUploadedEvidenceThunk(props?.router?.params?.id));
      });
    } else {
      console.error("Selected product UUID is not defined.");
    }
  };

  const handleDownloadAttachment = (
    selectedProductUuid: any,
    fileName: string,
    isDownload: boolean
  ) => {
    if (selectedProductUuid) {
      let sanitizedUid = selectedProductUuid.replaceAll("/", "%2F");
      sanitizedUid = encodeURI(sanitizedUid);

      const cb = (img: any) => {
        const temp2 = URL.createObjectURL(img);
        if (isDownload) {
          let link = document.createElement("a");
          link.href = temp2;
          link.download = fileName || "img.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setPrevSrc(temp2);
          if (img?.type.startsWith("video/")) {
            setIsOpenVideoLightBox(true);
          } else if (img?.type === "application/pdf") {
            setIsOpenDocLightBox(true);
          } else if (
            img?.type === "text/plain" ||
            img?.type === "text/html" ||
            img?.type === "application/json"
          ) {
            setIsOpenDocLightBox(true);
          } else {
            setIsOpenImgLightBox(true);
          }
        }
      };

      dispatch(getDownloadVattingAttachmentThunk(sanitizedUid, cb));
    } else {
      console.error("Selected product UUID is not defined.");
    }
  };

  const handleVettingFormSubmit = () => {
    const cb = () => {
      setModal2(false);
      dispatch(getVattingListThunk(props?.router?.params?.id));
    };

    const updatedPayload = {
      evpId: ventingForm.vettingPartner,
      vettingClass: ventingForm.vettingType,
    };
    dispatch(
      submitVettingDataThunk(props?.router?.params?.id, updatedPayload, cb)
    );
  };

  const handleImportVattingSubmit = () => {
    let updatedPayload = {};
    vettingImportForm &&
      Object.entries(vettingImportForm)?.map((field) => {
        if (field[0] === "vettingType") {
          updatedPayload = {
            ...updatedPayload,
            evpId: vettingImportForm.vettingType,
          };
        } else {
          updatedPayload = { ...updatedPayload, [field[0]]: field[1] };
        }
      });
    dispatch(
      importVattingSubmitThunk(props?.router?.params?.id, updatedPayload)
    );
  };

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignListThunk(page, perPage, {
        ...otherParams,
        brandId: props?.router?.params?.id,
      })
    );
  };

  const handleRowClick = (row: { campaignId: string }) => {
    props.router.navigate(`/campaigns/${row.campaignId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const handleGetData2 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCspWebhookEventsListThunk(page, perPage, {
        ...otherParams,
        brandId: props?.router?.params?.id,
      })
    );
  };

  const handlePageChange2 = (page: number) => {
    setCurrPage2(page);
    handleGetData2(page, rowsPerPage2);
  };

  const handleRowsPerPageChange2 = (rows: number) => {
    setCurrPage2(1);
    setRowsPerPage2(rows);
    handleGetData2(1, rows);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isOpenImgLightBox && (
          <Lightbox
            enableZoom={false}
            mainSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpenImgLightBox(false);
              setPrevSrc("");
            }}
          />
        )}
        {(isOpenDocLightBox || isOpenVideoLightBox) && (
          <div
            className="position-fixed top-0 end-0 bottom-0 start-0"
            style={{
              zIndex: 1060,
              // backgroundColor: "rgba(0,0,0,0.5)",
              backgroundColor: "white",
            }}
          >
            <div
              className="position-absolute text-white cursor-pointer"
              style={{
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                padding: "5px 10px",
                right: 10,
                top: 40,
              }}
            >
              <i
                className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
                onClick={() => {
                  setIsOpenDocLightBox(false);
                  setIsOpenVideoLightBox(false);
                  setPrevSrc("");
                }}
              />
            </div>
            {isOpenVideoLightBox ? (
              <video
                // playbackRate={2}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
                height="100%"
                width="100%"
              >
                <source src={prevSrc} type={`video/mp4`} />
              </video>
            ) : (
              <iframe
                id="iframe"
                title={prevSrc}
                src={prevSrc}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            )}
          </div>
        )}
        <Container fluid>
          <BreadCrumb
            title={brandDetails?.displayName || props?.router?.params?.id}
            isBack={true}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
          />
          <div className="position-relative wrapper">
            {isInit && loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Card>
                  <Card.Header>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="secondary"
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "EditNameAndSubgroup",
                              data: {
                                title:
                                  "Edit Legal Company Name and Sub Group ID",
                                brandDetails,
                                activeCampaignsData,
                                campaigns,
                                ownNumbers,
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body className="">
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Legal Company Name: </h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.companyName}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} />
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Brand ID:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.brandId}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Universal EIN:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.universalEin}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2 mb-xl-0">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Group ID:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {brandDetails?.groupId || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Sub Group ID: </h6>
                          </Col>
                          <Col md={8}>
                            {Array.isArray(brandDetails?.subGroupId) ? (
                              brandDetails?.subGroupId?.length > 0 ? (
                                brandDetails?.subGroupId.map(
                                  (dt: string, i: number) => (
                                    <p className="mb-0 me-md-4" key={i}>
                                      {dt}
                                    </p>
                                  )
                                )
                              ) : (
                                <p className="mb-0 me-md-4">-</p>
                              )
                            ) : (
                              <p className="mb-0 me-md-4">
                                {brandDetails?.subGroupId || "-"}
                              </p>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="d-sm-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Identity Status</h6>
                    <div className="d-flex">
                      <Button
                        variant="primary"
                        className="ms-2"
                        onClick={() => setModal1(!modal1)}
                      >
                        Identity Status Appeal
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => setModal5(!modal1)}
                      >
                        Resubmit Brand
                      </Button>
                    </div>
                  </Card.Header>

                  <Card.Body className="">
                    <Row className="mb-4">
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Identity Status:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.identityStatus}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Tax Exempt Status:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {brandDetails?.taxExemptStatus
                                ? brandDetails?.taxExemptStatus
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Russell3000:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.russell ? "True" : "False"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Government Entity:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {brandDetails?.optionalAttributes
                                ?.governmentEntity
                                ? "True"
                                : "False"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <h6 className="card-title mb-2">Appeal History</h6>
                    <Datatable
                      data={appeals}
                      columns={columns1}
                      pagination
                      paginationPerPage={10}
                    />
                  </Card.Body>
                </Card>

                <Modal
                  centered
                  show={modal4.brandId}
                  onHide={() => setModal4({})}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Identity Status Appeal Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Vetting Status:</h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0"> {modal4.appealStatus}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Request Date: </h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">
                              {dateFormat(
                                modal4.createDate,
                                "MM/DD/YYYY, hh:mm:ss A"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Status Update Date: </h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">
                              {dateFormat(modal4.appealStatusUpdateDate)}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">
                              Identity Status Appeal Outcome:
                            </h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">N/A</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Tax Exempt Status:</h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">N/A</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Government Entity:</h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">N/A</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <h5>Appeal Categories</h5>
                      <h6>Verify Government status</h6>
                      <p>
                        Select this category if the record submitted as a
                        Government entity type is UNVERIFIED or VERIFIED and is
                        missing a “Government Entity” status.
                      </p>
                    </div>
                    <div>
                      <h5>Explanation</h5>
                      <p>{modal4.explanation}</p>
                    </div>
                    <h5>Sample Multimedia Files</h5>
                    <ul className="vstack gap-2 list-unstyled my-3">
                      {Array.isArray(evidence) &&
                        evidence?.map(
                          (
                            dt: {
                              fileName: string;
                              mimeType: string;
                              uuid: string;
                            },
                            i: number
                          ) => (
                            <li key={i}>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      {dt?.mimeType.startsWith("image/") ? (
                                        <i className="ri ri-image-line fs-lg"></i>
                                      ) : dt?.mimeType.startsWith("video/") ? (
                                        <i className="ri ri-video-line fs-lg"></i>
                                      ) : (
                                        <i className="ri ri-file-line fs-lg"></i>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6
                                      className="mb-0"
                                      style={{ wordBreak: "break-word" }}
                                    >
                                      {dt?.fileName || ""}
                                    </h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    <i
                                      className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                      // onClick={() => {
                                      //   !imgLoading && handleDownloadImg(i);
                                      // }}
                                      onClick={() =>
                                        !loading &&
                                        handleDownloadAttachment(
                                          dt?.uuid,
                                          dt?.fileName,
                                          true
                                        )
                                      }
                                    />
                                    <i
                                      className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                      onClick={() =>
                                        !loading &&
                                        handleDownloadAttachment(
                                          dt?.uuid,
                                          dt?.fileName,
                                          false
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        )}
                    </ul>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setModal4(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Modal for Identity Status Appeal  */}
                <Modal show={modal1} onHide={() => setModal1(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h4>Identity Status Appeal</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      You can submit an Appeal up to 45 days after the
                      submission of a Brand. Appeals are charged $10 regardless
                      of the outcome. Do not use this to appeal a Standard Vet.
                    </p>
                    <h4>Appeal Categories</h4>
                    <p>
                      You are required to select at least one category, but may
                      select more
                    </p>
                    {Array.isArray(brandsData) &&
                      brandsData?.map(
                        (
                          category: {
                            id: string;
                            displayName?: string;
                            description?: string;
                          },
                          i: number
                        ) => (
                          <div key={i}>
                            <Form.Check
                              type="checkbox"
                              id={category?.id}
                              key={category?.id}
                              checked={
                                appealForm?.appealCategories?.indexOf(
                                  category?.id
                                ) !== -1
                                  ? true
                                  : false
                              }
                              label={<strong>{category?.displayName}</strong>}
                              onChange={(e) => {
                                let newValues: any = [];

                                if (
                                  appealForm?.appealCategories?.indexOf(
                                    category?.id
                                  ) !== -1
                                ) {
                                  newValues =
                                    appealForm?.appealCategories?.filter(
                                      (cat: string) => {
                                        return cat !== category.id;
                                      }
                                    );
                                } else {
                                  newValues = [
                                    ...appealForm?.appealCategories,
                                    category?.id,
                                  ];
                                }

                                setAppealForm({
                                  ...appealForm,
                                  appealCategories: [...newValues],
                                });
                              }}
                            />
                            <p>{category?.description}</p>
                          </div>
                        )
                      )}
                    <Form.Group>
                      <Form.Label>
                        <h5>
                          <strong>Explanation</strong>
                        </h5>
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <FormControl
                          as="textarea"
                          value={appealForm?.explanation}
                          onChange={handleExplanationChange}
                          maxLength={MAX_WORDS}
                        />
                        <InputGroup.Text>{MAX_WORDS} words</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className="border p-3 text-center"
                      style={{ background: "none" }}
                    >
                      <Form.Label>
                        <h5>
                          <strong>Attach Files</strong>
                        </h5>
                      </Form.Label>
                      <p>
                        Max 10 uploads
                        <br />
                        Max file size: 10MB
                        <br />
                        Max total size: 30MB
                      </p>
                      <i className="bi bi-upload fs-3"></i>
                      <Form.Control
                        type="file"
                        id="evidence-file-upload"
                        multiple
                        onChange={handleFileChange}
                      />
                      <Tooltip
                        placement="bottom"
                        overlay={
                          <span>
                            Approved file types: bmp, docx, htm, jpeg, jpg, odt,
                            pdf, png, raw, rtf, tiff, txt, xml
                          </span>
                        }
                      >
                        <p className="mt-2">
                          <em>Approved file types</em>
                        </p>
                      </Tooltip>
                      <div>
                        <ul className="vstack gap-2 list-unstyled my-3">
                          {Array.isArray(evidence) &&
                            evidence?.map(
                              (
                                dt: {
                                  fileName: string;
                                  mimeType: string;
                                  uuid: string;
                                },
                                i: number
                              ) => (
                                <li key={i}>
                                  <div
                                    className="d-block p-2 bg-light bg-opacity-50 rounded"
                                    style={{ textAlign: "start" }}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="avatar-xs flex-shrink-0 ronded">
                                        <div className="avatar-title bg-body-secondary text-body">
                                          {dt?.mimeType.startsWith("image/") ? (
                                            <i className="ri ri-image-line fs-lg"></i>
                                          ) : dt?.mimeType.startsWith(
                                              "video/"
                                            ) ? (
                                            <i className="ri ri-video-line fs-lg"></i>
                                          ) : (
                                            <i className="ri ri-file-line fs-lg"></i>
                                          )}
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h6
                                          className="mb-0"
                                          style={{ wordBreak: "break-word" }}
                                        >
                                          {dt?.fileName || ""}
                                        </h6>
                                      </div>
                                      <div className="flex-shrink-0 text-muted">
                                        <i
                                          className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                          // onClick={() => {
                                          //   !imgLoading && handleDownloadImg(i);
                                          // }}
                                          onClick={() =>
                                            !loading &&
                                            handleDownloadAttachment(
                                              dt?.uuid,
                                              dt?.fileName,
                                              true
                                            )
                                          }
                                        />
                                        <i
                                          className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                          onClick={() =>
                                            !loading &&
                                            handleDownloadAttachment(
                                              dt?.uuid,
                                              dt?.fileName,
                                              false
                                            )
                                          }
                                        />
                                        <i
                                          className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                          onClick={() => {
                                            if (!loading2) {
                                              dispatch(
                                                openModal({
                                                  modalbody:
                                                    "Are you sure you want to delete this file?",
                                                  data: {
                                                    title: "Delete File",
                                                    footer: true,
                                                    cancelBtn: true,
                                                    buttonText: {
                                                      submit: "Continue",
                                                    },
                                                    onCancel: ({
                                                      onClose,
                                                    }: any) => {
                                                      onClose();
                                                    },
                                                    onSubmit: ({
                                                      onClose,
                                                    }: any) => {
                                                      handleDeleteAttachment(
                                                        dt.uuid,
                                                        onClose
                                                      );
                                                    },
                                                  },
                                                })
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setModal1(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAppeal}>
                      Submit Appeal
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Modal for Identity Status Appeal End...... */}

                {/* Modal for resubmit brand........... */}
                <Modal show={modal5} onHide={() => setModal5(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h4>Resubmit Brand</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Are you sure? You will be charged $
                      {walletPricingData["brand.create"] || "4.00"} every time
                      you resubmit a Brand for identity verification.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setModal5(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={() => resubmitBrand()}>
                      Proceed
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Modal for resubmit brand End........... */}

                <Card>
                  <Card.Header className="d-sm-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Company Details</h6>
                    <div className="d-flex">
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "EditCompanyDetails",
                              data: {
                                modalClass: "summary_modal",
                                title: "Company Details",
                                brandDetails,
                                activeCampaignsData,
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body className="">
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Brand Name or DBA:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.displayName}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Address/Street:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.street}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Entity Type:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.entityType}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">City:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.city}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">EIN:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">{brandDetails?.ein}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">State:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.state}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">EIN Issuing Country:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.einIssuingCountry}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Postal Code:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.postalCode}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">LEI Number:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.altBusinessIdType === "LEI"
                                ? brandDetails?.altBusinessId
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Country:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.country}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Vertical:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.vertical}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Website/Online Presence:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {brandDetails?.website || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Stock Symbol:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.stockSymbol
                                ? brandDetails?.stockSymbol
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Reference ID:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.referenceId}</p>
                          </Col>
                        </Row>
                      </Col> */}
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Stock Exchange:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.stockExchange
                                ? brandDetails?.stockExchange
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Mobile Phone:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {brandDetails?.mobilePhone
                                ? brandDetails?.mobilePhone
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">First Name:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.firstName
                                ? brandDetails?.firstName
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">OTP Confirmation Date:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {brandDetails?.otp ? brandDetails?.otp : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Last Name:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.lastName
                                ? brandDetails?.lastName
                                : "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col />
                      <Col xl={6}>
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Registered On:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {dateFormat(
                                brandDetails?.createDate,
                                "MM/DD/YYYY, hh:mm:ss A"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="d-sm-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Contact Details</h6>
                    <div className="d-flex">
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "EditContactDetails",
                              data: {
                                title: "Contact Details",
                                brandDetails,
                                activeCampaignsData,
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body className="">
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Support Email Address:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {brandDetails?.email}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Support Phone Number:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{brandDetails?.phone}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="d-sm-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Brand Relationship</h6>
                    {/* {brandDetails?.brandRelationshipUpdatedDate &&
                      moment().isAfter(
                        moment(brandDetails?.brandRelationshipUpdatedDate).add(
                          3,
                          "months"
                        ),
                        "day"
                      ) && (
                        <div className="d-flex">
                          <Button
                            variant="secondary"
                            className="ms-2"
                            onClick={() => {
                              dispatch(
                                openModal({
                                  path: "EditBrandRelationship",
                                  data: {
                                    modalClass: "summary_modal",
                                    title: "Brand Relationship",
                                    brandDetails,
                                    footer: false,
                                  },
                                })
                              );
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      )} */}
                  </Card.Header>
                  <Card.Body className="">
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Brand Relationship:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {brandDetails?.brandRelationship}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="">
                    <h6 className="card-title mb-2">Brand Suspended</h6>
                  </Card.Header>
                  <Card.Body className="">
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">AT&T:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">NO</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">T-Mobile:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">NO</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Verizon Wireless:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">NO</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">US Cellular:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">NO</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">ClearSky:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">NO</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Interop:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">NO</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="d-sm-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Vetting Details</h6>
                    <div className="d-flex">
                      <Button
                        variant="primary"
                        className="ms-2"
                        onClick={() => setModal2(true)}
                      >
                        Apply for Vetting
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => setModal3(true)}
                      >
                        Import Vetting
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Datatable data={vettingBrandList} columns={columns2} />
                  </Card.Body>
                </Card>

                {/* Modal for Vetting Details...... */}
                <Modal show={modal2} onHide={() => setModal2(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Apply for Vetting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Select the Vetting Type from the drop-down menu. Once you
                      have selected the type, select the vetting partner and
                      click "Submit". By submitting the Brand for external
                      vetting you accept the Terms & Conditions.
                    </p>

                    <Col lg={12} className="mb-3">
                      <Form.Label htmlFor="country">
                        Select Vetting Type
                      </Form.Label>
                      <Select
                        styles={styleObj(false)}
                        theme={customSelectTheme}
                        options={vettingTypeOptions}
                        isSearchable
                        onChange={(value: { value: string }) => {
                          setVettingForm({
                            vettingPartner: "",
                            vettingType: value.value,
                          });
                        }}
                      />
                    </Col>
                    <Col lg={12} className="mb-3">
                      <Form.Label htmlFor="country">
                        Select Vetting Partner
                      </Form.Label>
                      <Select
                        styles={styleObj(false)}
                        theme={customSelectTheme}
                        options={vettingPartnerOptions?.map(
                          (option: { displayName: string; evpId: string }) => ({
                            label: option.displayName,
                            value: option.evpId,
                          })
                        )}
                        isSearchable
                        onChange={(value: { value: string }) => {
                          setVettingForm((pre) => {
                            return { ...pre, vettingPartner: value.value };
                          });
                        }}
                      />
                    </Col>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setModal2(false)}
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleVettingFormSubmit}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Modal for Vetting Details End...... */}

                {/* Modal For import Vetting...... */}
                <Modal
                  show={modal3}
                  onHide={() => {
                    setModal3(false);
                    setVettingImportForm({ vettingType: "" });
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Import Vetting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Col lg={12} className="mb-3">
                      <Form.Label htmlFor="vettingType">
                        Select Vetting Type
                      </Form.Label>
                      <Select
                        name="vettingType"
                        styles={styleObj(false)}
                        theme={customSelectTheme}
                        onChange={(selectedOption: { value: string }) =>
                          setVettingImportForm({
                            vettingType: selectedOption.value,
                          })
                        }
                        options={vettingBrandOptions?.map(
                          (option: { displayName: string; evpId: string }) => ({
                            label: option.displayName,
                            value: option.evpId,
                          })
                        )}
                      />
                    </Col>
                    {vettingImportFields?.importVet?.parameters &&
                      Object.entries(
                        vettingImportFields.importVet.parameters
                      )?.map((keys: any, index: number) => (
                        // keys[1].show &&
                        <Col key={index} lg={12} className="mb-3">
                          <Form.Label htmlFor={keys[1]}>
                            {keys[1].label}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={keys[0]}
                            id={keys[0]}
                            onChange={(e) => {
                              setVettingImportForm((pre) => {
                                return {
                                  ...pre,
                                  [keys[0]]: e.target.value,
                                };
                              });
                            }}
                            value={vettingImportFields[keys[0]]}
                          />
                        </Col>
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setModal3(false);
                        setVettingImportForm({ vettingType: "" });
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      // onClick={handleSubmitImportVatting}
                      onClick={handleImportVattingSubmit}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Modal For import Vetting End...... */}

                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="card-title mb-0">
                        {campaignsData?.totalRecords || 0} Registered Campaigns
                      </h6>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={toggleFilters}
                      >
                        <i className="bi bi-funnel-fill bi-3xl"> </i>
                        Add Filters
                      </button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Collapse in={showFilters}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                        }}
                      >
                        <Row>
                          <Col sm={6} lg={4} xl={3}>
                            <div className="mb-3">
                              <Form.Label htmlFor="usecase">Usecase</Form.Label>
                              <Select
                                name="usecase"
                                styles={styleObj(
                                  validation?.touched?.usecase &&
                                    validation?.errors?.usecase
                                )}
                                theme={customSelectTheme}
                                options={
                                  Object.keys(usecaseObj)?.length > 0
                                    ? Object.keys(usecaseObj)?.map((dt) => ({
                                        label:
                                          usecaseObj?.[dt]?.displayName || dt,
                                        value: dt,
                                      }))
                                    : []
                                }
                                onChange={(e: { value: string }) => {
                                  validation.setFieldValue("usecase", e.value);
                                }}
                                value={{
                                  label:
                                    usecaseObj?.[validation.values.usecase]
                                      ?.displayName,
                                  value: validation.values.usecase,
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm={6} lg={4} xl={3}>
                            <div className="mb-3">
                              <Form.Label htmlFor="description">
                                Campaign Description
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="description"
                                onChange={(e) => {
                                  validation.setFieldValue(
                                    "description",
                                    e.target.value
                                  );
                                }}
                                value={validation.values?.description}
                              />
                            </div>
                          </Col>
                          <Col className="d-flex align-items-end mb-3">
                            <div>
                              <Button
                                className="btn btn-secondary me-2"
                                type="button"
                                disabled={loading2}
                                onClick={() => {
                                  validation.resetForm();
                                  handleGetData(currPage, rowsPerPage);
                                }}
                              >
                                Clear Filters
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="submit"
                                disabled={loading2}
                              >
                                {loading2 && (
                                  <Spinner size="sm" animation="border" />
                                )}{" "}
                                Apply Filters
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Collapse>
                    <Datatable
                      data={
                        Array.isArray(campaignsData?.records)
                          ? campaignsData?.records
                          : []
                      }
                      columns={columns3}
                      handleRowClick={handleRowClick}
                      handlePageChange={handlePageChange}
                      handleRowsPerPageChange={handleRowsPerPageChange}
                      currPage={currPage}
                      rowsPerPage={rowsPerPage}
                      totalRecords={campaignsData?.totalRecords}
                    />
                  </Card.Body>
                </Card>

                {/* Events...... */}
                <Card>
                  <Card.Header>
                    <h6 className="card-title mb-2">Events</h6>
                  </Card.Header>
                  <Card.Body className="position-relative">
                    {loading3 ? (
                      <div style={{ height: 60 }}>
                        <img
                          src={Loader}
                          className={`position-absolute top-50 start-50 translate-middle`}
                          alt="loading"
                        />
                      </div>
                    ) : (
                      <Datatable
                        data={
                          Array.isArray(cspWebhookEventsData?.records)
                            ? cspWebhookEventsData?.records
                            : []
                        }
                        columns={columns4}
                        handlePageChange={handlePageChange2}
                        handleRowsPerPageChange={handleRowsPerPageChange2}
                        currPage={currPage2}
                        rowsPerPage={rowsPerPage2}
                        totalRecords={cspWebhookEventsData?.totalRecords}
                      />
                    )}
                  </Card.Body>
                </Card>
                {/* Events End...... */}
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BrandDetails);
