import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  OverlayTrigger,
  Popover,
  Dropdown,
  Tab,
  Nav,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import Loader2 from "assets/images/spinner-white.svg";

import {
  getTermsAndConditionsThunk,
  getTermsFromUsecaseListThunk,
  getCampaignOperationStatusThunk,
  getCampaignDcaElectedThunk,
  updateCampaignThunk,
  nudgeCampaignThunk,
  deactivateCampaignThunk,
  getCampaignMnoMetadataThunk,
  getCampaignMmsThunk,
  getCampaignAttachmentThunk,
  deleteCampaignAttachmentThunk,
  uploadCampaignMmsThunk,
  resubmitCampaignThunk,
  openModal,
  getCspWebhookEventsListThunk,
  getPrevNumProvListThunk,
  getCurrNumProvListThunk,
} from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import Tooltip from "rc-tooltip";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import Radiobox from "Common/Radiobox";
import TagsInput from "react-tagsinput";
import * as Yup from "yup";
import { useFormik } from "formik";
import Lightbox from "react-image-lightbox";
import { capitalizeString, dateFormat } from "helpers/common";
import Datatable from "Common/Datatable";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";
import { useSearchParams } from "react-router-dom";

interface HoverPopoverProps {
  children: React.ReactNode;
  icon?: any;
  placement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end";
  className?: string;
  popoverClassName?: string; // Added for popover-specific styling
  title?: string; // Added for cases where you need a title
  width?: number | string; // Added for custom widths
  onOpen?: () => void; // Added for tracking/analytics
  onClose?: () => void; // Added for tracking/analytics
}

const ALLOWED_FILE_TYPES = [
  ".bmp",
  ".dib",
  ".gif",
  ".jpeg",
  ".jpg",
  ".m2a",
  ".m4a",
  ".m4b",
  ".m4p",
  ".m4r",
  ".m4v",
  ".mp1",
  ".mp2",
  ".mp3",
  ".mp4",
  ".mpa",
  ".oga",
  ".ogg",
  ".ogm",
  ".ogv",
  ".ogx",
  ".png",
  ".spx",
  ".txt",
  ".wav",
  ".webm",
];

const MAX_FILE_SIZE = 10485760; // 10MB in bytes

const validateFile = (file: File): { isValid: boolean; error?: string } => {
  // Check file size
  if (file.size > MAX_FILE_SIZE) {
    return { isValid: false, error: "File is larger than 10MB" };
  }

  // Get file extension
  const extension = "." + file.name.split(".").pop()?.toLowerCase();

  // Check file type
  if (!ALLOWED_FILE_TYPES.includes(extension)) {
    return {
      isValid: false,
      error: `Invalid file type. Allowed types are: ${ALLOWED_FILE_TYPES.join(
        ", "
      )}`,
    };
  }

  return { isValid: true };
};

const HoverPopover: React.FC<HoverPopoverProps> = ({
  children,
  icon,
  placement = "bottom-end",
  className = "",
  popoverClassName = "",
  title,
  width,
  onOpen,
  onClose,
}) => {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShow(true);
    onOpen?.();
  };

  const handleMouseLeave = () => {
    setShow(false);
    onClose?.();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >
      <OverlayTrigger
        show={show}
        placement={placement}
        overlay={
          <Popover
            id="hover-popover"
            className={`hover-popover ${popoverClassName}`}
            style={width ? { width } : undefined}
          >
            {title && <Popover.Header>{title}</Popover.Header>}
            <Popover.Body>{children}</Popover.Body>
          </Popover>
        }
      >
        {icon || (
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
        )}
      </OverlayTrigger>
    </div>
  );
};

const CampaignDetails = (props: any) => {
  document.title = "Signal House Portal Campaign";
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const isPartner = props?.router?.location?.pathname?.includes("partner");

  const [isInit, setIsInit] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [acceptedFiles, setAcceptedFiles] = useState([]);
  // const [rejectedFiles, setRejectedFiles] = useState([]);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [campConditions, setCampConditions] = useState<any>({});
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [isOpenVideoLightBox, setIsOpenVideoLightBox] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currPage1, setCurrPage1] = useState(1);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [currPage2, setCurrPage2] = useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [isAttributesEditMode, setIsAttributesEditMode] = useState(false);
  const [attributeChanges, setAttributeChanges] = useState<any>({});

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (campaigns) => ({
      loading: campaigns.loading,
      loading2: campaigns.loading2,
      loading3: campaigns.loading3,
      loading4: campaigns.loading4,
      campaignDetails: campaigns.campaignDetails,
      campaignMnoMetadata: campaigns.campaignMnoMetadata,
      campaignMmsData: campaigns.campaignMmsData,
      campaignTerms: campaigns.campaignTerms,
      campaignTnC: campaigns.campaignTnC,
      campaignOperationStatus: campaigns.campaignOperationStatus,
      campaignDcaElected: campaigns.campaignDcaElected,
      cspWebhookEventsData: campaigns.cspWebhookEventsData,
      currNumProvData: campaigns.currNumProvData,
      prevNumProvData: campaigns.prevNumProvData,
      error: campaigns.error,
    })
  );

  const {
    loading,
    loading2,
    loading3,
    loading4,
    campaignDetails,
    campaignMnoMetadata,
    campaignMmsData,
    campaignTerms,
    campaignTnC,
    campaignOperationStatus,
    campaignDcaElected,
    cspWebhookEventsData,
    currNumProvData,
    prevNumProvData,
  } = useSelector(selectProfile);

  const validation1: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      optinKeywords: "",
      optinMessage: "",
    },
    validationSchema: Yup.object({
      optinKeywords: Yup.string().when("subscriberOptin", () => {
        return !attributeChanges?.subscriberOptin &&
          !campaignDetails?.subscriberOptin
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      optinMessage: Yup.string().when("subscriberOptin", () => {
        return !attributeChanges?.subscriberOptin &&
          !campaignDetails?.subscriberOptin
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      optoutKeywords: "",
      optoutMessage: "",
    },
    validationSchema: Yup.object({
      optoutKeywords: Yup.string().when("subscriberOptout", () => {
        return !attributeChanges?.subscriberOptout &&
          !campaignDetails?.subscriberOptout
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      optoutMessage: Yup.string().when("subscriberOptout", () => {
        return !attributeChanges?.subscriberOptout &&
          !campaignDetails?.subscriberOptout
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const validation3: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      helpKeywords: "",
      helpMessage: "",
    },
    validationSchema: Yup.object({
      helpKeywords: Yup.string().when("subscriberHelp", () => {
        return !attributeChanges?.subscriberHelp &&
          !campaignDetails?.subscriberHelp
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      helpMessage: Yup.string().when("subscriberHelp", () => {
        return !attributeChanges?.subscriberHelp &&
          !campaignDetails?.subscriberHelp
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const columns = isPartner
    ? [
        {
          name: <span className="font-weight-bold fs-sm">CNP ID</span>,
          minWidth: "110px",
          selector: (row: { eventData: { cnpId: string } }) =>
            row.eventData.cnpId || "-",
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-sm">CNP Migration</span>,
          minWidth: "110px",
          selector: (row: { eventData: { cnpMigration: boolean } }) => (
            <span
              data-tag="allowRowEvents"
              className={`d-flex align-items-center text-${
                row.eventData.cnpMigration ? "secondary" : "primary"
              }`}
            >
              <span
                data-tag="allowRowEvents"
                className={`badge border border-2 border-white rounded-circle bg-${
                  row.eventData.cnpMigration ? "secondary" : "primary"
                } p-1 me-2`}
              >
                <span></span>
              </span>
              {row.eventData.cnpMigration ? "True" : "False"}
            </span>
          ),
          sortable: true,
        },
        {
          name: (
            <span className="font-weight-bold fs-sm">Previously Accepted</span>
          ),
          minWidth: "110px",
          selector: (row: { eventData: { previouslyAccepted: boolean } }) => (
            <span
              data-tag="allowRowEvents"
              className={`d-flex align-items-center text-${
                row.eventData.previouslyAccepted ? "secondary" : "primary"
              }`}
            >
              <span
                data-tag="allowRowEvents"
                className={`badge border border-2 border-white rounded-circle bg-${
                  row.eventData.previouslyAccepted ? "secondary" : "primary"
                } p-1 me-2`}
              >
                <span></span>
              </span>
              {row.eventData.previouslyAccepted ? "True" : "False"}
            </span>
          ),
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-sm">Description</span>,
          selector: (row: { eventData: { description: string } }) => (
            <Tooltip
              placement="bottom"
              overlay={<span>{row.eventData.description || "-"}</span>}
            >
              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer" />
            </Tooltip>
          ),
        },
        {
          name: <span className="font-weight-bold fs-sm">Event Type</span>,
          minWidth: "230px",
          selector: (row: { eventData: { eventType: string } }) => (
            <span
              data-tag="allowRowEvents"
              className="badge bg-body-secondary border border-secondary text-secondary"
            >
              {row.eventData.eventType
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}
            </span>
          ),
          sortable: true,
        },
      ]
    : [
        {
          name: <span className="font-weight-bold fs-sm">Brand ID</span>,
          minWidth: "110px",
          selector: (row: { eventData: { brandId: string } }) => (
            <span
              data-tag="allowRowEvents"
              className="text-secondary cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                props.router.navigate(`/brands/${row.eventData?.brandId}`);
              }}
            >
              <b data-tag="allowRowEvents">{row.eventData.brandId || "-"}</b>
            </span>
          ),
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-sm">Brand Name</span>,
          minWidth: "150px",
          selector: (row: { eventData: { brandName: string } }) =>
            capitalizeString(row.eventData.brandName || "-"),
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-sm">CSP Id</span>,
          minWidth: "110px",
          selector: (row: { eventData: { cspId: string } }) =>
            row.eventData.cspId || "-",
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-sm">CSP Name</span>,
          minWidth: "150px",
          selector: (row: { eventData: { cspName: string } }) =>
            row.eventData.cspName || "-",
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-sm">Description</span>,
          selector: (row: { eventData: { description: string } }) => (
            <Tooltip
              placement="bottom"
              overlay={<span>{row.eventData.description || "-"}</span>}
            >
              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer" />
            </Tooltip>
          ),
        },
        {
          name: <span className="font-weight-bold fs-sm">Event Type</span>,
          minWidth: "230px",
          selector: (row: { eventData: { eventType: string } }) => (
            <span
              data-tag="allowRowEvents"
              className="badge bg-body-secondary border border-secondary text-secondary"
            >
              {row.eventData.eventType
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}
            </span>
          ),
          sortable: true,
        },
      ];

  const columns2 = (isCurrent: boolean) => [
    {
      name: (
        <span className="font-weight-bold fs-sm">
          {isCurrent ? "" : "Previous "}Phone Numbers
        </span>
      ),
      minWidth: "130px",
      selector: (row: { phoneNumber: number | string }) =>
        row.phoneNumber || "",
      cell: (row: { phoneNumber: number | string; signalHouseSID: string }) => (
        <span
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() =>
            props.router.navigate(
              `/mynumbers/${row.phoneNumber || ""}/${row.signalHouseSID || ""}`
            )
          }
        >
          {row.phoneNumber || ""}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">NNID</span>,
      selector: (row: { nnid?: string }) => row.nnid || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Provisioned On</span>,
      minWidth: "220px",
      selector: (row: { provisionedDate?: string }) =>
        row.provisionedDate
          ? dateFormat(row.provisionedDate, "MM/DD/YYYY, hh:mm:ss A")
          : "-",
      sortable: true,
    },
  ];

  useEffect(() => {
    !isPartner && dispatch(getTermsAndConditionsThunk());
    if (props?.router?.params?.id) {
      dispatch(
        getCampaignMnoMetadataThunk(props?.router?.params?.id, isPartner)
      );
      dispatch(
        getCampaignOperationStatusThunk(props?.router?.params?.id, isPartner)
      );
      !isPartner && dispatch(getCampaignMmsThunk(props?.router?.params?.id));
      dispatch(getCampaignDcaElectedThunk(props?.router?.params?.id));
      handleGetData(currPage, rowsPerPage);
      !isPartner && handleGetData1(currPage1, rowsPerPage1);
      !isPartner && handleGetData2(currPage2, rowsPerPage2);
    }
  }, [props?.router?.params?.id]);

  useEffect(() => {
    if (isInit) {
      setIsInit(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (campaignDetails?.optinKeywords)
      validation1.setFieldValue(
        "optinKeywords",
        campaignDetails?.optinKeywords,
        true
      );
    if (campaignDetails?.optinMessage)
      validation1.setFieldValue(
        "optinMessage",
        campaignDetails?.optinMessage,
        true
      );
    if (campaignDetails?.optoutKeywords)
      validation2.setFieldValue(
        "optoutKeywords",
        campaignDetails?.optoutKeywords,
        true
      );
    if (campaignDetails?.optoutMessage)
      validation2.setFieldValue(
        "optoutMessage",
        campaignDetails?.optoutMessage,
        true
      );
    if (campaignDetails?.helpKeywords)
      validation3.setFieldValue(
        "helpKeywords",
        campaignDetails?.helpKeywords,
        true
      );
    if (campaignDetails?.helpMessage)
      validation3.setFieldValue(
        "helpMessage",
        campaignDetails?.helpMessage,
        true
      );

    if (campaignDetails?.brandId && campaignDetails?.usecase && !isPartner)
      dispatch(
        getTermsFromUsecaseListThunk(
          campaignDetails?.brandId,
          campaignDetails?.usecase
        )
      );
  }, [campaignDetails]);

  useEffect(() => {
    setselectedFiles(campaignMmsData || []);
  }, [campaignMmsData]);

  useEffect(() => {
    const temp2 =
      campaignMnoMetadata &&
      Object.fromEntries(
        Object.entries(campaignMnoMetadata).filter(
          ([key, value]: any) => value?.tpmScope === "CAMPAIGN"
        )
      );
    setCampConditions((temp2 && Object.values(temp2)[0]) || {});
  }, [campaignMnoMetadata]);

  const campaignDetailsSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };
    handleUpdateCampaign2({ obj: values, cb });
  };

  const sampleMessagesSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };
    const tempMsg: { [key: string]: string } = {};
    values?.messages?.forEach((dt: string, i: number) => {
      tempMsg[`sample${i + 1}`] = dt;
    });

    const tempPayload = { ...tempMsg };
    handleUpdateCampaign2({ obj: tempPayload, cb });
  };

  const inputModalSubmit = ({ values, resetForm, otherSubmitArgs }: any) => {
    submitLoadingSubject.next(true);
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };
    dispatch(
      nudgeCampaignThunk(
        props?.router?.params?.id,
        {
          ...otherSubmitArgs,
          description: values?.description,
        },
        cb
      )
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  console.log("isLoading", isLoading);
  const handleAcceptedFiles = (files: any, rejFiles: any) => {
    rejFiles?.length > 0 && console.log("rejFiles", rejFiles);
    rejFiles.forEach((file: any) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === "file-too-large") {
          toast.error(err.message || "Something went wrong");
        }

        if (err.code === "file-invalid-type") {
          toast.error(err.message || "Something went wrong");
        }
      });
    });

    if (files?.length > 0) {
      console.log("files", files);
      setIsLoading(true);
      const cb = (files: any) => {
        if (typeof files[0] === "object" && files[0] !== null) {
          const cb = () => {
            setIsLoading(false);
            dispatch(getCampaignMmsThunk(props?.router?.params?.id));
          };

          dispatch(
            uploadCampaignMmsThunk(props?.router?.params?.id, files, cb)
          );
        }
      };
      cb(files);
    }
  };

  const handleDownloadImg = (ind: number) => {
    const temp = [...selectedFiles];
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      let link = document.createElement("a");
      link.href = temp2;
      link.download = temp?.[ind]?.name || temp?.[ind]?.fileName || "img.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    temp?.[ind]?.uuid
      ? dispatch(getCampaignAttachmentThunk(temp?.[ind]?.uuid, cb))
      : cb(temp?.[ind]);
  };

  const handleViewImg = (ind: number) => {
    const temp = [...selectedFiles];
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      setPrevSrc(temp2);
      if (img?.type.startsWith("video/")) {
        setIsOpenVideoLightBox(true);
      } else if (img?.type === "application/pdf") {
        setIsOpenDocLightBox(true);
      } else if (
        img?.type === "text/plain" ||
        img?.type === "text/html" ||
        img?.type === "application/json"
      ) {
        setIsOpenDocLightBox(true);
      } else {
        setIsOpenImgLightBox(true);
      }
    };
    temp?.[ind]?.uuid
      ? dispatch(getCampaignAttachmentThunk(temp?.[ind]?.uuid, cb))
      : cb(temp?.[ind]);
  };

  const handleDeleteImg = ({ onClose, deleteId }: any) => {
    const temp = [...selectedFiles];
    if (temp?.[deleteId]?.uuid) {
      submitLoadingSubject.next(true);
      const cb = () => {
        onClose();
      };
      dispatch(
        deleteCampaignAttachmentThunk(
          temp?.[deleteId]?.uuid,
          props?.router?.params?.id,
          cb
        )
      ).then(() => {
        submitLoadingSubject.next(false);
      });
    }
  };

  const handleUpdateConf = (obj: any) => {
    submitLoadingSubject.next(true);
    dispatch(updateCampaignThunk(props?.router?.params?.id, obj)).then(() => {
      submitLoadingSubject.next(false);
      onCloseSubject.next({});
    });
    // dispatch(
    //   openModal({
    //     modalbody: `Are you sure you want to update this?`,
    //     data: {
    //       title: "Update Attributes",
    //       footer: true,
    //       cancelBtn: true,
    //       buttonText: {
    //         submit: "Update",
    //       },
    //       onSubmit: () => {
    //         alert("wdadas");
    //       },
    //     },
    //   })
    // );
  };

  const handleUpdateCampaign = (obj: any, cb?: any) => {
    dispatch(updateCampaignThunk(props?.router?.params?.id, obj, cb));
  };

  const handleUpdateCampaign2 = ({ obj, cb }: any) => {
    submitLoadingSubject.next(true);
    dispatch(updateCampaignThunk(props?.router?.params?.id, obj, cb)).then(
      () => {
        submitLoadingSubject.next(false);
      }
    );
  };

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCspWebhookEventsListThunk(page, perPage, {
        ...otherParams,
        campaignId: props?.router?.params?.id,
      })
    );
  };

  const handleGetData1 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCurrNumProvListThunk(props?.router?.params?.id, page, perPage, {
        current: true,
        ...otherParams,
      })
    );
  };

  const handleGetData2 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getPrevNumProvListThunk(props?.router?.params?.id, page, perPage, {
        current: false,
        ...otherParams,
      })
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handlePageChange1 = (page: number) => {
    setCurrPage1(page);
    handleGetData1(page, rowsPerPage1);
  };

  const handlePageChange2 = (page: number) => {
    setCurrPage2(page);
    handleGetData2(page, rowsPerPage2);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const handleRowsPerPageChange1 = (rows: number) => {
    setCurrPage1(1);
    setRowsPerPage1(rows);
    handleGetData1(1, rows);
  };

  const handleRowsPerPageChange2 = (rows: number) => {
    setCurrPage2(1);
    setRowsPerPage2(rows);
    handleGetData2(1, rows);
  };

  const handleRadioClick = (e: React.MouseEvent) => {
    if (!isAttributesEditMode) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleAttributeChange = (key: string, value: boolean) => {
    setAttributeChanges((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSaveAttributes = () => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to save these changes?",
        data: {
          title: "Save Changes",
          footer: true,
          cancelBtn: true,
          buttonText: {
            cancel: "Cancel",
            submit: "Update",
          },
          onSubmit: ({ onClose }: any) => {
            handleUpdateConf(attributeChanges);
            setIsAttributesEditMode(false);
            setAttributeChanges({});
            onClose();
          },
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isOpenImgLightBox && (
          <Lightbox
            enableZoom={false}
            mainSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpenImgLightBox(false);
              setPrevSrc("");
            }}
          />
        )}
        {(isOpenDocLightBox || isOpenVideoLightBox) && (
          <div
            className="position-fixed top-0 end-0 bottom-0 start-0"
            style={{
              zIndex: 1000,
              // backgroundColor: "rgba(0,0,0,0.5)",
              backgroundColor: "white",
            }}
          >
            <div
              className="position-absolute text-white cursor-pointer"
              style={{
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                padding: "5px 10px",
                right: 10,
                top: 40,
              }}
            >
              <i
                className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
                onClick={() => {
                  setIsOpenDocLightBox(false);
                  setIsOpenVideoLightBox(false);
                  setPrevSrc("");
                }}
              />
            </div>
            {isOpenVideoLightBox ? (
              <video
                // playbackRate={2}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
                height="100%"
                width="100%"
              >
                <source src={prevSrc} type={`video/mp4`} />
              </video>
            ) : (
              <iframe
                id="iframe"
                title={prevSrc}
                src={prevSrc}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            )}
          </div>
        )}
        <Container fluid>
          <BreadCrumb
            title={props?.router?.params?.id}
            isBack={true}
            backClick={() => {
              const currentPath = props.router.location.pathname;

              if (subGroupId) {
                props.router.navigate(
                  `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                );
              } else if (
                currentPath.startsWith("/campaigns/") &&
                /\d+$/.test(currentPath)
              ) {
                props.router.navigate(-1);
              } else {
                props.router.navigate(-1);
              }
            }}
          />

          <div className="position-relative wrapper">
            {isInit ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Card>
                  {isPartner ? null : (
                    <Card.Header className="d-flex justify-content-end align-items-center">
                      {campaignDetails?.status !== "EXPIRED" ? (
                        <Dropdown>
                          <Dropdown.Toggle className="text-white">
                            <span className="text-white fs-lg">
                              <i className="mdi mdi-dots-vertical align-middle"></i>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-end">
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    path: "InputModal",
                                    data: {
                                      title: "Nudge CNP to Review",
                                      bodyDesc:
                                        "Are you sure you would like to <b>nudge the CNP to Review?</b><br />This will remind the upstream CNP to review the campaign.<br />If yes, you may add an optional message below to send with your action.",
                                      footer: false,
                                      otherSubmitArgs: {
                                        nudgeIntent: "REVIEW",
                                      },
                                      inputModalSubmit,
                                    },
                                  })
                                );
                              }}
                            >
                              Nudge CNP to Review
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    path: "InputModal",
                                    data: {
                                      title: "Nudge CNP to Review",
                                      bodyDesc:
                                        "Are you sure you would like to <b>nudge the CNP to Appeal Rejection?</b><br />This will notify the CNP that the rejected campaign has been updated and can be resubmitted.<br />If yes, you may add an optional message below to send with your action.",
                                      footer: false,
                                      otherSubmitArgs: {
                                        nudgeIntent: "APPEAL_REJECTION",
                                      },
                                      inputModalSubmit,
                                    },
                                  })
                                );
                              }}
                            >
                              Nudge CNP to Appeal Rejection
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    path: "ResubmitCampaign",
                                    data: {
                                      modalClass: "campResubmitModal",
                                      title: "Campaign Resubmission",
                                      campaignMnoMetadata,
                                      campaignTerms,
                                      bodyDesc:
                                        "Below is a preview of the current and new terms that would be adopted if you choose to resubmit the campaign against thenewest vetting score. Current terms are shown on the left and new terms are shown on the right.<br />Select the MNO's you wish to resubmit with. MNOs that currently do not qualify are greyed out.",
                                      footer: true,
                                      cancelBtn: true,
                                      buttonText: {
                                        submit: "Submit with New Terms",
                                      },
                                      onSubmit: ({ onClose }: any) => {
                                        onClose();
                                        dispatch(
                                          openModal({
                                            modalbody:
                                              "The campaign will adopt all the BEST vetting terms not the NEWEST vetting terms.<br />Are you sure want to submit with the newest terms?",
                                            data: {
                                              title:
                                                "Resubmission Confirmation",
                                              footer: true,
                                              buttonText: {
                                                submit: "Confirm",
                                              },
                                              cancelBtn: true,
                                              onSubmit: ({ onClose }: any) => {
                                                submitLoadingSubject.next(true);
                                                const cb2 = () => onClose();
                                                const qualifyData =
                                                  campaignTerms?.mnoMetadata &&
                                                  Object.fromEntries(
                                                    Object.entries(
                                                      campaignTerms?.mnoMetadata
                                                    ).filter(
                                                      ([key, value]: any) =>
                                                        value?.qualify
                                                    )
                                                  );

                                                dispatch(
                                                  resubmitCampaignThunk(
                                                    props?.router?.params?.id,
                                                    {
                                                      mnoIds:
                                                        qualifyData &&
                                                        Object.keys(qualifyData)
                                                          ?.length > 0
                                                          ? Object.keys(
                                                              qualifyData
                                                            )
                                                          : [],
                                                    },
                                                    cb2
                                                  )
                                                ).then(() => {
                                                  submitLoadingSubject.next(
                                                    false
                                                  );
                                                });
                                              },
                                            },
                                          })
                                        );
                                      },
                                    },
                                  })
                                );
                              }}
                            >
                              Resubmit Campaign
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalbody:
                                      "Are you sure you want to <b>deactivate</b> the campaign?<br />This action is irrevocable.<br />Should you choose to continue, the campaign will be deactivated and campaign details will be removed from the nnSR (netnumber Services Registry) database.",
                                    data: {
                                      title: "Deactivate Campaign",
                                      footer: true,
                                      cancelBtn: true,
                                      buttonText: {
                                        submit: "Continue",
                                      },
                                      onSubmit: ({ onClose }: any) => {
                                        const cb = () => onClose();
                                        submitLoadingSubject.next(true);
                                        dispatch(
                                          deactivateCampaignThunk(
                                            props?.router?.params?.id,
                                            cb,
                                            isPartner
                                          )
                                        ).then(() => {
                                          submitLoadingSubject.next(false);
                                        });
                                      },
                                    },
                                  })
                                );
                              }}
                            >
                              Deactivate Campaign
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : null}
                      <Tooltip
                        placement="bottomRight"
                        overlay={
                          <div>
                            <p>
                              <b>Review:</b> Use this to remind your CNP to
                              review the campaign and/or take action.
                            </p>
                            <p>
                              <b>Appeal Rejection:</b> Use this after you have
                              edited and resubmitted a campaign to appeal the
                              rejection by your CNP/DCA.
                            </p>
                            <p>
                              <b>Resubmit Campaign:</b> Use this to resubmit a
                              campaign to pick up the highest carrier terms or
                              vetting results. This does not trigger a review by
                              your CNP or DCA partner. Do not use this to
                              resubmit a campaign that has been rejected by your
                              CNP or DCA.
                            </p>
                            <p className="mb-0">
                              <b>Deactivate:</b> The Campaign will be
                              immediately deactivated on all MNOS.
                            </p>
                          </div>
                        }
                      >
                        <i className="ri-information-line text-secondary fs-3xl ms-2"></i>
                      </Tooltip>
                    </Card.Header>
                  )}
                  <Card.Body>
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Brand Name:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.brandName ||
                                campaignDetails?.brand?.displayName ||
                                "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Registered on:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.createDate
                                ? dateFormat(
                                    campaignDetails?.createDate,
                                    "MM/DD/YYYY, hh:mm:ss A"
                                  )
                                : "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Use-Case:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.usecase || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Expiration Date:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.nextRenewalOrExpirationDate
                                ? dateFormat(
                                    campaignDetails?.nextRenewalOrExpirationDate
                                  )
                                : "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">TCR Status:</h6>
                          </Col>
                          <Col md={8}>
                            {campaignDetails?.status === "ACTIVE" ? (
                              <p className="mb-0 me-md-4 text-secondary">
                                <b>Active </b>
                              </p>
                            ) : campaignDetails?.status === "EXPIRED" ? (
                              <p className="mb-0 me-md-4 text-primary">
                                <b>Deactivated </b>
                              </p>
                            ) : (
                              "-"
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Migration:</h6>
                          </Col>
                          <Col md={8}>-</Col>
                        </Row>
                      </Col>
                      {!isPartner && (
                        <>
                          <Col xl={6} className="mb-2">
                            <Row className="g-0">
                              <Col md={4}>
                                <h6 className="mb-0">Group Id:</h6>
                              </Col>
                              <Col md={8}>
                                {campaignDetails?.groupId || "-"}
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6} className="mb-2">
                            <Row className="g-0">
                              <Col md={4}>
                                <h6 className="mb-0">Subgroups:</h6>
                              </Col>
                              <Col md={8}>
                                <div className="d-flex flex-wrap mt-1 mt-sm-0">
                                  {campaignDetails?.subGroupId?.length > 0
                                    ? campaignDetails?.subGroupId?.map(
                                        (dt: string, i2: number) => {
                                          return (
                                            <span
                                              className="details-tag"
                                              key={i2}
                                            >
                                              {dt}
                                            </span>
                                          );
                                        }
                                      )
                                    : "-"}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6} className="mb-2">
                            <Row className="g-0">
                              <Col md={4}>
                                <h6 className="mb-0">Phone Numbers:</h6>
                              </Col>
                              <Col md={8}>
                                <div className="d-flex flex-wrap mt-1 mt-sm-0">
                                  {campaignDetails?.phoneNumbers?.length > 0
                                    ? campaignDetails?.phoneNumbers?.map(
                                        (dt: string, i2: number) => {
                                          return (
                                            <span
                                              className="details-tag"
                                              key={i2}
                                            >
                                              {dt}
                                            </span>
                                          );
                                        }
                                      )
                                    : "-"}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}
                      {!isPartner && campaignDetails?.status !== "EXPIRED" ? (
                        <Col xl={6}>
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Auto Renewal:</h6>
                            </Col>
                            <Col md={8}>
                              <div className="mb-0 me-md-4">
                                <div
                                  className="form-check form-switch form-switch-md"
                                  dir="ltr"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={campaignDetails?.autoRenewal}
                                    onChange={() => {
                                      dispatch(
                                        openModal({
                                          modalbody:
                                            "Are you sure you want to <b>cancel the Auto-Renewal</b>?",
                                          data: {
                                            title: "Auto Renewal",
                                            footer: true,
                                            cancelBtn: true,
                                            buttonText: {
                                              submit: "Continue",
                                            },
                                            onSubmit: ({ onClose }: any) => {
                                              const cb = () => onClose();
                                              handleUpdateCampaign2({
                                                obj: {
                                                  autoRenewal:
                                                    !campaignDetails?.autoRenewal,
                                                },
                                                cb,
                                              });
                                            },
                                          },
                                        })
                                      );
                                    }}
                                  />
                                  <Tooltip
                                    placement="bottomRight"
                                    overlay={
                                      "If set to No, the campaign will be deactivated at the end of the active period."
                                    }
                                  >
                                    <i className="ri-information-line text-secondary fs-3xl"></i>
                                  </Tooltip>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                  </Card.Body>
                </Card>

                {/* Carrier Status */}
                <Card>
                  <Card.Header>
                    <h6 className="card-title mb-2">Carrier Status</h6>
                  </Card.Header>
                  <Card.Body>
                    <div className="">
                      {campaignMnoMetadata &&
                      Object.keys(campaignMnoMetadata).length > 0
                        ? Object.keys(campaignMnoMetadata).map((dt, i) => (
                            <Row className="mb-3 p-2" key={i}>
                              <Col className="bg-secondary text-white d-flex align-items-center justify-content-center">
                                {campaignMnoMetadata?.[dt]?.mno}
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  Qualify
                                </div>
                                <div className="p-2 bg-body">
                                  {campaignMnoMetadata?.[dt]?.qualify
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  MNO Review
                                </div>
                                <div className="p-2 bg-body">
                                  {campaignMnoMetadata?.[dt]?.mnoReview
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  {campaignMnoMetadata?.[dt]?.mno === "T-Mobile"
                                    ? "Brand Tier"
                                    : "TPM Scope"}
                                </div>
                                <div className="p-2 bg-body">
                                  {campaignMnoMetadata?.[dt]?.mno === "T-Mobile"
                                    ? campaignMnoMetadata?.[dt]?.brandTier ||
                                      "N/A"
                                    : campaignMnoMetadata?.[dt]?.tpmScope ||
                                      "N/A"}
                                </div>
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  {campaignMnoMetadata?.[dt]?.mno === "T-Mobile"
                                    ? "Brand Daily Cap"
                                    : "SMS TPM"}
                                </div>
                                <div className="p-2 bg-body">
                                  {campaignMnoMetadata?.[dt]?.mno === "T-Mobile"
                                    ? campaignMnoMetadata?.[dt]?.brandDailyCap
                                      ? "Yes"
                                      : "No"
                                    : campaignMnoMetadata?.[dt]?.tpm || "N/A"}
                                </div>
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  MMS TPM
                                </div>
                                <div className="p-2 bg-body">
                                  {campaignMnoMetadata?.[dt]?.mmsTpm || "N/A"}
                                </div>
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  Message Class
                                </div>
                                <div className="p-2 bg-body">
                                  {campaignMnoMetadata?.[dt]?.msgClass || "N/A"}
                                </div>
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  Status
                                </div>
                                <div
                                  className={`p-2 bg-body ${
                                    campaignOperationStatus?.[
                                      dt
                                    ]?.toLowerCase() === "registered"
                                      ? "text-secondary"
                                      : campaignOperationStatus?.[
                                          dt
                                        ]?.toLowerCase() === "rejected" ||
                                        campaignOperationStatus?.[
                                          dt
                                        ]?.toLowerCase() === "failed"
                                      ? "text-primary"
                                      : campaignOperationStatus?.[
                                          dt
                                        ]?.toLowerCase() === "pending"
                                      ? "text-black"
                                      : ""
                                  }`}
                                >
                                  <b>{campaignOperationStatus?.[dt]}</b>
                                </div>
                              </Col>
                              <Col className="p-0 text-center">
                                <div className="bg-primary-subtle p-2">
                                  Elected DCA
                                </div>
                                <div className="p-2 bg-body">
                                  {campaignDcaElected?.includes(dt) ||
                                  campaignDcaElected?.includes(Number(dt))
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </Col>
                            </Row>
                          ))
                        : null}
                    </div>
                  </Card.Body>
                </Card>

                {/* Brand Details */}
                {isPartner ? (
                  <Card>
                    <Card.Header>
                      <h6 className="card-title mb-2">Brand Details</h6>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Legal Company Name:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">
                                {campaignDetails?.brand?.companyName || "-"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Brand Support Email:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">
                                {campaignDetails?.brand?.email || "-"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Brand Name or DBA:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">
                                {campaignDetails?.brand?.displayName || "-"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Russell3000:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">-</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">External Vetting Score:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">
                                {campaignDetails?.brand?.evpVettingScore || "-"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Tax Exempt Status:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">
                                {campaignDetails?.brand?.optionalAttributes
                                  ?.taxExemptStatus || "-"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Website/Online Presence:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">-</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Government Entity:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">-</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">Support Phone Number:</h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">
                                {campaignDetails?.brand?.phone || "-"}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">
                                Political Committee Locale:
                              </h6>
                            </Col>
                            <Col md={8}>
                              <p className="mb-0 me-md-4">-</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : null}

                {/* Campaign and Content Attributes */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">
                      Campaign and Content Attributes
                    </h6>
                    <div className="d-flex gap-2">
                      {isAttributesEditMode ? (
                        <>
                          <Button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              setIsAttributesEditMode(false);
                              setAttributeChanges({});
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            onClick={handleSaveAttributes}
                            disabled={
                              Object.keys(attributeChanges).length === 0
                            }
                          >
                            Update
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="secondary"
                          onClick={() => setIsAttributesEditMode(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={6}>
                        <ul className="list-group list-group-flush">
                          {/* Subscriber Opt-in */}
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Opt-in
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberOptinYes"
                                      label="Yes"
                                      name="subscriberOptin"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptin"
                                        )
                                          ? attributeChanges.subscriberOptin ===
                                            true
                                          : campaignDetails?.subscriberOptin ===
                                            true
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptin",
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberOptinNo"
                                      label="No"
                                      name="subscriberOptin"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptin"
                                        )
                                          ? attributeChanges.subscriberOptin ===
                                            false
                                          : campaignDetails?.subscriberOptin ===
                                            false
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptin",
                                          false
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {attributeChanges?.subscriberOptin ||
                                    campaignDetails?.subscriberOptin ? (
                                      <HoverPopover>
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation1.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optinKeywords">
                                              Type Opt-in Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              className="react-tagsinput"
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation1.values.optinKeywords
                                                  ? validation1.values.optinKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation1.setFieldValue(
                                                  "optinKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation1.touched
                                                ?.optinKeywords &&
                                              validation1.errors
                                                ?.optinKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation1.errors
                                                      ?.optinKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optinMessage">
                                              Type Opt-in Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="optinMessage"
                                              name="optinMessage"
                                              onChange={
                                                validation1.handleChange
                                              }
                                              onBlur={validation1.handleBlur}
                                              value={
                                                validation1.values
                                                  .optinMessage || ""
                                              }
                                              className={`form-control ${
                                                validation1.touched
                                                  ?.optinMessage &&
                                                validation1.errors?.optinMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation1.touched
                                                  ?.optinMessage &&
                                                validation1.errors
                                                  ?.optinMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation1.errors
                                                        ?.optinMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation1.values
                                                    .optinMessage?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={loading2}
                                            onClick={() =>
                                              validation1.handleSubmit()
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          {/* Subscriber Opt-out */}
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Opt-Out{" "}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberOptoutYes"
                                      label="Yes"
                                      name="subscriberOptout"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptout"
                                        )
                                          ? attributeChanges.subscriberOptout ===
                                            true
                                          : campaignDetails?.subscriberOptout ===
                                            true
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptout",
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberOptoutNo"
                                      label="No"
                                      name="subscriberOptout"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptout"
                                        )
                                          ? attributeChanges.subscriberOptout ===
                                            false
                                          : campaignDetails?.subscriberOptout ===
                                            false
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptout",
                                          false
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {attributeChanges?.subscriberOptout ||
                                    campaignDetails?.subscriberOptout ? (
                                      <HoverPopover>
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation2.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optoutKeywords">
                                              Type Opt-out Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              className="react-tagsinput"
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation2.values
                                                  .optoutKeywords
                                                  ? validation2.values.optoutKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation2.setFieldValue(
                                                  "optoutKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation2.touched
                                                ?.optoutKeywords &&
                                              validation2.errors
                                                ?.optoutKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation2.errors
                                                      ?.optoutKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optoutMessage">
                                              Type Opt-out Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="optoutMessage"
                                              name="optoutMessage"
                                              onChange={
                                                validation2.handleChange
                                              }
                                              onBlur={validation2.handleBlur}
                                              value={
                                                validation2.values
                                                  .optoutMessage || ""
                                              }
                                              className={`form-control ${
                                                validation2.touched
                                                  ?.optoutMessage &&
                                                validation2.errors
                                                  ?.optoutMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation2.touched
                                                  ?.optoutMessage &&
                                                validation2.errors
                                                  ?.optoutMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation2.errors
                                                        ?.optoutMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation2.values
                                                    .optoutMessage?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={loading2}
                                            onClick={() =>
                                              validation2.handleSubmit()
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          {/* Subscriber Help */}
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Help
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberHelpYes"
                                      label="Yes"
                                      name="subscriberHelp"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberHelp"
                                        )
                                          ? attributeChanges.subscriberHelp ===
                                            true
                                          : campaignDetails?.subscriberHelp ===
                                            true
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "subscriberHelp",
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberHelpNo"
                                      label="No"
                                      name="subscriberHelp"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberHelp"
                                        )
                                          ? attributeChanges.subscriberHelp ===
                                            false
                                          : campaignDetails?.subscriberHelp ===
                                            false
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "subscriberHelp",
                                          false
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {attributeChanges?.subscriberHelp ||
                                    campaignDetails?.subscriberHelp ? (
                                      <HoverPopover>
                                        <Form
                                          action="#"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation3.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="helpKeywords">
                                              Type Help Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              className="react-tagsinput"
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation3.values.helpKeywords
                                                  ? validation3.values.helpKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation3.setFieldValue(
                                                  "helpKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation3.touched
                                                ?.helpKeywords &&
                                              validation3.errors
                                                ?.helpKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation3.errors
                                                      ?.helpKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="helpMessage">
                                              Type Help Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="helpMessage"
                                              name="helpMessage"
                                              onChange={
                                                validation3.handleChange
                                              }
                                              onBlur={validation3.handleBlur}
                                              value={
                                                validation3.values
                                                  .helpMessage || ""
                                              }
                                              className={`form-control ${
                                                validation3.touched
                                                  ?.helpMessage &&
                                                validation3.errors?.helpMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation3.touched
                                                  ?.helpMessage &&
                                                validation3.errors
                                                  ?.helpMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation3.errors
                                                        ?.helpMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation3.values.helpMessage
                                                    ?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            type="submit"
                                            variant="primary"
                                            className="d-flex m-auto"
                                            disabled={loading2}
                                            onClick={() =>
                                              validation3.handleSubmit()
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          {/* Number Pooling */}
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Number Pooling
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="numberPoolYes"
                                      label="Yes"
                                      name="numberPool"
                                      value="Yes"
                                      disabled={!isAttributesEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "numberPool"
                                        )
                                          ? attributeChanges.numberPool === true
                                          : campaignDetails?.numberPool === true
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "numberPool",
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="numberPoolNo"
                                      label="No"
                                      name="numberPool"
                                      value="No"
                                      disabled={
                                        !isAttributesEditMode
                                          ? true
                                          : campConditions?.reqNumberPool
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "numberPool"
                                        )
                                          ? attributeChanges.numberPool ===
                                            false
                                          : campaignDetails?.numberPool ===
                                            false
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "numberPool",
                                          false
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>

                      <Col lg={6}>
                        <ul className="list-group list-group-flush">
                          {/* Embedded Link */}
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Embedded Link
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="embeddedLinkYes"
                                      label="Yes"
                                      name="embeddedLink"
                                      value="Yes"
                                      disabled={!isAttributesEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedLink"
                                        )
                                          ? attributeChanges.embeddedLink ===
                                            true
                                          : campaignDetails?.embeddedLink ===
                                            true
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "embeddedLink",
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="embeddedLinkNo"
                                      label="No"
                                      name="embeddedLink"
                                      value="No"
                                      disabled={
                                        !isAttributesEditMode
                                          ? true
                                          : campConditions?.noEmbeddedLink
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedLink"
                                        )
                                          ? attributeChanges.embeddedLink ===
                                            false
                                          : campaignDetails?.embeddedLink ===
                                            false
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "embeddedLink",
                                          false
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>

                          {/* Embedded Phone */}
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Embedded Phone Number{" "}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="embeddedPhoneYes"
                                      label="Yes"
                                      name="embeddedPhone"
                                      value="Yes"
                                      disabled={!isAttributesEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedPhone"
                                        )
                                          ? attributeChanges.embeddedPhone ===
                                            true
                                          : campaignDetails?.embeddedPhone ===
                                            true
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "embeddedPhone",
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="embeddedPhoneNo"
                                      label="No"
                                      name="embeddedPhone"
                                      value="No"
                                      disabled={
                                        !isAttributesEditMode
                                          ? true
                                          : campConditions?.noEmbeddedPhone
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedPhone"
                                        )
                                          ? attributeChanges.embeddedPhone ===
                                            false
                                          : campaignDetails?.embeddedPhone ===
                                            false
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange(
                                          "embeddedPhone",
                                          false
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>

                          {/* Age Gated */}
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Age Gated
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="ageGatedYes"
                                      label="Yes"
                                      name="ageGated"
                                      value="Yes"
                                      disabled={!isAttributesEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "ageGated"
                                        )
                                          ? attributeChanges.ageGated === true
                                          : campaignDetails?.ageGated === true
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange("ageGated", true)
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="ageGatedNo"
                                      label="No"
                                      name="ageGated"
                                      value="No"
                                      disabled={!isAttributesEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "ageGated"
                                        )
                                          ? attributeChanges.ageGated === false
                                          : campaignDetails?.ageGated === false
                                      }
                                      onChange={() =>
                                        isAttributesEditMode &&
                                        handleAttributeChange("ageGated", false)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>

                          {/* Terms & Conditions (Always Disabled) */}
                          <li className="list-group-item ps-0 pb-1">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Terms & Conditions
                                  </span>
                                  <span className="text-muted d-block">
                                    {campaignTnC?.subTerms?.[0]}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="termsAndConditionsYes"
                                      label="Yes"
                                      name="termsAndConditions"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        campaignDetails?.termsAndConditions ===
                                        true
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="termsAndConditionsNo"
                                      label="No"
                                      name="termsAndConditions"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        campaignDetails?.termsAndConditions ===
                                        false
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Campaign Details */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Campaign Details</h6>
                    <div className="d-flex">
                      {isPartner ? null : (
                        <Button
                          variant="secondary"
                          className="ms-2"
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "EditCampaignDetails",
                                data: {
                                  title: "Campaign Details",
                                  campaignDetails,
                                  campaignDetailsSubmit,
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Privacy Policy Link:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.privacyPolicyLink ? (
                                <a
                                  href={campaignDetails?.privacyPolicyLink}
                                  target="_blank"
                                >
                                  {campaignDetails?.privacyPolicyLink}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Terms and Conditions Link:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.termsAndConditionsLink ? (
                                <a
                                  href={campaignDetails?.termsAndConditionsLink}
                                  target="_blank"
                                >
                                  {campaignDetails?.termsAndConditionsLink}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Website:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.website ? (
                                <a
                                  href={campaignDetails?.website}
                                  target="_blank"
                                >
                                  {campaignDetails?.website}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={2}>
                        <h6 className="mb-0">Campaign Description:</h6>
                      </Col>
                      <Col md={10}>
                        <p className="mb-0 me-md-4">
                          {campaignDetails?.description}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <h6 className="mb-0">Call-to-Action / Message Flow:</h6>
                      </Col>
                      <Col md={10}>
                        <p className="mb-0">{campaignDetails?.messageFlow}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Sample Messages */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Sample Messages</h6>
                    <div className="d-flex">
                      {isPartner ? null : (
                        <Button
                          variant="secondary"
                          className="ms-2"
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "EditSampleMessages",
                                data: {
                                  title: "Sample Messages",
                                  campaignDetails,
                                  sampleMessagesSubmit,
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {campaignDetails?.sample1 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          1
                        </span>
                        {campaignDetails?.sample1}
                      </p>
                    ) : null}
                    {campaignDetails?.sample2 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          2
                        </span>
                        {campaignDetails?.sample2}
                      </p>
                    ) : null}
                    {campaignDetails?.sample3 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          3
                        </span>
                        {campaignDetails?.sample3}
                      </p>
                    ) : null}
                    {campaignDetails?.sample4 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          4
                        </span>
                        {campaignDetails?.sample4}
                      </p>
                    ) : null}
                    {campaignDetails?.sample5 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          5
                        </span>
                        {campaignDetails?.sample5}
                      </p>
                    ) : null}
                  </Card.Body>
                </Card>

                {/* Sample Multimedia */}
                {isPartner ? null : (
                  <Card>
                    <Card.Header>
                      <h6 className="card-title mb-2">Sample Multimedia</h6>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Dropzone
                            onDrop={(
                              acceptedFiles: any,
                              fileRejections: any
                            ) => {
                              if (selectedFiles?.length > 4) {
                                toast.error(
                                  "Max 5 uploads per campaign is allowed"
                                );
                                return;
                              }

                              // Validate each file
                              const validFiles = acceptedFiles.filter(
                                (file: File) => {
                                  const validation = validateFile(file);
                                  if (!validation.isValid) {
                                    toast.error(validation.error);
                                    return false;
                                  }
                                  return true;
                                }
                              );

                              // Handle rejections
                              fileRejections?.forEach((file: any) => {
                                file.errors.forEach(
                                  (err: { code: string; message: string }) => {
                                    if (err.code === "file-too-large") {
                                      toast.error("File is larger than 10MB");
                                    }
                                    if (err.code === "file-invalid-type") {
                                      toast.error(
                                        "Invalid file type. Please upload supported file formats only"
                                      );
                                    }
                                  }
                                );
                              });

                              // Only proceed with valid files
                              if (validFiles.length > 0) {
                                handleAcceptedFiles(validFiles, fileRejections);
                              }
                            }}
                            maxSize={MAX_FILE_SIZE}
                            multiple={false}
                            accept={{
                              "image/bmp": [".bmp", ".dib"],
                              "image/gif": [".gif"],
                              "image/jpeg": [".jpeg", ".jpg"],
                              "image/png": [".png"],
                              "image/webp": [".webm"],
                              "audio/mpeg": [
                                ".m2a",
                                ".mp1",
                                ".mp2",
                                ".mp3",
                                ".mpa",
                              ],
                              "audio/mp4": [".m4a", ".m4b", ".m4p", ".m4r"],
                              "video/mp4": [".m4v", ".mp4"],
                              "audio/ogg": [
                                ".oga",
                                ".ogg",
                                ".ogm",
                                ".ogv",
                                ".ogx",
                                ".spx",
                              ],
                              "audio/wav": [".wav"],
                              "text/plain": [".txt"],
                            }}
                          >
                            {({ getRootProps }: any) => (
                              <div className="position-relative dropzone dz-clickable text-center border rounded">
                                {isLoading ? (
                                  <div className="d-flex justify-content-center align-items-center position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                                    <img
                                      src={Loader2}
                                      alt="loading"
                                      className="img-thumbnail border-0 bg-transparent"
                                    />
                                  </div>
                                ) : null}
                                <div
                                  className="dz-message needsclick cursor-pointer"
                                  {...getRootProps()}
                                >
                                  <div className="mb-3">
                                    <i className="ri-camera-fill text-muted fs-8xl" />
                                    <i className="ri-video-fill text-muted fs-8xl" />
                                    <i className="ri-file-music-fill text-muted fs-8xl" />
                                  </div>
                                  <p className="fs-lg">
                                    Drop a multimedia file to upload or click to
                                    upload
                                  </p>
                                  <p className="fs-md text-muted">
                                    Maximum upload file size: 10MB
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>{" "}
                        </Col>
                        <Col lg={6}>
                          <div className="border rounded p-3 dropzone flex-column">
                            <h6 className="mb-3">Sample Multimedia Files</h6>
                            <ul className="vstack gap-2 list-unstyled mb-0">
                              {selectedFiles?.map(
                                (
                                  dt: {
                                    mimeType?: string;
                                    fileName?: string;
                                    name?: string;
                                  },
                                  i: number
                                ) => (
                                  <li key={i}>
                                    <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="avatar-xs flex-shrink-0 ronded">
                                          <div className="avatar-title bg-body-secondary text-body">
                                            {dt?.mimeType?.startsWith(
                                              "image/"
                                            ) ? (
                                              <i className="ri ri-image-line fs-lg"></i>
                                            ) : dt?.mimeType?.startsWith(
                                                "video/"
                                              ) ? (
                                              <i className="ri ri-video-line fs-lg"></i>
                                            ) : (
                                              <i className="ri ri-file-line fs-lg"></i>
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h6
                                            className="mb-0"
                                            style={{ wordBreak: "break-word" }}
                                          >
                                            {dt?.fileName || dt?.name || ""}
                                          </h6>
                                        </div>
                                        <div className="flex-shrink-0 text-muted">
                                          <i
                                            className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                            onClick={() => {
                                              !loading2 && handleDownloadImg(i);
                                            }}
                                          />
                                          <i
                                            className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                            onClick={() => {
                                              !loading2 && handleViewImg(i);
                                            }}
                                          />
                                          <i
                                            className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                            onClick={() => {
                                              if (!loading2) {
                                                dispatch(
                                                  openModal({
                                                    modalbody:
                                                      "Are you sure you want to delete this file?",
                                                    data: {
                                                      title: "Delete File",
                                                      footer: true,
                                                      cancelBtn: true,
                                                      buttonText: {
                                                        submit: "Continue",
                                                      },
                                                      onCancel: ({
                                                        onClose,
                                                      }: any) => {
                                                        onClose();
                                                      },
                                                      onSubmit: ({
                                                        onClose,
                                                      }: any) => {
                                                        handleDeleteImg({
                                                          onClose,
                                                          deleteId: i,
                                                        });
                                                      },
                                                    },
                                                  })
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col lg={12}>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleAcceptedFiles(acceptedFiles, rejectedFiles);
                            setAcceptedFiles([]);
                            setRejectedFiles([]);
                          }}
                          variant="primary"
                          className="d-flex m-auto"
                          disabled={acceptedFiles?.length < 1 || isLoading}
                        >
                          Add Sample Multimedia
                        </Button>
                      </Col>
                    </Row> */}
                    </Card.Body>
                  </Card>
                )}

                {/* Events */}
                <Card>
                  <Card.Header>
                    <h6 className="card-title mb-2">Events</h6>
                  </Card.Header>
                  <Card.Body className="position-relative">
                    {loading3 ? (
                      <div style={{ height: 60 }}>
                        <img
                          src={Loader}
                          className={`position-absolute top-50 start-50 translate-middle`}
                          alt="loading"
                        />
                      </div>
                    ) : (
                      <Datatable
                        data={
                          Array.isArray(cspWebhookEventsData?.records)
                            ? cspWebhookEventsData?.records
                            : []
                        }
                        columns={columns}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        currPage={currPage}
                        rowsPerPage={rowsPerPage}
                        totalRecords={cspWebhookEventsData?.totalRecords}
                      />
                    )}
                  </Card.Body>
                </Card>

                {/* Number Provisioned */}
                {isPartner ? null : (
                  <Card className="card-height-100">
                    <Card.Body>
                      <Tab.Container defaultActiveKey="current">
                        <Nav
                          as="ul"
                          variant="tabs"
                          className="nav-tabs-custom nav-primary mb-4"
                        >
                          <Nav.Item as="li">
                            <Nav.Link eventKey="current">
                              Current Number Provisioned
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="previous">
                              Previous Number Provisioned
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="current">
                            <div className="position-relative">
                              {loading4 ? (
                                <div style={{ height: 60 }}>
                                  <img
                                    src={Loader}
                                    className={`position-absolute top-50 start-50 translate-middle`}
                                    alt="loading"
                                  />
                                </div>
                              ) : (
                                <Datatable
                                  data={
                                    Array.isArray(currNumProvData?.records)
                                      ? currNumProvData?.records
                                      : []
                                  }
                                  columns={columns2(true)}
                                  handlePageChange={handlePageChange1}
                                  handleRowsPerPageChange={
                                    handleRowsPerPageChange1
                                  }
                                  currPage={currPage1}
                                  rowsPerPage={rowsPerPage1}
                                  totalRecords={currNumProvData?.totalRecords}
                                />
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="previous">
                            <div className="position-relative">
                              {loading4 ? (
                                <div>
                                  <img
                                    src={Loader}
                                    className={`position-absolute top-50 start-50 translate-middle`}
                                    alt="loading"
                                  />
                                </div>
                              ) : (
                                <Datatable
                                  data={
                                    Array.isArray(prevNumProvData?.records)
                                      ? prevNumProvData?.records
                                      : []
                                  }
                                  columns={columns2(false)}
                                  handlePageChange={handlePageChange2}
                                  handleRowsPerPageChange={
                                    handleRowsPerPageChange2
                                  }
                                  currPage={currPage2}
                                  rowsPerPage={rowsPerPage2}
                                  totalRecords={prevNumProvData?.totalRecords}
                                />
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card.Body>
                  </Card>
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CampaignDetails);
