import {
  getDashboardAnalyticsApi,
  getUsageSummaryApi,
  getUsageTransactionsApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  apiLoading3,
  usageSummaryDataSuccessful,
  usageSummaryDataFailed,
  usageSummaryDashboardDataSuccessful,
  usageSummaryDashboardDataFailed,
  usageTransactionsDataSuccessful,
  usageTransactionsDataFailed,
  dashboardAnalyticsDataSuccessful,
  dashboardAnalyticsDataFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getUsageSummaryThunk =
  (startDate?: string, endDate?: string) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getUsageSummaryApi(startDate, endDate);

      if (data?.code || data?.message) {
        dispatch(usageSummaryDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(usageSummaryDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(usageSummaryDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getUsageSummaryDashboardThunk =
  (startDate?: string, endDate?: string) => async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await getUsageSummaryApi(startDate, endDate);

      if (data?.code || data?.message) {
        dispatch(usageSummaryDashboardDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(usageSummaryDashboardDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(usageSummaryDashboardDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getUsageTransactionsThunk =
  (
    serviceType?: string,
    startDate?: string,
    endDate?: string,
    page?: number,
    limit?: number
  ) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await getUsageTransactionsApi(
        serviceType,
        startDate,
        endDate,
        page,
        limit
      );
      if (data?.code || data?.message) {
        dispatch(usageTransactionsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(usageTransactionsDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(usageTransactionsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getDashboardAnalyticsThunk =
  (otherParams?: any) => async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await getDashboardAnalyticsApi(otherParams);

      console.log("Efwfef", data);
      dispatch(dashboardAnalyticsDataSuccessful(data?.data));
    } catch (error: any) {
      dispatch(dashboardAnalyticsDataFailed(error));
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
