import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncPortingPhoneNumberList,
  asyncSubgroupList,
  capitalizeString,
  customSelectTheme,
  dateFormat,
  getSortIcon,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getFileThunk, getPortRequestListThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";

const initialFilterValues = {
  portingId: "",
  submissionDate: "",
  startDate: "",
  endDate: "",
  completionDate: "",
  activationDateStart: "",
  activationDateEnd: "",
  subGroupId: [],
  brandId: [],
  campaignId: [],
  phoneNumber: [],
};

const PortRequests = (props: any) => {
  document.title = "Signal House Portal Port Requests";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const brandId = searchParams.get("brandId");
  const campaignId = searchParams.get("campaignId");
  const sortColumn = searchParams.get("sortColumn") || "";
  const isSortAsc = searchParams.get("sortDir") || "asc";

  const isSortClick = useRef(false);
  const [isInit, setIsInit] = useState(true);
  const [open, setOpen] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [iconDisable, setIconDisable] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);

  const selector = createSelector(
    (state: any) => state.Ports,
    (state: any) => state.Groups,
    (ports, groups) => ({
      loading: ports.loading,
      portRequestsData: ports.portRequestsArr,
      error: ports.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, portRequestsData, groupDetails } = useSelector(selector);

  const columns = [
    {
      fieldName: "createdDate",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "createdDate" ? "hideIcon" : ""
          }`}
        >
          Requested Date{" "}
          <span className="table-header-icon">
            {getSortIcon("createdDate", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {row.createdDate
            ? dateFormat(row.createdDate, "MM/DD/YYYY, hh:mm A")
            : "-"}
        </span>
      ),
      minWidth: "220px",
      width: "220px",
      sortable: true,
    },
    {
      fieldName: "phoneNumberToBePorted",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "phoneNumberToBePorted" ? "hideIcon" : ""
          }`}
        >
          Phone Number{" "}
          <span className="table-header-icon">
            {getSortIcon("phoneNumberToBePorted", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { phoneNumberToBePorted: string[] }) => {
        if (
          Array.isArray(row.phoneNumberToBePorted) &&
          row.phoneNumberToBePorted.length > 1
        ) {
          return (
            <Tooltip
              placement="top"
              overlay={<span>{row.phoneNumberToBePorted.join(", ")}</span>}
            >
              <span>
                {row.phoneNumberToBePorted[0]} <br />{" "}
                <small> and {row.phoneNumberToBePorted.length - 1} more</small>
              </span>
            </Tooltip>
          );
        }
        return row.phoneNumberToBePorted?.[0] || "-";
      },
      minWidth: "130px",
      sortable: true,
    },
    {
      fieldName: "ownersName",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "ownersName" ? "hideIcon" : ""
          }`}
        >
          Owners Name{" "}
          <span className="table-header-icon">
            {getSortIcon("ownersName", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { ownersName: string }) => row.ownersName || "-",
      minWidth: "200px",
      sortable: true,
    },
    {
      fieldName: "portingId",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "portingId" ? "hideIcon" : ""
          }`}
        >
          Porting ID{" "}
          <span className="table-header-icon">
            {getSortIcon("portingId", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { portingId: string }) => (
        <Tooltip
          placement="top"
          overlay={<p className="mb-0">{row.portingId || "-"}</p>}
        >
          <div onClick={() => handleRowClick(row)}>
            {row.portingId
              ? row.portingId.length > 10
                ? `${row.portingId.slice(0, 23)}...`
                : row.portingId
              : "-"}
          </div>
        </Tooltip>
      ),
      minWidth: "150px",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">LOA</span>,
      selector: (row: { letterOfAuthorization: string }) =>
        row.letterOfAuthorization ? (
          <i
            className={`ri-eye-fill ${
              iconDisable ? "cursor-not-allowed" : "cursor-pointer"
            } fs-2xl text-secondary`}
            onClick={() =>
              iconDisable ? {} : handleViewLoa(row.letterOfAuthorization)
            }
          />
        ) : (
          "-"
        ),
      sortable: false,
    },
    {
      fieldName: "portingStatus",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "portingStatus" ? "hideIcon" : ""
          }`}
        >
          Status{" "}
          <span className="table-header-icon">
            {getSortIcon("portingStatus", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (cell: { portingStatus: string }) => {
        const status = cell.portingStatus?.toLowerCase() || "";
        switch (status) {
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary"
              >
                {capitalizeString(status)}
              </span>
            );
          case "success":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(status)}
              </span>
            );
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge border"
                style={{
                  borderColor: "#6c757d",
                  background: "#d3d3d3e0",
                  color: "#6c757d",
                }}
              >
                {capitalizeString(status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(status)}
              </span>
            );
        }
      },
      minWidth: "150px",
      sortable: true,
    },
    {
      fieldName: "activationDate",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "activationDate" ? "hideIcon" : ""
          }`}
        >
          Completion Date{" "}
          <span className="table-header-icon">
            {getSortIcon("activationDate", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { activationDate: string }) =>
        row.activationDate ? (
          <span data-tag="allowRowEvents" className="d-flex align-items-center">
            <i
              data-tag="allowRowEvents"
              className="bi bi-calendar3 me-2 fs-lg text-secondary"
            />
            {dateFormat(row.activationDate, "MM/DD/YYYY, hh:mm A")}
          </span>
        ) : (
          "-"
        ),
      minWidth: "220px",
      sortable: true,
    },
  ];

  const handleRowClick = (row: { portingId: string }) => {
    navigate(`/porting-status/${row.portingId}`);
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      setOpen(!open);
      setCurrPage(1);
      handleGetData(1, rowsPerPage, values);
    },
  });

  useEffect(() => {
    handleGetData(1, rowsPerPage, {
      ...validation.values,
      subGroupId: subGroupId ? [subGroupId] : [],
      brandId: brandId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
            },
          ]
        : [],
      campaignId: campaignId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
              campaignId: campaignId || "",
            },
          ]
        : [],
    });
    setIsInit(false);
  }, []);

  useEffect(() => {
    subGroupId && validation.setFieldValue("subGroupId", [subGroupId]);
    brandId &&
      validation.setFieldValue("brandId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
        },
      ]);
    campaignId &&
      validation.setFieldValue("campaignId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
          campaignId: campaignId || "",
        },
      ]);
  }, [subGroupId, brandId, campaignId]);

  useEffect(() => {
    setCurrPage(1);
    !isInit && handleGetData(1, rowsPerPage, validation.values);
    isSortClick.current = false;
  }, [sortColumn, isSortAsc]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getPortRequestListThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          sortBy: sortColumn || "",
          sortOrder: sortColumn ? isSortAsc : "",
          submissionDate: "",
          startDate: otherParams?.startDate
            ? moment(otherParams?.startDate, "MM-DD-YYYY")
                .startOf("day")
                .toISOString()
            : "",
          endDate: otherParams?.endDate
            ? moment(otherParams?.endDate, "MM-DD-YYYY")
                .endOf("day")
                .toISOString()
            : "",
          completionDate: "",
          activationDateStart: otherParams?.activationDateStart
            ? moment(otherParams?.activationDateStart, "MM-DD-YYYY")
                .startOf("day")
                .toISOString()
            : "",
          activationDateEnd: otherParams?.activationDateEnd
            ? moment(otherParams?.activationDateEnd, "MM-DD-YYYY")
                .endOf("day")
                .toISOString()
            : "",
          subGroupId:
            otherParams?.subGroupId.length > 0 ? otherParams?.subGroupId : "",
          brandId:
            otherParams?.brandId.length > 0
              ? otherParams?.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            otherParams?.campaignId.length > 0
              ? otherParams?.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          phoneNumber:
            otherParams?.phoneNumber.length > 0
              ? otherParams?.phoneNumber?.map((dt: any) => dt.phoneNumber)
              : "",
        })
      )
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    !isSortClick.current && handleGetData(page, rowsPerPage, validation.values);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows, validation.values);
  };

  const handleSort = (column: any) => {
    isSortClick.current = true;
    if (sortColumn === column.fieldName) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        sortColumn: column.fieldName || "",
        sortDir: isSortAsc === "asc" ? "desc" : "asc",
      });
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        sortColumn: column.fieldName || "",
        sortDir: "asc",
      });
    }
  };

  const handleViewLoa = (loadId: string) => {
    setIconDisable(true);
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      window.open(temp2, "_blank");
    };
    dispatch(getFileThunk(loadId, cb)).then(() => {
      setIconDisable(false);
    });
  };

  return (
    <Container fluid>
      <BreadCrumb
        title="Porting Requests"
        isBack={Boolean(subGroupId)}
        backClick={() =>
          props.router.navigate(
            subGroupId
              ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
              : -1
          )
        }
        isFilter={true}
        filterButton={
          <Dropdown
            align="end"
            className="filterDropDown2"
            show={open}
            onToggle={() => {
              if (!datePickerModal) {
                setOpen(!open);
              }
            }}
          >
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ flex: "1 1 0%" }}
            >
              <div className="d-flex align-items-center flex-wrap">
                {validation.values.portingId && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Porting ID: {validation.values.portingId}
                  </span>
                )}
                {validation.values.submissionDate?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Requested Date: {validation.values.submissionDate}
                  </span>
                )}
                {validation.values.completionDate?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Completion Date: {validation.values.completionDate}`
                  </span>
                )}

                {validation.values.subGroupId?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.subGroupId?.join(", ")}
                    overlayClassName="subgroup-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.subGroupId.length === 1 ? (
                        `Subgroup Id: ${validation.values.subGroupId[0]}`
                      ) : (
                        <>
                          Subgroup Id: {validation.values.subGroupId[0]}
                          {` +${validation.values.subGroupId.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
                {validation.values.brandId?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.brandId
                      ?.map((dt: any) => dt.brandId)
                      ?.join(", ")}
                    overlayClassName="brand-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.brandId.length === 1 ? (
                        `Brand Id: ${validation.values.brandId[0].brandId}`
                      ) : (
                        <>
                          Brand Id: {validation.values.brandId[0].brandId}
                          {` +${validation.values.brandId.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
                {validation.values.campaignId?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.campaignId
                      ?.map((dt: any) => dt.campaignId)
                      ?.join(", ")}
                    overlayClassName="campaign-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.campaignId.length === 1 ? (
                        `Campaign Id: ${validation.values.campaignId[0].campaignId}`
                      ) : (
                        <>
                          Campaign Id:{" "}
                          {validation.values.campaignId[0].campaignId}
                          {` +${validation.values.campaignId.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}

                {validation.values.numberStatus && (
                  <Tooltip
                    placement="top"
                    overlay={`Number Status: ${validation.values.numberStatus}`}
                    overlayClassName="status-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Number Status: {validation.values.numberStatus}
                    </span>
                  </Tooltip>
                )}
                {validation.values.tenDLCStatus && (
                  <Tooltip
                    placement="top"
                    overlay={`Number Status: ${validation.values.tenDLCStatus}`}
                    overlayClassName="status-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Messaging Status: {validation.values.tenDLCStatus}
                    </span>
                  </Tooltip>
                )}

                {validation.values.friendlyName && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Friendly Name: {validation.values.friendlyName}
                  </span>
                )}
                {validation.values.qTags?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.qTags
                      ?.map((tagObj: any) => tagObj.tag)
                      ?.join(", ")}
                    overlayClassName="status-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.qTags.length === 1 ? (
                        `Tags: ${validation.values.qTags[0]?.tag}`
                      ) : (
                        <>
                          Tags: {validation.values.qTags[0]?.tag}{" "}
                          {` +${validation.values.qTags.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
                {validation.values.phoneNumber?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.phoneNumber
                      ?.map((dt: any) => dt.phoneNumber)
                      ?.join(", ")}
                    overlayClassName="phone-number-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.phoneNumber.length === 1 ? (
                        `Phone Number: ${validation.values.phoneNumber[0].phoneNumber}`
                      ) : (
                        <>
                          Phone Number:{" "}
                          {validation.values.phoneNumber[0].phoneNumber}
                          {` +${validation.values.phoneNumber.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
              </div>
              <Dropdown.Toggle variant="secondary">
                <i className="bx bx-filter-alt me-1"></i>
              </Dropdown.Toggle>
            </div>

            <Dropdown.Menu className="dropdown-menu-md p-4">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
              >
                <Row>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="portingId">Porting ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="portingId"
                      placeholder="Enter Porting ID"
                      onChange={(e) => {
                        validation.setFieldValue("portingId", e.target.value);
                      }}
                      value={validation.values.portingId}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="submissionDate">
                      Requested Date
                    </Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="submissionDate"
                      placeholder="Requested Date Range"
                      options={{
                        enableTime: false,
                        onOpen: () => {
                          setDatePickerModal(true);
                        },
                        onClose: () => {
                          setDatePickerModal(false);
                        },
                        altFormat: "F j, Y",
                        dateFormat: "m-d-Y",
                        mode: "range",
                        onChange: (
                          value: any,
                          dateStr: string,
                          instance: any
                        ) => {
                          validation.setFieldValue(
                            "submissionDate",
                            dateStr || ""
                          );
                          value?.[0] &&
                            validation.setFieldValue(
                              "startDate",
                              moment(value?.[0]).format("MM-DD-YYYY")
                            );
                          value?.[1] &&
                            validation.setFieldValue(
                              "endDate",
                              moment(value?.[1]).format("MM-DD-YYYY")
                            );
                        },
                      }}
                      value={validation.values.submissionDate || ""}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="completionDate">
                      Completion Date
                    </Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="completionDate"
                      placeholder="Completion Date Range"
                      options={{
                        enableTime: false,
                        onOpen: () => {
                          setDatePickerModal(true);
                        },
                        onClose: () => {
                          setDatePickerModal(false);
                        },
                        altFormat: "F j, Y",
                        dateFormat: "m-d-Y",
                        mode: "range",
                        onChange: (
                          value: any,
                          dateStr: string,
                          instance: any
                        ) => {
                          validation.setFieldValue(
                            "completionDate",
                            dateStr || ""
                          );
                          value?.[0] &&
                            validation.setFieldValue(
                              "activationDateStart",
                              moment(value?.[0]).format("MM-DD-YYYY")
                            );
                          value?.[1] &&
                            validation.setFieldValue(
                              "activationDateEnd",
                              moment(value?.[1]).format("MM-DD-YYYY")
                            );
                        },
                      }}
                      value={validation.values.completionDate || ""}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                    <GroupSelect
                      isDisabled={!groupDetails?.records?.[0]?.group_id}
                      key={groupDetails?.records?.[0]?.group_id}
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.subGroupId &&
                          validation?.errors?.subGroupId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.subGroupId?.length > 0
                          ? validation.values.subGroupId.map((dt: string) => ({
                              sub_group_id: dt,
                            }))
                          : null
                      }
                      loadOptions={asyncSubgroupList(
                        removeEmptyAndNullValues({
                          groupId: groupDetails?.records?.[0]?.group_id || "",
                        }),
                        "subGroupId"
                      )}
                      getOptionValue={(option: any) => option?.sub_group_id}
                      getOptionLabel={(option: any) =>
                        `${option?.sub_group_id} - ${option?.sub_group_name}`
                      }
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.map((dt: any) => dt.sub_group_id)
                          );
                          validation.setFieldValue("brandId", []);
                          validation.setFieldValue("campaignId", []);
                          validation.setFieldValue("phoneNumber", []);
                        } else {
                          validation.setFieldValue("subGroupId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="brandId">Brand</Form.Label>
                    <BrandSelect
                      key={
                        validation.values.subGroupId.toString() ||
                        groupDetails?.records?.[0]?.group_id ||
                        "brandId"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.brandId &&
                          validation?.errors?.brandId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.brandId?.length > 0
                          ? validation.values.brandId
                          : null
                      }
                      loadOptions={asyncBrandList(
                        removeEmptyAndNullValues({
                          groupId: groupDetails?.records?.[0]?.group_id || "",
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                        }),
                        "brandId"
                      )}
                      getOptionValue={(option: any) => option?.brandId}
                      getOptionLabel={(option: any) =>
                        `${option?.brandId} - ${option?.displayName}`
                      }
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                            }))
                          );
                          validation.setFieldValue("campaignId", []);
                          validation.setFieldValue("phoneNumber", []);
                        } else {
                          validation.setFieldValue("brandId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.brandId
                          ?.map((dt: any) => dt.brandId)
                          .toString() ||
                        validation.values.subGroupId.toString() ||
                        groupDetails?.records?.[0]?.group_id ||
                        "campaignId"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.campaignId &&
                          validation?.errors?.campaignId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.campaignId?.length > 0
                          ? validation.values.campaignId
                          : null
                      }
                      loadOptions={asyncCampaignList(
                        removeEmptyAndNullValues({
                          groupId: groupDetails?.records?.[0]?.group_id || "",
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                          brandId:
                            validation.values.brandId.length > 0
                              ? validation.values.brandId?.map(
                                  (dt: any) => dt.brandId
                                )
                              : "",
                        }),
                        "campaignId"
                      )}
                      getOptionValue={(option: any) => option?.campaignId}
                      getOptionLabel={(option: any) => option?.campaignId}
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.reduce(
                              (acc: any[], dt: any) => {
                                const existingItem =
                                  validation.values.brandId.find(
                                    (item: any) =>
                                      item.brandId === dt.brandId &&
                                      JSON.stringify(item.subGroupId) ===
                                        JSON.stringify(dt.subGroupId)
                                  );
                                if (!existingItem) {
                                  acc.push({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.brandId]
                            )
                          );
                          validation.setFieldValue(
                            "campaignId",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                              campaignId: dt?.campaignId || "",
                            }))
                          );
                          validation.setFieldValue("phoneNumber", []);
                        } else {
                          validation.setFieldValue("campaignId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.campaignId
                          ?.map((dt: any) => dt.campaignId)
                          .toString() ||
                        validation.values.brandId
                          ?.map((dt: any) => dt.brandId)
                          .toString() ||
                        validation.values.subGroupId.toString() ||
                        "phoneNumber"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.phoneNumber &&
                          validation?.errors?.phoneNumber
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.phoneNumber
                          ? validation.values.phoneNumber
                          : null
                      }
                      loadOptions={asyncPortingPhoneNumberList(
                        removeEmptyAndNullValues({
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                          brandId:
                            validation.values.brandId.length > 0
                              ? validation.values.brandId?.map(
                                  (dt: any) => dt.brandId
                                )
                              : "",
                          campaignId:
                            validation.values.campaignId.length > 0
                              ? validation.values.campaignId?.map(
                                  (dt: any) => dt.campaignId
                                )
                              : "",
                        }),
                        "phoneNumber"
                      )}
                      getOptionValue={(option: any) => option?.phoneNumber}
                      getOptionLabel={(option: any) => option?.phoneNumber}
                      onChange={(option: any) => {
                        if (
                          option &&
                          option.phoneNumber === validation.values.phoneNumber
                        ) {
                          return;
                        }

                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.reduce(
                              (acc: any[], dt: any) => {
                                const existingItem =
                                  validation.values.brandId.find(
                                    (item: any) =>
                                      item.brandId === dt.brandId &&
                                      JSON.stringify(item.subGroupId) ===
                                        JSON.stringify(dt.subGroupId)
                                  );
                                if (!existingItem) {
                                  acc.push({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.brandId]
                            )
                          );
                          validation.setFieldValue(
                            "campaignId",
                            option.reduce(
                              (acc: any[], dt: any) => {
                                const existingItem =
                                  validation.values.campaignId.find(
                                    (item: any) =>
                                      item.campaignId === dt.campaignId &&
                                      item.brandId === dt.brandId &&
                                      JSON.stringify(item.subGroupId) ===
                                        JSON.stringify(dt.subGroupId)
                                  );
                                if (!existingItem) {
                                  acc.push({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                    campaignId: dt.campaignId || "",
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.campaignId]
                            )
                          );
                          validation.setFieldValue(
                            "phoneNumber",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                              campaignId: dt.campaignId || "",
                              phoneNumber: dt?.phoneNumber || "",
                            }))
                          );
                        } else {
                          validation.setFieldValue("phoneNumber", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    className="btn btn-secondary me-2"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      validation.setValues(initialFilterValues);
                      validation.resetForm();
                      setDatePickerModal(false);
                      setCurrPage(1);
                      handleGetData(1, rowsPerPage);
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && <Spinner size="sm" animation="border" />} Apply
                  </Button>
                </div>
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        }
        isCreate={true}
        createLabel="New Port Request"
        createLink="/port-numbers/new-porting-requests"
      />
      <div className="position-relative">
        <Card className="wrapper2">
          <Card.Body className="listing-table3">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Datatable
                data={
                  Array.isArray(portRequestsData?.records)
                    ? portRequestsData?.records
                    : []
                }
                columns={columns}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleRowClick={handleRowClick}
                currPage={currPage}
                rowsPerPage={rowsPerPage}
                totalRecords={portRequestsData?.totalRecords}
                sortColumn={sortColumn}
                isSortAsc={isSortAsc}
                onSort={handleSort}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default withRouter(PortRequests);
