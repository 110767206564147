import moment from "moment";
import {
  activePhoneNumberApi,
  brandListApi,
  campaignListApi,
  portingPhoneNumberApi,
  portRequestListApi,
  subgroupListApi,
  tagsListApi,
} from "./apiroute_helper";
import _ from "lodash";

export const getInitials = function (string: string) {
  const names = string ? string.split(" ") : ["U"];
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const capitalizeString = (string: string) => {
  const words = string ? string.toLowerCase().split(" ") : [];

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
};

export const humanizeString = (string: string) => {
  if (string) {
    const words = string.toLowerCase().split("_");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
  }
  return "";
};

export const removeEmptyAndNullValues = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
};

export const getChangedValues = (initialValues: any, changedValues: any) => {
  const changedFields: any = {};

  for (const key in changedValues) {
    if (
      initialValues.hasOwnProperty(key) &&
      initialValues[key] !== changedValues[key]
    ) {
      changedFields[key] = changedValues[key];
    }
  }

  return changedFields;
};

export const removeKeyFromObj = (obj: any, keysToRemove: string[]): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeyFromObj(item, keysToRemove));
  }

  return Object.keys(obj).reduce((acc: any, key: string) => {
    if (!keysToRemove.includes(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        acc[key] = removeKeyFromObj(obj[key], keysToRemove);
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});
};

export const flattenObj = (ob: any) => {
  let result: any = {};

  for (const i in ob) {
    if (typeof ob[i] === "object" && ob[i] !== null && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + "." + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }

  return result;
};

export const cleanAndCapitalizeWithSpaces = (str: any) => {
  // ex. str => event.fld-val-list.reason_description
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str.replace(/[-._](\w)/g, (_: any, c: any) => " " + c.toUpperCase());
};

export const cleanAndCapitalize = (str: any) => {
  return str.replace(/[-._](\w)/g, (_: any, c: any) => c.toUpperCase());
};

export const camelToFlat = (camel: any) => {
  let flat = "";
  const camelCase = camel.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");
  camelCase.forEach((word: any) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });
  return flat;
};

export const dateFormat = (date: number | string, format?: string) => {
  return date ? moment(date).format(format || "MM/DD/YYYY") : "-";
};

export const getCurrentDateTime = (format?: string) => {
  return moment().format(format || "MM-DD-YYYY, HH:mm:ss");
};

export const customSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: state.isDisabled
      ? "var(--tb-tertiary-bg)"
      : "var(--tb-secondary-bg)",
    color: state.isDisabled
      ? "var(--tb-tertiary-color)"
      : "var(--tb-body-color)",
    boxShadow: "none",
    border: state.isFocused
      ? "1px solid var(--tb-primary-border-subtle)"
      : "var(--tb-border-width) solid var(--tb-border-color-translucent)",
    outline: "none",
    "&:hover": {
      border: "1px solid var(--tb-primary-border-subtle)",
    },
  }),
  input: (base: any) => ({
    ...base,
    color: "var(--tb-body-color)",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "var(--tb-body-color)",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    backgroundColor: "var(--tb-border-color-translucent)",
  }),
  menu: (base: any) => ({
    ...base,
    background: "var(--tb-secondary-bg)",
    zIndex: 6,
  }),
  option: (base: any) => {
    return {
      ...base,
    };
  },
  multiValue: (styles: any) => {
    return {
      ...styles,
      fontWeight: 600,
      backgroundColor: "var(--tb-primary)",
    };
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--tb-primary)",
    color: "white",
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: "white",
    backgroundColor: "var(--tb-primary)",
    ":hover": {
      backgroundColor: "var(--tb-primary)",
      color: "white",
    },
  }),
};

export const styleObj = (error: any) => ({
  ...customSelectStyles,
  control: (base: any, state: any) => ({
    ...customSelectStyles.control(base, state),
    borderColor: error ? "var(--tb-primary-border-subtle) !important" : "",
    "&:hover": {
      borderColor: error ? "var(--tb-primary-border-subtle)" : "",
    },
  }),
});

export const customSelectTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#e41cfd1f",
    primary: "#e41cfd",
  },
});

export const last12Months = () => {
  const last12Months = [];

  for (let i = 0; i < 12; i++) {
    const month = moment().subtract(i, "months").month();
    const year = moment().subtract(i, "months").year();
    last12Months.push({
      label: moment().month(month).year(year).format("MMM YYYY"),
      value: moment().month(month).year(year).format("MM-YYYY"),
    });
  }

  return last12Months;
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getRangeBasedOnLabel = (
  value: string,
  startDate: string,
  endDate: string
) => {
  // let startDate = "";
  // let endDate = "";

  if (value === "24 hours") {
    startDate = moment().subtract(24, "hours").format("MM-DD-YYYY HH:mm:ss");
    endDate = moment().format("MM-DD-YYYY HH:mm:ss");
    console.log("24 hours", value, startDate, endDate);
  } else if (value === "7 days") {
    startDate = moment().subtract(6, "days").format("MM-DD-YYYY");
    endDate = moment().format("MM-DD-YYYY HH:mm:ss");
    console.log("7 days", value, startDate, endDate);
  } else if (value === "30 days") {
    startDate = moment()
      .subtract(29, "days")
      .startOf("day")
      .format("MM-DD-YYYY");
    endDate = moment().format("MM-DD-YYYY HH:mm:ss");
    console.log("30 days", value, startDate, endDate);
  } else if (value === "120 days") {
    startDate = moment()
      .subtract(119, "days")
      .startOf("day")
      .format("MM-DD-YYYY");
    endDate = moment().format("MM-DD-YYYY HH:mm:ss");
    console.log("120 days", value, startDate, endDate);
  } else if (value === "Custom") {
    startDate = startDate;
    endDate = endDate;
    console.log("Custom", value, startDate, endDate);
  } else {
    endDate = moment().format("MM-DD-YYYY HH:mm:ss");
    startDate = moment()
      .subtract(6, "days")
      .startOf("day")
      .format("MM-DD-YYYY");
    console.log("Default", value, startDate, endDate);
  }

  return { startDate, endDate };
};

export const asyncSubgroupList =
  (dataObj: any, serchKey?: string, subGroupId?: string) =>
  async (searchQuery: string, loadedOptions: any, { page }: any) => {
    try {
      let data: any = await subgroupListApi(
        page,
        10,
        removeEmptyAndNullValues({
          ...dataObj,
          ...(serchKey && { [serchKey]: searchQuery.toUpperCase() || "" }),
          ...(subGroupId && { subGroupId }),
        })
      );

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    } catch (error: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

export const asyncBrandList =
  (dataObj: any, serchKey?: string) =>
  async (searchQuery: string, loadedOptions: any, { page }: any) => {
    try {
      let data: any = await brandListApi(
        page,
        10,
        removeEmptyAndNullValues({
          ...dataObj,
          ...(serchKey && { [serchKey]: searchQuery.toUpperCase() || "" }),
        })
      );

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    } catch (error: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

export const asyncCampaignList =
  (dataObj: any, serchKey?: string, includePending?: boolean) =>
  async (searchQuery: string, loadedOptions: any, { page }: any) => {
    try {
      let data: any = await campaignListApi(
        page,
        10,
        removeEmptyAndNullValues({
          ...dataObj,
          ...(!includePending && { excludePendingCampaigns: true }),
          ...(serchKey && { [serchKey]: searchQuery.toUpperCase() || "" }),
        })
      );

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    } catch (error: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

export const asyncActivePhoneNumberList =
  (dataObj: any, serchKey?: string) =>
  async (searchQuery: string, loadedOptions: any, { page }: any) => {
    try {
      let data: any = await activePhoneNumberApi(
        page,
        10,
        removeEmptyAndNullValues({
          ...dataObj,
          ...(serchKey && { [serchKey]: searchQuery || "" }),
        })
      );

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    } catch (error: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

export const asyncTagsList =
  (dataObj: any, serchKey?: string) =>
  async (searchQuery: string, loadedOptions: any, { page }: any) => {
    try {
      let data: any = await tagsListApi(
        page,
        10,
        removeEmptyAndNullValues({
          ...dataObj,
          ...(serchKey && { [serchKey]: searchQuery || "" }),
        })
      );

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    } catch (error: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

export const asyncPortRequestList =
  (dataObj: any, serchKey?: string) =>
  async (searchQuery: string, loadedOptions: any, { page }: any) => {
    try {
      let data: any = await portRequestListApi(
        page,
        10,
        removeEmptyAndNullValues({
          ...dataObj,
          ...(serchKey && { [serchKey]: searchQuery || "" }),
        })
      );

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    } catch (error: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

export const asyncPortingPhoneNumberList =
  (dataObj: any, serchKey?: string) =>
  async (searchQuery: string, loadedOptions: any, { page }: any) => {
    try {
      let data: any = await portingPhoneNumberApi(
        page,
        10,
        removeEmptyAndNullValues({
          ...dataObj,
          ...(serchKey && { [serchKey]: searchQuery || "" }),
        })
      );

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    } catch (error: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

export const helpCenterObj: any = {
  "/de549a7b09e84d42a5a8496fd271e294":
    "/help-center/de549a7b-09e8-4d42-a5a8-496fd271e294/de549a7b-09e8-4d42-a5a8-496fd271e294?name=Dashboard",
  "/e837fb056b86400fb4d5361bcd2db2fa":
    "/help-center/2f062a71-8633-45a1-a55a-3419b2d64d35/e837fb05-6b86-400f-b4d5-361bcd2db2fa?name=General-Settings",
  "/b5645cfb60b5414e964d0ab1e1c0e910":
    "/help-center/2f062a71-8633-45a1-a55a-3419b2d64d35/b5645cfb-60b5-414e-964d-0ab1e1c0e910?name=Security-Settings",
  "/697c1dc88c50443ea5bb65a37d9b7dc8":
    "/help-center/2f062a71-8633-45a1-a55a-3419b2d64d35/697c1dc8-8c50-443e-a5bb-65a37d9b7dc8?name=Billing-Settings",
  "/2c3cb6f9fa764e1da9a118be384bb595":
    "/help-center/2f062a71-8633-45a1-a55a-3419b2d64d35/2c3cb6f9-fa76-4e1d-a9a1-18be384bb595?name=Account-Pricing",
  "/3f0da356d61f479b9a6df03952ffa4e0":
    "/help-center/2f062a71-8633-45a1-a55a-3419b2d64d35/3f0da356-d61f-479b-9a6d-f03952ffa4e0?name=Usage-Transactions",
  "/4af5f763b82345c3a2a76ca45d541fe4":
    "/help-center/bf3dccba-cef2-47eb-894b-ad284a7be29b/4af5f763-b823-45c3-a2a7-6ca45d541fe4?name=Webhooks-",
  "/4e0f7869598746fca14be115e8b58947":
    "/help-center/bf3dccba-cef2-47eb-894b-ad284a7be29b/4e0f7869-5987-46fc-a14b-e115e8b58947?name=Groups-and-Sub-Groups:-Hierarchy-",
  "/5af262bbf2334c6083fbcccf6dac7fb6":
    "/help-center/bf3dccba-cef2-47eb-894b-ad284a7be29b/5af262bb-f233-4c60-83fb-cccf6dac7fb6?name=API-Keys-and-Documentation",
  "/221c9f601a634b63a2b7b85f307672bd":
    "/help-center/2cf274e3-2ee4-4da2-81f1-df0edabb7ce1/221c9f60-1a63-4b63-a2b7-b85f307672bd?name=Your-Numbers",
  "/f8f6eea7fb84465e8dd84ba3920d7538":
    "/help-center/2cf274e3-2ee4-4da2-81f1-df0edabb7ce1/f8f6eea7-fb84-465e-8dd8-4ba3920d7538?name=Buy-a-Number",
  "/4fa68ef7fae64976b26b4a15545dc9ff":
    "/help-center/2cf274e3-2ee4-4da2-81f1-df0edabb7ce1/4fa68ef7-fae6-4976-b26b-4a15545dc9ff?name=Configure-your-Number",
  "/016fd66b38c849b3b5540cd758f1a482":
    "/help-center/2cf274e3-2ee4-4da2-81f1-df0edabb7ce1/016fd66b-38c8-49b3-b554-0cd758f1a482?name=Release-a-Number",
  "/4ffdf05808ea40678ba04144da4a779e":
    "/help-center/2cf274e3-2ee4-4da2-81f1-df0edabb7ce1/4ffdf058-08ea-4067-8ba0-4144da4a779e?name=Getting-and-Setting-up-your-First-Number",
  "/18dcd7486fe64ca4805b4697c034adac":
    "/help-center/d0891fca-3683-4e72-911c-bc64f5dc6c87/18dcd748-6fe6-4ca4-805b-4697c034adac?name=Port-a-Number-(Full)",
  "/4d9e73bf6ba545629faaa3e0b99a187e":
    "/help-center/d0891fca-3683-4e72-911c-bc64f5dc6c87/4d9e73bf-6ba5-4562-9faa-a3e0b99a187e?name=Port-a-Number-(Soft)",
  "/3a0d914c54054e22b04de579d66adab0":
    "/help-center/d0891fca-3683-4e72-911c-bc64f5dc6c87/3a0d914c-5405-4e22-b04d-e579d66adab0?name=Port-a-Number-(Hard)",
  "/01f103a6fd1d4ce697b09edd53dbcb19":
    "/help-center/d0891fca-3683-4e72-911c-bc64f5dc6c87/01f103a6-fd1d-4ce6-97b0-9edd53dbcb19?name=Check-Porting-Status:",
  "/ad5beb15d1d241e38e988675d40e335c":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/ad5beb15-d1d2-41e3-8e98-8675d40e335c?name=10DLC-Messaging-Overview",
  "/0e726aab3888487e82effa885cad2bc4":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/0e726aab-3888-487e-82ef-fa885cad2bc4?name=Carrier-Passthrough-Fees",
  "/54bfa5eb69014681b29da1c7ba99da6c":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/54bfa5eb-6901-4681-b29d-a1c7ba99da6c?name=Campaign-Types-and-Throughput",
  "/5e554e3abb594fefbc5de5ad0ab93eae":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/5e554e3a-bb59-4fef-bc5d-e5ad0ab93eae?name=Brand-Verification",
  "/e1a3643c3dd248b198d36663465c2d07":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/e1a3643c-3dd2-48b1-98d3-6663465c2d07?name=Add-a-New-Campaign",
  "/345d8749be0f4cf3b47eb708b2a20d33":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/345d8749-be0f-4cf3-b47e-b708b2a20d33?name=10DLC-Campaign-Website-Review",
  "/61c6e2a27de442c8a4a20f951a7b77f9":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/61c6e2a2-7de4-42c8-a4a2-0f951a7b77f9?name=10DLC-Campaign-Approvals",
  "/06c10eee705f4935b774e9438d4bee52":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/06c10eee-705f-4935-b774-e9438d4bee52?name=Brand-Throughput-and-Additional-Vetting",
  "/ef60b0f4f752474f8e29d19e1951dd91":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/ef60b0f4-f752-474f-8e29-d19e1951dd91?name=Segments",
  "/10e8796dd5ae80a79fa8df14fd9d5086":
    "/help-center/2226f669-8816-4a28-9686-6e89c2c46fca/10e8796d-d5ae-80a7-9fa8-df14fd9d5086?name=Logs",
  "/b702aea329374f31822a85458b551860":
    "/help-center/fff8796d-d5ae-803f-8fdc-d5c3d1ccd789/b702aea3-2937-4f31-822a-85458b551860?name=Platform-Analytics",
};

export const roleBasedPermission: any = {
  // Admin User
  company_admin: {
    dashboard: { create: true, read: true, update: true, delete: true },
    settings: {
      create: true,
      read: true,
      update: true,
      delete: true,
      general: { create: true, read: true, update: true, delete: true },
      security: { create: true, read: true, update: true, delete: true },
      users: { create: true, read: true, update: true, delete: true },
      notifications: { create: true, read: true, update: true, delete: true },
      billing: { create: true, read: true, update: true, delete: true },
      "account-pricing": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "usage-transactions": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
    "api-keys": { create: true, read: true, update: true, delete: true },
    "api-documentation": {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    webhooks: { create: true, read: true, update: true, delete: true },
    "short-url": { create: true, read: true, update: true, delete: true },
    groups: { create: true, read: true, update: true, delete: true },
    "own-numbers": { create: true, read: true, update: true, delete: true },
    "buy-a-number": { create: true, read: true, update: true, delete: true },
    "release-number": { create: true, read: true, update: true, delete: true },
    "port-a-number": { create: true, read: true, update: true, delete: true },
    analytics: { create: true, read: true, update: true, delete: true },
    "message-logs": { create: true, read: true, update: true, delete: true },
    brands: { create: true, read: true, update: true, delete: true },
    "campaigns-child": { create: true, read: true, update: true, delete: true },
    "rbm-agent": { create: true, read: true, update: true, delete: true },
    "send-sms": { create: true, read: true, update: true, delete: true },
    "send-mms": { create: true, read: true, update: true, delete: true },
    "help-center": { create: true, read: true, update: true, delete: true },
    marketplace: { create: true, read: true, update: true, delete: true },
  },
  // Billing User
  company_billing: {
    dashboard: { create: false, read: true, update: false, delete: false },
    settings: {
      create: true,
      read: true,
      update: true,
      delete: true,
      general: { create: true, read: true, update: true, delete: true },
      security: { create: true, read: true, update: true, delete: true },
      users: { create: true, read: true, update: true, delete: false },
      notifications: { create: true, read: true, update: true, delete: true },
      billing: { create: true, read: true, update: true, delete: false },
      "account-pricing": {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "usage-transactions": {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
    "api-keys": { create: false, read: false, update: false, delete: false },
    "api-documentation": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    webhooks: { create: false, read: false, update: false, delete: false },
    "short-url": { create: false, read: false, update: false, delete: false },
    groups: { create: true, read: true, update: true, delete: true },
    "own-numbers": { create: false, read: true, update: false, delete: false },
    "buy-a-number": {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    "release-number": {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    "port-a-number": {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    analytics: { create: true, read: true, update: true, delete: true },
    "message-logs": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    brands: { create: false, read: true, update: false, delete: false },
    "campaigns-child": {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    "rbm-agent": { create: true, read: true, update: true, delete: true },
    "send-sms": { create: false, read: false, update: false, delete: false },
    "send-mms": { create: false, read: false, update: false, delete: false },
    "help-center": { create: true, read: true, update: true, delete: true },
    marketplace: { create: true, read: true, update: true, delete: true },
  },
  // Developer User
  company_developer: {
    dashboard: { create: true, read: true, update: true, delete: true },
    settings: {
      create: true,
      read: true,
      update: true,
      delete: true,
      general: { create: true, read: true, update: true, delete: true },
      security: { create: true, read: true, update: true, delete: true },
      users: { create: true, read: true, update: true, delete: true },
      notifications: { create: true, read: true, update: true, delete: true },
      billing: { create: false, read: false, update: false, delete: false },
      "account-pricing": {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      "usage-transactions": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
    "api-keys": { create: true, read: true, update: true, delete: true },
    "api-documentation": {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    webhooks: { create: true, read: true, update: true, delete: true },
    "short-url": { create: true, read: true, update: true, delete: true },
    groups: { create: true, read: true, update: true, delete: true },
    "own-numbers": { create: true, read: true, update: true, delete: true },
    "buy-a-number": { create: true, read: true, update: true, delete: true },
    "release-number": { create: true, read: true, update: true, delete: true },
    "port-a-number": { create: true, read: true, update: true, delete: true },
    analytics: { create: true, read: true, update: true, delete: true },
    "message-logs": { create: true, read: true, update: true, delete: true },
    brands: { create: true, read: true, update: true, delete: true },
    "campaigns-child": { create: true, read: true, update: true, delete: true },
    "rbm-agent": { create: true, read: true, update: true, delete: true },
    "send-sms": { create: true, read: true, update: true, delete: true },
    "send-mms": { create: true, read: true, update: true, delete: true },
    "help-center": { create: true, read: true, update: true, delete: true },
    marketplace: { create: true, read: true, update: true, delete: true },
  },
  // Standard User
  company_standard: {
    dashboard: { create: true, read: true, update: true, delete: true },
    settings: {
      create: true,
      read: true,
      update: true,
      delete: true,
      general: { create: true, read: true, update: true, delete: true },
      security: { create: true, read: true, update: true, delete: true },
      users: { create: false, read: false, update: false, delete: false },
      notifications: { create: true, read: true, update: true, delete: true },
      billing: { create: false, read: false, update: false, delete: false },
      "account-pricing": {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      "usage-transactions": {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    },
    "api-keys": { create: false, read: false, update: false, delete: false },
    "api-documentation": {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    webhooks: { create: false, read: false, update: false, delete: false },
    "short-url": { create: false, read: false, update: false, delete: false },
    groups: { create: true, read: true, update: true, delete: true },
    "own-numbers": { create: true, read: true, update: true, delete: true },
    "buy-a-number": { create: true, read: true, update: true, delete: true },
    "release-number": { create: true, read: true, update: true, delete: true },
    "port-a-number": { create: true, read: true, update: true, delete: true },
    analytics: { create: true, read: true, update: true, delete: true },
    "message-logs": { create: false, read: true, update: false, delete: false },
    brands: { create: true, read: true, update: true, delete: false },
    "campaigns-child": {
      create: true,
      read: true,
      update: true,
      delete: false,
    },
    "rbm-agent": { create: true, read: true, update: true, delete: true },
    "send-sms": { create: true, read: true, update: false, delete: false },
    "send-mms": { create: true, read: true, update: false, delete: false },
    "help-center": { create: true, read: true, update: true, delete: true },
    marketplace: { create: true, read: true, update: true, delete: true },
  },
};

export const roleArr = [
  {
    label: "Admin User",
    value: "company_admin",
    desc: "Full permissions, can edit all settings, modify billing information, purchase numbers, create and submit brands and campaigns, has access to developer data, can add additional seats of any role",
  },
  {
    label: "Developer User",
    value: "company_developer",
    desc: "Can do all that an admin can do except edit billing information, can add additional developer and user seats",
  },
  {
    label: "Billing User",
    value: "company_billing",
    desc: "Can modify billing information, review usage (cost and amounts), and has access to analytics tab, can only add additional billing and user seats",
  },
  {
    label: "Standard User",
    value: "company_standard",
    desc: "Can filter for analytics/has full analytics permissions, can view/add numbers, brands, message logs, and campaigns; and can view usage information. Cannot add seats",
  },
];

export const calculateMessageSegments = (
  message: any,
  useSmartEncoding = false
) => {
  const GSM7_CHAR_SET =
    "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#$%&'()*+,-./0123456789:;" +
    "<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ`¿abcdefghijklmnopqrstuvwxyzäöñüà";
  const GSM7_EXTENDED_CHAR_SET = "^{}\\[~]|€";

  let encoding = "GSM-7";
  let length = 0;
  let unicode = false;

  for (const char of message) {
    if (GSM7_CHAR_SET.includes(char)) {
      length += 1;
    } else if (GSM7_EXTENDED_CHAR_SET.includes(char)) {
      if (useSmartEncoding) {
        length += 2;
      } else {
        unicode = true;
        break;
      }
    } else {
      unicode = true;
      break;
    }
  }

  if (unicode) {
    encoding = "UCS-2";
    length = message.length;
  }

  const SEGMENT_LIMIT = encoding === "UCS-2" ? 70 : 160;
  const CONCAT_SEGMENT_LIMIT = encoding === "UCS-2" ? 67 : 153;

  let segments = 1;
  if (length > SEGMENT_LIMIT) {
    segments = Math.ceil(length / CONCAT_SEGMENT_LIMIT);
  }

  return {
    encoding,
    segments,
    totalCharacters: length,
    maxCharactersPerSegment: encoding === "UCS-2" ? 70 : 160,
  };
};

export const rolesConst = {
  company_admin: "company_admin",
  company_billing: "company_billing",
  company_developer: "company_developer",
  company_standard: "company_standard",
};

export const dateRangeArr = [
  {
    label: "24 hours",
    value: "24 hours",
  },
  {
    label: "7 days",
    value: "7 days",
  },
  {
    label: "30 days",
    value: "30 days",
  },
  {
    label: "120 days",
    value: "120 days",
  },
  {
    label: "Custom (full custom range)",
    value: "Custom",
  },
];

export const getAnalyticCardData = ({
  endDate,
  startDate,
  analyticsAllV1,
  setCardObj,
}: any) => {
  let date = moment(endDate, "MM-DD-YYYY")?.diff(
    moment(startDate, "YYYY-MM-DD"),
    "days"
  );
  let dateDiff = endDate && startDate ? (isNaN(date) ? 1 : date) : 1;

  const smsTotal = Object.keys(analyticsAllV1.SMS).reduce(
    (acc: any, date: any) => {
      const dateData = analyticsAllV1.SMS[date];
      Object.keys(dateData).forEach((phoneNumber) => {
        const phoneData = dateData[phoneNumber];
        Object.keys(phoneData).forEach((key) => {
          acc[key] = (acc[key] || 0) + phoneData[key];
        });
      });
      return acc;
    },
    {}
  );

  const mmsTotal = Object.keys(analyticsAllV1.MMS).reduce(
    (acc: any, date: any) => {
      const dateData = analyticsAllV1.MMS[date];
      Object.keys(dateData).forEach((phoneNumber) => {
        const phoneData = dateData[phoneNumber];
        Object.keys(phoneData).forEach((key) => {
          acc[key] = (acc[key] || 0) + phoneData[key];
        });
      });
      return acc;
    },
    {}
  );

  const calculateResponseRate = () => {
    const groupDataByPhoneNumber = (
      data: Record<string, Record<string, Record<string, number>>>
    ) => {
      return Object.keys(data).reduce(
        (acc: Record<string, Record<string, number>>, date) => {
          const dateData = data[date];
          Object.keys(dateData).forEach((phoneNumber) => {
            if (!acc[phoneNumber]) {
              acc[phoneNumber] = {};
            }
            Object.keys(dateData[phoneNumber]).forEach((key) => {
              acc[phoneNumber][key] =
                (acc[phoneNumber][key] || 0) + dateData[phoneNumber][key];
            });
          });
          return acc;
        },
        {}
      );
    };

    const smsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.SMS);
    const mmsDataByPhoneNumber = groupDataByPhoneNumber(analyticsAllV1.MMS);
    const allDataByPhoneNumber: any = Object.keys({
      ...smsDataByPhoneNumber,
      ...mmsDataByPhoneNumber,
    }).reduce((acc: any, phoneNumber: any) => {
      acc[phoneNumber] = {};
      const smsData = smsDataByPhoneNumber[phoneNumber] || {};
      const mmsData = mmsDataByPhoneNumber[phoneNumber] || {};
      const allKeys = new Set([
        ...Object.keys(smsData),
        ...Object.keys(mmsData),
      ]);
      allKeys.forEach((key) => {
        acc[phoneNumber][key] = (smsData[key] || 0) + (mmsData[key] || 0);
      });
      return acc;
    }, {});

    const calculateRateForType = (
      data: Record<string, Record<string, number>>,
      total: any
    ) => {
      let respondedNumbers = 0;
      Object.keys(data).forEach((phoneNumber) => {
        const phoneData = data[phoneNumber];

        // Check if the number has any inbound messages
        if (phoneData.totalInbound > 0 && phoneData.delivered > 0) {
          // Check if all inbound messages are opt-outs
          // if (phoneData.totalInbound > phoneData.totalOptOut) {
          respondedNumbers++;
          // }
        }
      });

      return total > 0 ? (respondedNumbers / total) * 100 : 0;
    };

    const smsResponseRate = calculateRateForType(
      smsDataByPhoneNumber,
      smsTotal?.delivered
    );

    const mmsResponseRate = calculateRateForType(
      mmsDataByPhoneNumber,
      mmsTotal?.delivered
    );

    // Calculate total response rate

    const totalResponseRate = calculateRateForType(
      allDataByPhoneNumber,
      smsTotal?.delivered + mmsTotal?.delivered
    );

    // Update the cardObj with new response rates
    return {
      smsrr: smsResponseRate,
      mmsrr: mmsResponseRate,
      allrr: totalResponseRate,
    };
  };

  const responseRateData = calculateResponseRate();

  const totalSmsSent =
    (smsTotal?.delivered || 0) +
    (smsTotal?.sent || 0) +
    (smsTotal?.failed || 0);

  smsTotal["deliveryRate"] = (100 * smsTotal?.delivered) / (totalSmsSent || 1);
  smsTotal["failedRate"] = (100 * smsTotal?.failed) / (totalSmsSent || 1);
  smsTotal["sentRate"] = (100 * smsTotal?.sent) / (totalSmsSent || 1);
  smsTotal["totalMessagesSent"] = totalSmsSent;
  smsTotal["averageMessagesSent"] = totalSmsSent / dateDiff;

  smsTotal["averageMessagesReceived"] = smsTotal?.totalInbound / dateDiff;
  smsTotal["averageSegmentsSent"] = smsTotal?.segmentSent / dateDiff;
  smsTotal["optOutRate"] =
    (100 * smsTotal?.totalOptOut) / (smsTotal?.totalInbound || 1);
  smsTotal["averageSegmentReceived"] = smsTotal?.segmentRecieved / dateDiff;
  smsTotal["responseRate"] = responseRateData?.smsrr;
  smsTotal["clickthroughRate"] =
    (smsTotal?.totalShortLinksClick || 0) / (smsTotal?.totalShortLinks || 0);

  const totalMmsSent =
    (mmsTotal?.delivered || 0) +
    (mmsTotal?.sent || 0) +
    (mmsTotal?.failed || 0);

  mmsTotal["deliveryRate"] = (100 * mmsTotal?.delivered) / (totalMmsSent || 1);
  mmsTotal["failedRate"] = (100 * mmsTotal?.failed) / (totalMmsSent || 1);
  mmsTotal["sentRate"] = (100 * mmsTotal?.sent) / (totalMmsSent || 1);
  mmsTotal["totalMessagesSent"] = totalMmsSent;
  mmsTotal["averageMessagesSent"] = totalMmsSent / dateDiff;
  mmsTotal["averageMessagesReceived"] = mmsTotal?.totalInbound / dateDiff;
  mmsTotal["averageSegmentsSent"] = mmsTotal?.segmentSent / dateDiff;
  mmsTotal["optOutRate"] =
    (100 * mmsTotal?.totalOptOut) / (mmsTotal?.totalInbound || 1);
  mmsTotal["averageSegmentReceived"] = mmsTotal?.segmentRecieved / dateDiff;
  mmsTotal["responseRate"] = responseRateData?.mmsrr;
  mmsTotal["clickthroughRate"] =
    ((mmsTotal?.totalShortLinksClick || 0) / (mmsTotal?.totalShortLinks || 0)) *
    100;

  const allTotal = Object.keys(smsTotal).reduce((acc: any, key: any) => {
    acc[key] = (smsTotal[key] || 0) + (mmsTotal[key] || 0);
    return acc;
  }, {});

  const totalAllSent =
    (allTotal?.delivered || 0) +
    (allTotal?.sent || 0) +
    (allTotal?.failed || 0);

  allTotal["deliveryRate"] = (100 * allTotal?.delivered) / (totalAllSent || 1);
  allTotal["failedRate"] = (100 * allTotal?.failed) / (totalAllSent || 1);
  allTotal["sentRate"] = (100 * allTotal?.sent) / (totalAllSent || 1);
  allTotal["totalMessagesSent"] = totalAllSent;
  allTotal["averageMessagesSent"] = totalAllSent / dateDiff;
  allTotal["averageMessagesReceived"] = allTotal?.totalInbound / dateDiff;
  allTotal["averageSegmentsSent"] = allTotal?.segmentSent / dateDiff;
  allTotal["optOutRate"] =
    (100 * allTotal?.totalOptOut) / (allTotal?.totalInbound || 1);
  allTotal["averageSegmentReceived"] = allTotal?.segmentRecieved / dateDiff;
  allTotal["responseRate"] = responseRateData?.allrr;
  allTotal["clickthroughRate"] =
    ((allTotal?.totalShortLinksClick || 0) / (allTotal?.totalShortLinks || 0)) *
    100;

  // smsTotal["totalShortLinks"] = smsTotal?.totalShortLinks;

  // smsTotal["totalShortLinksClick"] = smsTotal?.totalShortLinksClick;
  const tempObj = {
    SMS: { ...smsTotal },
    MMS: { ...mmsTotal },
    All: { ...allTotal },
  };

  setCardObj({ ...tempObj });
};

export const getAnalyticDateGraphData = ({
  selectedType,
  analyticsAllV1,
  setDateGraphObj,
}: any) => {
  const mergeData = (data1: any, data2?: any) => {
    const mergedData = _.mergeWith({}, data1, data2, (objValue, srcValue) => {
      if (_.isObject(objValue) && _.isObject(srcValue)) {
        return _.mergeWith(
          {},
          objValue,
          srcValue,
          (innerObjValue, innerSrcValue) => {
            if (_.isNumber(innerObjValue) && _.isNumber(innerSrcValue)) {
              return innerObjValue + innerSrcValue;
            }
          }
        );
      }
    });

    // Merge data phone number wise

    // Calculate response rate based on per-number non-opt out responses
    // let totalNumbers = 0;
    // let respondedNumbers = 0;

    // Object.keys(mergedPhoneData).forEach((phoneNumber) => {
    //   const phoneStats = mergedPhoneData[phoneNumber];
    //   totalNumbers++;

    //   if (phoneStats.totalInbound > 0 && phoneStats.totalOptOut === 0) {
    //     respondedNumbers++;
    //   }
    // });

    // const responseRate =
    //   totalNumbers > 0
    //     ? Math.min((respondedNumbers / totalNumbers) * 100, 100)
    //     : 0;

    // Add the calculated response rate to the merged data
    // Object.keys(mergedData).forEach((date) => {
    //   mergedData[date].responseRate = responseRate;
    // });

    // Merge phone number data for each date and calculate new fields
    Object.keys(mergedData).forEach((date) => {
      const phoneData = mergedData[date];
      mergedData[date] = Object.values(phoneData).reduce(
        (acc: any, curr: any) => {
          Object.keys(curr).forEach((key) => {
            acc[key] = (acc[key] || 0) + curr[key];
          });
          return acc;
        },
        {}
      );

      // Calculate response rate for this date
      let totalNumbers = 0;
      let respondedNumbers = 0;

      Object.keys(phoneData).forEach((phoneNumber) => {
        const phoneStats = phoneData[phoneNumber];
        totalNumbers++;

        if (
          phoneStats.totalInbound > 0 &&
          phoneStats.delivered > 0 &&
          phoneStats.totalInbound > phoneStats.totalOptOut
        ) {
          respondedNumbers++;
        }
      });

      // Calculate new fields
      const totalSent =
        mergedData[date].delivered +
        mergedData[date].sent +
        mergedData[date].failed;

      mergedData[date].deliveryRate = totalSent
        ? Number((mergedData[date].delivered / totalSent) * 100)
        : 0;
      mergedData[date].failedRate = totalSent
        ? Number((mergedData[date].failed / totalSent) * 100)
        : 0;
      mergedData[date].sentRate = totalSent
        ? Number((mergedData[date].sent / totalSent) * 100)
        : 0;

      mergedData[date].clickthroughRate =
        ((mergedData[date].totalShortLinksClick || 0) /
          (mergedData[date].totalShortLinks || 0)) *
        100;
      mergedData[date].totalMessagesSent = totalSent;
      mergedData[date].optOutRate = mergedData[date].totalInbound
        ? Number(
            (mergedData[date].totalOptOut / mergedData[date].totalInbound) * 100
          )
        : 0;
      mergedData[date].responseRate =
        mergedData[date].delivered > 0
          ? Math.min((respondedNumbers / mergedData[date].delivered) * 100, 100)
          : 0;

      // mergedData[date].responseRate = mergedData[date].responseRate;
    });

    return mergedData;
  };

  const result =
    selectedType === "All"
      ? mergeData(analyticsAllV1?.SMS, analyticsAllV1?.MMS)
      : selectedType === "SMS"
      ? mergeData(analyticsAllV1?.SMS)
      : selectedType === "MMS"
      ? mergeData(analyticsAllV1?.MMS)
      : {};

  setDateGraphObj(result);
};

export const getAnalyticNumberGraphData = ({
  selectedType,
  analyticsAllV1,
  setNumberGraphObj,
}: any) => {
  const mergeDataByPhoneNumber = (data1: any, data2?: any) => {
    const mergedData: any = {};

    // Helper function to merge data for a single phone number
    const mergePhoneData = (phoneData: any, date: any, phoneNumber: any) => {
      if (!mergedData[phoneNumber]) {
        mergedData[phoneNumber] = {};
      }
      // Ensure metrics is defined before using it
      const metrics = [
        "delivered",
        "sent",
        "dequeued",
        "enqueued",
        "created",
        "failed",
        "segmentSent",
        "totalInbound",
        "segmentRecieved",
        "totalOptOut",
        "averageResponseTime",
        "totalShortLinks",
        "totalShortLinksClick",
        "clickthroughRate",
      ];

      metrics.forEach((metric) => {
        if (!mergedData[phoneNumber][metric]) {
          mergedData[phoneNumber][metric] = 0;
        }
        mergedData[phoneNumber][metric] += phoneData[metric] || 0;
      });
    };

    // Merge data from both input objects
    [data1, data2].forEach((data) => {
      data &&
        Object.keys(data).forEach((date) => {
          data[date] &&
            Object.keys(data[date]).forEach((phoneNumber) => {
              mergePhoneData(data[date][phoneNumber], date, phoneNumber);
            });
        });
    });

    // Calculate new fields for each phone number
    Object.keys(mergedData).forEach((phoneNumber) => {
      const totalSent =
        mergedData[phoneNumber].delivered +
        mergedData[phoneNumber].sent +
        mergedData[phoneNumber].failed;

      mergedData[phoneNumber].deliveryRate = totalSent
        ? Number((mergedData[phoneNumber].delivered / totalSent) * 100)
        : 0;
      mergedData[phoneNumber].failedRate = totalSent
        ? Number((mergedData[phoneNumber].failed / totalSent) * 100)
        : 0;
      mergedData[phoneNumber].sentRate = totalSent
        ? Number((mergedData[phoneNumber].sent / totalSent) * 100)
        : 0;
      mergedData[phoneNumber].totalMessagesSent = totalSent;
      mergedData[phoneNumber].optOutRate = mergedData[phoneNumber].totalInbound
        ? Number(
            (mergedData[phoneNumber].totalOptOut /
              mergedData[phoneNumber].totalInbound) *
              100
          )
        : 0;
      mergedData[phoneNumber].clickthroughRate =
        ((mergedData[phoneNumber].totalShortLinksClick || 0) /
          (mergedData[phoneNumber].totalShortLinks || 0)) *
        100;

      mergedData[phoneNumber].responseRate = mergedData[phoneNumber].delivered
        ? Math.min(
            100,
            Number(
              ((mergedData[phoneNumber].totalInbound -
                mergedData[phoneNumber].totalOptOut) /
                mergedData[phoneNumber].delivered) *
                100
            )
          )
        : 0;
    });

    return mergedData;
  };

  const result =
    selectedType === "All"
      ? mergeDataByPhoneNumber(analyticsAllV1?.SMS, analyticsAllV1?.MMS)
      : selectedType === "SMS"
      ? mergeDataByPhoneNumber(analyticsAllV1?.SMS)
      : selectedType === "MMS"
      ? mergeDataByPhoneNumber(analyticsAllV1?.MMS)
      : {};

  setNumberGraphObj(result);
};

export const countriesForRBM = {
  NAM: [
    "US",
    "MX",
    "CA",
    "GT",
    "HT",
    "DO",
    "CU",
    "HN",
    "NI",
    "SV",
    "CR",
    "PA",
    "JM",
    "TT",
    "BS",
    "BZ",
    "BB",
    "LC",
    "GD",
    "VC",
    "BM",
    "GL",
    "PM",
    "UM",
  ],
  LATAM: [
    "BR",
    "AR",
    "PY",
    "PA",
    "CO",
    "EC",
    "VE",
    "CL",
    "UY",
    "BO",
    "PE",
    "GT",
    "HN",
    "SV",
    "NI",
    "MX",
    "DO",
    "CU",
    "CR",
    "HT",
    "GF",
    "PR",
    "GP",
    "MQ",
    "LC",
    "AG",
    "SR",
    "GY",
    "DM",
    "JM",
    "BZ",
    "BS",
    "BB",
    "GD",
    "KN",
    "TT",
    "VC",
    "KY",
    "TC",
    "VI",
    "BL",
    "AW",
    "SX",
    "VG",
    "MS",
    "AI",
    "FK",
    "MF",
    "CW",
    "ES",
    // Caribbean Netherlands
  ],
  Europe: [
    "BY",
    "BG",
    "CZ",
    "HU",
    "MD",
    "PL",
    "RO",
    "RU",
    "SK",
    "UA",
    "XK",
    "AL",
    "AD",
    "BA",
    "HR",
    "CY",
    "GI",
    "GR",
    "IT",
    "MK",
    "MT",
    "ME",
    "PT",
    "SM",
    "RS",
    "SI",
    "ES",
    "VA",
    "AT",
    "BE",
    "FR",
    "DE",
    "LI",
    "LU",
    "MC",
    "NL",
    "CH",
    "AX",
    "DK",
    "EE",
    "FO",
    "FI",
    "GG",
    "IS",
    "IE",
    "JE",
    "LV",
    "LT",
    "IM",
    "NO",
    "SJ",
    "SE",
    "GB",
  ],
  Africa: [
    "DZ",
    "EG",
    "LY",
    "MA",
    "SD",
    "TN",
    "EH",
    "AO",
    "CM",
    "CF",
    "TD",
    "CG",
    "CD",
    "GQ",
    "GA",
    "ST",
    "SS",
    "BJ",
    "BF",
    "CV",
    "CI",
    "GM",
    "GH",
    "GN",
    "GW",
    "LR",
    "ML",
    "MR",
    "NE",
    "NG",
    "SH",
    "SN",
    "SL",
    "TG",
    "IO",
    "BI",
    "KM",
    "DJ",
    "ER",
    "ET",
    "KE",
    "MG",
    "MW",
    "MU",
    "YT",
    "MZ",
    "RE",
    "RW",
    "SC",
    "SO",
    "TZ",
    "UG",
    "ZW",
    "BW",
    "TF",
    "LS",
    "NA",
    "ZA",
    "SZ",
    "ZM",
  ],
};

export const getSortIcon = (
  columnName: string,
  currentSortColumn: string,
  currentSortAsc: string
) => {
  if (columnName !== currentSortColumn) return null;
  return currentSortAsc === "asc" ? "▲" : "▼"; // Use any icon or library like FontAwesome
};

export const handleDateFunction = (value: any, defaultDate?: any) => {
  let startDate = "";
  let endDate = moment().format("MM-DD-YYYY HH:mm:ss");

  if (value === "24 hours") {
    startDate = moment().subtract(24, "hours").format("MM-DD-YYYY HH:mm:ss");
  } else if (value === "7 days") {
    startDate = moment().subtract(6, "days").format("MM-DD-YYYY  HH:mm:ss");
  } else if (value === "30 days") {
    startDate = moment()
      .subtract(29, "days")
      .startOf("day")
      .format("MM-DD-YYYY  HH:mm:ss");
  } else if (value === "120 days") {
    startDate = moment()
      .subtract(119, "days")
      .startOf("day")
      .format("MM-DD-YYYY  HH:mm:ss");
  } else {
    if (defaultDate) {
      startDate = defaultDate;
      endDate = moment().format("MM-DD-YYYY HH:mm:ss");
    } else {
      startDate = "";
      endDate = "";
    }
  }
  return { startDate, endDate };
};
