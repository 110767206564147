import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Nav,
  Tab,
  ProgressBar,
  Form,
  Dropdown,
} from "react-bootstrap";
import withRouter from "Common/withRouter";
import { getBrandDetailsThunk } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  asyncBrandList,
  countriesForRBM,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { City, Country, State } from "country-state-city";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { BrandSelect } from "Common/filter/brand-select";
import { subgroupListApi } from "helpers/apiroute_helper";

const CreateAgent = (props: any) => {
  document.title = "Signal House Portal Add RBM Agent";

  const dispatch = useDispatch<any>();

  const [allCountry, setAllCountry] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const [allCity, setAllCity] = useState<any>([]);
  const [activeTab, setactiveTab] = useState<number>(0);
  const [subgroupTemp, setSubgroupTemp] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [autoFillData, setAutoFillData] = useState<any>([]);

  const selectEnums = createSelector(
    (state: any) => state.Brands,
    (brands) => ({
      brandDetails: brands.brandDetails,
    })
  );

  const { brandDetails } = useSelector(selectEnums);

  const validation1: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: [],
      subGroupId: [],
      legalCompanyName: "",
      companyName: "",
      agentDisplayName: "",
      brandCompanyName: "",
      ein: "",
      address: "",
      state: "",
      city: "",
      zip: "",
      sector: "",
      rbmType: "",
      contactPersonName: "",
      contactPersonJobTitle: "",
      contactPersonEmail: "",
      contactPersonPhone: "",
      useCase: "",
      action: "",
      testingNumber: [],
      anticipatedUsersNumber: "",
      messageCount: "",
      launchDate: "",
      optOut: "",
      optIn: "",
      typicalResponse: "",
    },
    validationSchema: Yup.object({
      country: Yup.array()
        .min(1, "At least one country must be selected")
        .required("Country is required"),
      subGroupId: Yup.array()
        .min(1, "At least one sub group must be selected")
        .required("Please select sub group"),
      legalCompanyName: Yup.string().required(
        "Please enter legal company name"
      ),
      companyName: Yup.string().when("country", ([country]) => {
        return country.some((ct: string) => ct !== "US")
          ? Yup.string().required("Please enter company name")
          : Yup.string().notRequired();
      }),
      agentDisplayName: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string().required("Please enter agent display name")
          : Yup.string().notRequired();
      }),
      brandCompanyName: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string().required("Please enter brand company name")
          : Yup.string().notRequired();
      }),
      ein: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string()
              .max(10)
              .matches(
                /^\d{2}-\d{7}$/,
                "Invalid - It should be in XX-XXXXXXX format"
              )
              .required("Please enter ein")
          : Yup.string().notRequired();
      }),
      address: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string().required("Please enter address")
          : Yup.string().notRequired();
      }),
      state: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string().required("Please select state")
          : Yup.string().notRequired();
      }),
      city: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string().required("Please select city")
          : Yup.string().notRequired();
      }),
      zip: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string().max(10).required("Please enter zip")
          : Yup.string().notRequired();
      }),
      sector: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string().required("Please enter sector")
          : Yup.string().notRequired();
      }),
      rbmType: Yup.string().required("Please select RBM type"),
      contactPersonName: Yup.string().required("Please enter contact name"),
      contactPersonJobTitle: Yup.string().required(
        "Please enter contact person job title"
      ),
      contactPersonEmail: Yup.string()
        .email("Invalid email address")
        .max(100)
        .required("Please enter contact person email address"),
      contactPersonPhone: Yup.string().when("country", ([country]) => {
        return country.includes("US")
          ? Yup.string()
              .matches(
                /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
                "Phone number must start with 1-4 digit country code followed by 7-15 digit phone number, numbers only"
              )
              .required("Please enter contact person phone number")
          : Yup.string().notRequired();
      }),
      useCase: Yup.string().required("Please select use case"),
      action: Yup.string().when("country", ([country]) => {
        return country.some((ct: string) => ct !== "US")
          ? Yup.string().required("Please enter action")
          : Yup.string().notRequired();
      }),
      testingNumber: Yup.array().when("country", ([country]) => {
        return country.some((ct: string) => ct !== "US")
          ? Yup.array()
              .min(1, "At least one number must be added")
              .required("Please enter testing number")
          : Yup.array().min(0).notRequired();
      }),
      anticipatedUsersNumber: Yup.number().when("country", ([country]) => {
        return country.some((ct: string) => ct !== "US")
          ? Yup.number().min(1).required("Please Enter user count")
          : Yup.number().notRequired();
      }),
      messageCount: Yup.number().when("country", ([country]) => {
        return country.some((ct: string) => ct !== "US")
          ? Yup.number().min(1).required("Please Enter message count")
          : Yup.number().notRequired();
      }),
      launchDate: Yup.string().when("country", ([country]) => {
        return country.some((ct: string) => ct !== "US")
          ? Yup.string().required("Please enter launch date")
          : Yup.string().notRequired();
      }),
      optOut: Yup.string()
        .min(20, "message must be at least 20 characters")
        .max(320)
        .required("Please Enter opt-out message"),
      optIn: Yup.string()
        .min(20, "message must be at least 20 characters")
        .max(320)
        .required("Please Enter opt-in message"),
      typicalResponse: Yup.string()
        .min(20, "message must be at least 20 characters")
        .max(320)
        .required("Please Enter message"),
    }),
    onSubmit: () => {
      setactiveTab(1);
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      agentName: "",
      agentDescription: "",
      brandColor: "",
      traficSpike: "",
      traficSpikeTime: "",
      cfPhone: "",
      cfPhoneLabel: "",
      cfEmail: "",
      cfEmailLabel: "",
      companyWebsite: "",
      companyWebsiteLabel: "",
      privacyPolicyLink: "",
      termsofServiceLink: "",
      pCompanyWebsite: "",
      testingNumber2: [],
      messageCount2: "",
      launchDate2: "",
      agentBanner: null,
      agentLogo: null,
      signature: null,
    },
    validationSchema: Yup.object({
      agentName: Yup.string().when("country", ([]) => {
        return validation1.values.country.includes("US")
          ? Yup.string().required("Please enter agent name")
          : Yup.string().notRequired();
      }),
      agentDescription: Yup.string()
        .max(100)
        .required("Please enter agent description"),
      brandColor: Yup.string().required("Please select brand color"),
      traficSpike: Yup.string().required("Please select trafic spike"),
      traficSpikeTime: Yup.string().when("traficSpike", ([traficSpike]) => {
        return traficSpike === "Yes"
          ? Yup.string().required("Please select  trafic spike time")
          : Yup.string().notRequired();
      }),
      cfPhone: Yup.string()
        .required("Please enter customer facing phone number")
        .matches(
          /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
          "Phone number must start with 1-4 digit country code followed by 7-15 digit phone number, numbers only"
        ),
      cfPhoneLabel: Yup.string().required(
        "Please enter customer facing phone number label"
      ),
      cfEmail: Yup.string()
        .email("Invalid email address")
        .max(100)
        .required("Please enter customer facing email address"),
      cfEmailLabel: Yup.string().required(
        "Please enter customer facing email address label"
      ),
      companyWebsite: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .max(100)
        .required("Please enter official company website"),
      companyWebsiteLabel: Yup.string().required(
        "Please enter company website label"
      ),
      privacyPolicyLink: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .max(100)
        .required("Please enter privacy policy URL"),
      termsofServiceLink: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .max(100)
        .required("Please enter terms of service URL"),
      pCompanyWebsite: Yup.string().when("country", ([]) => {
        return validation1.values.country.some((ct: string) => ct !== "US")
          ? Yup.string()
              .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                "Enter correct url!"
              )
              .max(100)
              .required("Please enter parent company website")
          : Yup.string().notRequired();
      }),
      testingNumber2: Yup.array().when("country", ([]) => {
        return validation1.values.country?.length > 0 &&
          validation1.values.country.includes("US") &&
          !validation1.values.country.some((ct: string) => ct !== "US")
          ? Yup.array()
              .min(1, "At least one number must be added")
              .required("Please enter testing number")
          : Yup.array().min(0).notRequired();
      }),
      messageCount2: Yup.number().when("country", ([]) => {
        return validation1.values.country?.length > 0 &&
          validation1.values.country.includes("US") &&
          !validation1.values.country.some((ct: string) => ct !== "US")
          ? Yup.number().min(1).required("Please Enter message count")
          : Yup.number().notRequired();
      }),
      launchDate2: Yup.string().when("country", ([country]) => {
        return validation1.values.country?.length > 0 &&
          validation1.values.country.includes("US") &&
          !validation1.values.country.some((ct: string) => ct !== "US")
          ? Yup.string().required("Please enter launch date")
          : Yup.string().notRequired();
      }),
      agentBanner: Yup.string().required("Please add banner image"),
      agentLogo: Yup.string().required("Please add logo image"),
      signature: Yup.string().required("Please add signature"),
    }),
    onSubmit: () => {
      const temp1 = { ...validation1?.values };
      const temp2 = { ...validation2?.values };

      if (validation1.values.country.includes("US")) {
        delete temp1.companyName;
        delete temp1.action;
        delete temp1.testingNumber;
        delete temp1.anticipatedUsersNumber;
        delete temp1.messageCount;
        delete temp1.launchDate;
        delete temp2.pCompanyWebsite;
      } else {
        delete temp1.agentDisplayName;
        delete temp1.brandCompanyName;
        delete temp1.ein;
        delete temp1.address;
        delete temp1.state;
        delete temp1.city;
        delete temp1.zip;
        delete temp1.sector;
        delete temp1.contactPersonPhone;
        delete temp2.agentName;
        delete temp2.testingNumber2;
        delete temp2.messageCount2;
        delete temp2.launchDate2;
      }

      console.log("submit obj --------------", {
        ...temp1,
        ...temp2,
      });
    },
  });

  useEffect(() => {
    const combinedCountries = Object.values(countriesForRBM).flat();
    const uniqueCountries = combinedCountries.filter(
      (ct, index, self) => self.indexOf(ct) === index
    );
    const allCountries = Country.getAllCountries();
    const countriesInRegion = allCountries.filter((ct) =>
      uniqueCountries.includes(ct.isoCode)
    );
    setAllCountry(countriesInRegion);
  }, []);

  useEffect(() => {
    if (validation1.values.country?.length > 0) {
      const getStatesOfCountries = (countryCodes: any) => {
        return countryCodes.flatMap((countryCode: string) =>
          State.getStatesOfCountry(countryCode)
        );
      };

      const temp = getStatesOfCountries(validation1.values.country);
      setAllState(temp);
    } else {
      setAllState([]);
    }
  }, [validation1.values.country]);

  useEffect(() => {
    if (validation1.values.state) {
      const getCitiesOfStates = (countryCodes: any) => {
        return countryCodes.flatMap((countryCode: string) =>
          City.getCitiesOfState(countryCode, validation1.values.state)
        );
      };

      const temp = getCitiesOfStates(validation1.values.country);
      setAllCity(temp);
    } else {
      setAllCity([]);
    }
  }, [validation1.values.state]);

  useEffect(() => {
    if (brandDetails) {
      setAutoFillData([{ ...brandDetails }]);
    }
  }, [brandDetails]);

  const fetchSubGroupNames = async (ids: any) => {
    if (ids) {
      try {
        let data: any = await subgroupListApi(
          1,
          100,
          removeEmptyAndNullValues({
            subGroupId: ids,
          })
        );

        if (!data?.code && !data?.errorCode) {
          setSubgroupTemp(data?.records || []);
        }
      } catch (error: any) {
        console.error("Error fetching sub group names:", error);
      }
    } else {
      setSubgroupTemp([]);
    }
  };

  const handlePincodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    validation1.handleBlur(event);

    const zip = validation1.values.zip;

    if (zip) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          componentRestrictions: {
            postalCode: zip,
          },
        },
        (results: any, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            let city = "";
            let country = "";
            let state = "";

            for (let component of results[0].address_components) {
              if (component.types.includes("locality")) {
                city = component.long_name;
              }
              if (component.types.includes("country")) {
                country = component.short_name;
              }
              if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
              }
            }

            if (city) validation1.setFieldValue("city", city);
            if (state) validation1.setFieldValue("state", state);
            if (country) validation1.setFieldValue("country", [country]);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Register RBM Agent"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={open}
                onToggle={() => {
                  setOpen(!open);
                }}
              >
                <Dropdown.Toggle variant="primary">
                  Autofill Data
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      const formattedValue = autoFillData?.[0]?.ein
                        ? autoFillData?.[0]?.ein
                            ?.replace(/[^0-9a-zA-Z]/g, "")
                            ?.replace(/^(.{2})(.{0,7})/, "$1-$2")
                            ?.slice(0, 10)
                        : "";
                      validation1.setValues({
                        ...validation1.values,
                        companyName: autoFillData?.[0]?.companyName || "",
                        subGroupId: autoFillData?.[0]?.subGroupId || [],
                        country: autoFillData?.[0]?.country
                          ? [autoFillData?.[0]?.country]
                          : [],
                        ein: formattedValue,
                        address: autoFillData?.[0]?.street || "",
                        state: autoFillData?.[0]?.state || "",
                        city: autoFillData?.[0]?.city || "",
                        zip: autoFillData?.[0]?.postalCode || "",
                        contactPersonName: `${
                          autoFillData?.[0]?.firstName || ""
                        } ${autoFillData?.[0]?.lastName || ""}`.trim(),
                        contactPersonPhone: autoFillData?.[0]?.phone
                          ? Number(autoFillData?.[0]?.phone.replace(/\D/g, ""))
                          : "",
                        contactPersonEmail: autoFillData?.[0]?.email || "",
                      });
                      validation2.setValues({
                        ...validation2.values,
                        agentName: autoFillData?.[0]?.displayName || "",
                      });
                      fetchSubGroupNames(autoFillData?.[0]?.subGroupId?.[0]);
                    }}
                  >
                    <Form.Label htmlFor="brandId">Select Brand</Form.Label>
                    <BrandSelect
                      key="brandId"
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(false)}
                      theme={customSelectTheme}
                      value={autoFillData?.length > 0 ? autoFillData : null}
                      loadOptions={asyncBrandList({}, "brandId")}
                      getOptionValue={(option: any) => option?.brandId}
                      getOptionLabel={(option: any) =>
                        option?.displayName + " - " + option?.brandId
                      }
                      onChange={(option: any) => {
                        if (option) {
                          option?.brandId &&
                            dispatch(getBrandDetailsThunk(option?.brandId));
                        } else {
                          setAutoFillData([]);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                    <div className="d-flex align-items-center justify-content-end mt-3">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        onClick={() => {
                          setAutoFillData([]);
                          validation1.setValues({
                            companyName: "",
                            subGroupId: [],
                            country: [],
                            ein: "",
                            address: "",
                            state: "",
                            city: "",
                            zip: "",
                            contactPersonName: "",
                            contactPersonPhone: "",
                            contactPersonEmail: "",
                          });
                          validation2.setValues({
                            agentName: "",
                          });
                        }}
                      >
                        Clear
                      </Button>
                      <Button className="btn btn-primary" type="submit">
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isBack={true}
            backClick={() => props.router.navigate(-1)}
          />

          <div className="position-relative">
            <Card>
              {false ? (
                <div className={``}>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                    alt="loading"
                  />
                </div>
              ) : (
                <Card.Body className="">
                  <Tab.Container activeKey={activeTab}>
                    <div
                      id="custom-progress-bar"
                      className="progress-nav mb-4 mx-auto"
                      style={{ maxWidth: 600, width: "100%" }}
                    >
                      <div>
                        <ProgressBar
                          now={activeTab * 100}
                          style={{ height: "1px" }}
                        ></ProgressBar>
                      </div>

                      <Nav
                        as="ul"
                        variant="pills"
                        className="progress-bar-tab custom-nav"
                      >
                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="0"
                            onClick={() => setactiveTab(0)}
                            className="rounded-pill"
                          >
                            <span>1</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="1"
                            onClick={() => validation1?.handleSubmit()}
                            className="rounded-pill"
                          >
                            <span>2</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>

                    <Tab.Content>
                      <Step1
                        validation1={validation1}
                        subgroupTemp={subgroupTemp}
                        setSubgroupTemp={setSubgroupTemp}
                        allCountry={allCountry}
                        allState={allState}
                        allCity={allCity}
                        handlePincodeBlur={handlePincodeBlur}
                      />
                      <Step2
                        validation1={validation1}
                        validation2={validation2}
                        setactiveTab={setactiveTab}
                      />
                    </Tab.Content>
                  </Tab.Container>
                </Card.Body>
              )}
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateAgent);
