import DataTable from "react-data-table-component";

const Datatable = ({
  data = [],
  columns = [],
  handleRowClick = () => {},
  pagination = true,
  handlePageChange = () => {},
  customSort = () => {},
  sortColumn = "",
  isSortAsc = "",
  onSort,
  handleRowsPerPageChange = () => {},
  currPage = 1,
  rowsPerPage = 10,
  totalRecords = 0,
  expandableRows = false,
  expandableRowsComponent = () => {},
  className = "",
  conditionalRowStyles = [],
  customOptions = null,
}: any) => {
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  return (
    <div>
      <DataTable
        className={`${data?.length > 0 ? "" : "empty_table"} ${className}`}
        columns={columns}
        data={data}
        highlightOnHover
        onRowClicked={handleRowClick}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        customSort={customSort}
        sortServer={onSort ? true : false}
        defaultSortFieldId={sortColumn}
        defaultSortAsc={isSortAsc}
        onSort={onSort ? onSort : () => {}}
        pagination={pagination}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[10, 20, 30]}
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        paginationServer
        paginationDefaultPage={currPage}
        paginationServerOptions={{
          totalPages: Math.ceil(totalRecords / rowsPerPage),
          totalRows: totalRecords,
          // page: currPage,
          rowsPerPage: rowsPerPage,
        }}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
      />
      {customOptions && <div>{customOptions}</div>}
    </div>
  );
};

export default Datatable;
