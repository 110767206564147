import { AsyncPaginate } from "react-select-async-paginate";
import { useEffect, useState, useRef } from "react";
import { brandListApi } from "helpers/apiroute_helper";
import { removeEmptyAndNullValues } from "helpers/common";

interface Brand {
  brandId: string | number;
  [key: string]: any;
}

interface BrandSelectProps {
  value?: Brand | Brand[];
  onChange?: (value: Brand | Brand[] | null) => void;
  [key: string]: any;
}

export const BrandSelect = (props: any) => {
  const [selectedValue, setSelectedValue] = useState<Brand | Brand[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const optionsCacheRef = useRef<Record<string | number, Brand>>({});

  const handleChange = (newValue: Brand | Brand[] | null) => {
    if (newValue) {
      if (Array.isArray(newValue)) {
        newValue.forEach((item) => {
          optionsCacheRef.current[item.brandId] = item;
        });
      } else {
        optionsCacheRef.current[newValue.brandId] = newValue;
      }
    }
    props.onChange?.(newValue);
  };

  useEffect(() => {
    const fetchOptionData = async () => {
      if (!props.value) {
        setSelectedValue(null);
        return;
      }

      const isCached = Array.isArray(props.value)
        ? props.value.every((val: any) => optionsCacheRef.current[val.brandId])
        : props.value?.brandId && optionsCacheRef.current[props.value.brandId];

      if (isCached) {
        const cachedValue = Array.isArray(props.value)
          ? props.value.map((val: any) => optionsCacheRef.current[val.brandId])
          : optionsCacheRef.current[props.value.brandId];
        setSelectedValue(cachedValue);
        return;
      }

      setIsLoading(true);
      try {
        const limit = Array.isArray(props.value) ? props.value.length : 1;
        const brandIds = Array.isArray(props.value)
          ? props.value.map((dt: any) => dt.brandId)
          : props.value.brandId
          ? [props.value.brandId]
          : "";

        const response: any = await brandListApi(
          1,
          limit,
          removeEmptyAndNullValues({
            brandId: brandIds,
          })
        );
        const records = Array.isArray(props.value)
          ? response?.records || []
          : response?.records?.[0] || null;

        if (Array.isArray(records)) {
          records.forEach((record) => {
            optionsCacheRef.current[record.brandId] = record;
          });
        } else if (records?.brandId) {
          optionsCacheRef.current[records.brandId] = records;
        }

        setSelectedValue(records);
      } catch (error: any) {
        console.error("Error fetching option data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptionData();
  }, [props.value]);

  return (
    <AsyncPaginate
      {...props}
      value={selectedValue}
      isLoading={isLoading}
      onChange={handleChange}
    />
  );
};
