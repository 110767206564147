import { Button, Col, Dropdown, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  asyncActivePhoneNumberList,
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  asyncTagsList,
  customSelectTheme,
  dateRangeArr,
  handleDateFunction,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { AsyncPaginate } from "react-select-async-paginate";
import withRouter from "Common/withRouter";
import { setLocal } from "helpers/services/auth/auth";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";
import { getAllMsgShortLinkThunk } from "slices/thunk";
import { useDispatch } from "react-redux";
import { allMsgShortLinkApi } from "helpers/apiroute_helper";

// import Tooltip from "rc-tooltip";

const selectedTypeArr = ["All", "SMS", "MMS"];
const selectedTypeArr2 = ["All", "Outbound", "Inbound"];

const AnalyticsHeader = (props: any) => {
  const {
    selectedType,
    setSelectedType,
    selectedType2,
    setSelectedType2,
    setSelectedSubType,
    setSelectedSubTypeLabel,
    validation,
    setFilterObj,
    dateRangeTemp,
    setDateRangeTemp,
    datePickerModal,
    setDatePickerModal,
    setOpen,
    open,
    loading,
    handleGetData,
    groupDetails,
  } = props;

  // Add this async function for link options
  const asyncLinkList =
    (params: any) =>
    async (search: string, loadedOptions: any, { page }: any) => {
      try {
        const response: any = await allMsgShortLinkApi(
          page,
          10,
          removeEmptyAndNullValues({
            ...params,
            filter: search || "",
          })
        );

        return {
          options:
            response?.records?.map((item: any) => ({
              shortUrl: item.shortUrl,
              friendlyName: item.friendlyName,
              label: `${item.friendlyName} - ${item.originalUrl}`,
              originalUrl: item.originalUrl,
            })) || [],
          hasMore: response?.totalRecords > page * 10,
          additional: {
            page: page + 1,
          },
        };
      } catch (error: any) {
        console.error("Error loading link options:", error);
        return {
          options: [],
          hasMore: false,
        };
      }
    };

  return (
    <div className="page-title-box d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center mb-1 me-3">
          <h4 className="mb-0 me-3">Analytics</h4>
          <div
            className="btn-group"
            role="group"
            aria-label="Analytics filters"
          >
            <div className="btn-group" role="group" aria-label="Message type">
              {selectedTypeArr
                ?.filter((dt) => dt !== "All")
                ?.map((dt, i) => (
                  // <Tooltip placement="bottom" overlay={dt} key={i}>
                  <Button
                    key={i}
                    className={`btn me-1 ${
                      selectedType === dt || selectedType === "All"
                        ? "btn-secondary "
                        : "btn-outline-secondary hover-button"
                    }`}
                    onClick={() => {
                      if (selectedType === dt || selectedType === "All") {
                        const otherType = selectedTypeArr.find(
                          (t) => t !== "All" && t !== dt
                        );
                        setSelectedType(otherType || "All");
                      } else {
                        setSelectedType(selectedType === "All" ? dt : "All");
                      }
                    }}
                  >
                    {dt === "SMS" ? (
                      <i className="bi bi-chat-left-text" />
                    ) : (
                      <i className="bi bi-file-image" />
                    )}
                    <span className="ms-1 d-none d-sm-inline">{dt}</span>
                  </Button>
                  // </Tooltip>
                ))}
            </div>
            <div
              className="btn-group"
              role="group"
              aria-label="Message direction"
            >
              {selectedTypeArr2
                ?.filter((dt) => dt !== "All")
                ?.map((dt, i) => (
                  // <Tooltip placement="bottom" overlay={dt} key={i}>
                  <Button
                    key={i}
                    className={`btn me-1  ${
                      selectedType2 === dt || selectedType2 === "All"
                        ? "btn-secondary"
                        : "btn-outline-secondary hover-button"
                    }`}
                    onClick={() => {
                      const setTypes = (type: any) => {
                        if (type === "Inbound") {
                          setSelectedSubType("totalInbound");
                          setSelectedSubTypeLabel("Total messages received");
                        } else {
                          setSelectedSubType("deliveryRate");
                          setSelectedSubTypeLabel("Delivered");
                        }
                      };

                      if (selectedType2 === dt || selectedType2 === "All") {
                        const otherType = selectedTypeArr2.find(
                          (t) => t !== "All" && t !== dt
                        );
                        setSelectedType2(otherType || "All");
                        setTypes(otherType);
                      } else {
                        setSelectedType2(selectedType2 === "All" ? dt : "All");
                        setTypes(dt);
                      }
                    }}
                  >
                    <i
                      className={`bi bi-send${dt === "Inbound" ? "-fill" : ""}`}
                    />
                    <span className="ms-1 d-none d-sm-inline">{dt}</span>
                  </Button>
                  // </Tooltip>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-end"
        style={{ flex: "1 1 0%" }}
      >
        <div className="d-flex align-items-center flex-wrap">
          {validation.values.subGroupId?.length > 0 && (
            <span className="details-tag mt-1 mr-2 d-flex align-items-center">
              Subgroup Id: {validation.values.subGroupId?.join(", ")}
            </span>
          )}
          {validation.values.brandId?.length > 0 && (
            <span className="details-tag mt-1 mr-2 d-flex align-items-center">
              Brand Id:{" "}
              {validation.values.brandId
                ?.map((dt: any) => dt.brandId)
                ?.join(", ")}
            </span>
          )}
          {validation.values.campaignId?.length > 0 && (
            <span className="details-tag mt-1 mr-2 d-flex align-items-center">
              Campaign Id:{" "}
              {validation.values.campaignId
                ?.map((dt: any) => dt.campaignId)
                ?.join(", ")}
            </span>
          )}
          {validation.values.phoneNumber?.length > 0 && (
            <span className="details-tag mt-1 mr-2 d-flex align-items-center">
              Phone Number:{" "}
              {validation.values.phoneNumber
                ?.map((dt: any) => dt.phoneNumber)
                ?.join(", ")}
            </span>
          )}
          {validation.values.tag?.length > 0 && (
            <span className="details-tag mt-1 mr-2 d-flex align-items-center">
              Tag:
              {validation.values.tag?.map((dt: any) => dt.tag)?.join(", ")}
            </span>
          )}
          {validation.values.link?.length > 0 && (
            <span className="details-tag mt-1 mr-2 d-flex align-items-center">
              Link:{" "}
              {validation.values.link
                ?.map((dt: any) => dt.friendlyName || dt.shortUrl)
                ?.join(", ")}
            </span>
          )}
          {dateRangeTemp?.value ? (
            dateRangeTemp?.value === "Custom" ? (
              <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                Date Range: {validation.values.startDate}
                {validation.values.endDate
                  ? ` to ${validation.values.endDate}`
                  : ""}
              </span>
            ) : (
              <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                Date Range: {dateRangeTemp?.value}
              </span>
            )
          ) : null}
        </div>
        <Dropdown
          align="end"
          className="ms-2 filterDropDown2"
          show={open}
          onToggle={() => {
            if (!datePickerModal) {
              setOpen(!open);
            }
          }}
        >
          <Dropdown.Toggle variant="secondary">
            <i className="bx bx-filter-alt me-1" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-md p-4">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Row>
                <div>
                  {validation.touched.groupId && validation.errors.groupId ? (
                    <p className="text-primary">{validation.errors.groupId}</p>
                  ) : null}
                </div>
                <Col sm={6} className="mb-3">
                  <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                  <GroupSelect
                    isDisabled={!validation.values.groupId}
                    key={validation.values.groupId}
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    styles={styleObj(
                      validation?.touched?.subGroupId &&
                        validation?.errors?.subGroupId
                    )}
                    theme={customSelectTheme}
                    value={
                      validation.values.subGroupId?.length > 0
                        ? validation.values.subGroupId.map((dt: string) => ({
                            sub_group_id: dt,
                            sub_group_name: dt,
                            label: `${dt} - ${dt}`,
                          }))
                        : null
                    }
                    name="subGroupId"
                    loadOptions={asyncSubgroupList(
                      removeEmptyAndNullValues({
                        groupId: validation.values.groupId || "",
                      }),
                      "subGroupId"
                    )}
                    getOptionValue={(option: any) => option?.sub_group_id}
                    getOptionLabel={(option: any) =>
                      option?.sub_group_name + " - " + option?.sub_group_id
                    }
                    onChange={(option: any) => {
                      if (
                        option &&
                        option.sub_group_id !== validation.values.subGroupId
                      ) {
                        validation.setFieldValue(
                          "subGroupId",
                          option.map((dt: any) => dt.sub_group_id)
                        );
                        validation.setFieldValue("brandId", []);
                        validation.setFieldValue("campaignId", []);
                        validation.setFieldValue("phoneNumber", []);
                        validation.setFieldValue("tag", []);
                      } else if (!option) {
                        validation.setFieldValue("subGroupId", []);
                      }
                    }}
                    additional={{
                      page: 1,
                    }}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label htmlFor="brandId">Brand</Form.Label>
                  <BrandSelect
                    key={
                      validation.values.subGroupId.toString() ||
                      validation.values.groupId ||
                      "brandId"
                    }
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    styles={styleObj(
                      validation?.touched?.brandId &&
                        validation?.errors?.brandId
                    )}
                    theme={customSelectTheme}
                    value={
                      validation.values.brandId?.length > 0
                        ? validation.values.brandId
                        : null
                    }
                    loadOptions={asyncBrandList(
                      removeEmptyAndNullValues({
                        groupId: validation.values.groupId || "",
                        subGroupId:
                          validation.values.subGroupId.length > 0
                            ? validation.values.subGroupId
                            : "",
                      }),
                      "brandId"
                    )}
                    getOptionValue={(option: any) => option?.brandId}
                    getOptionLabel={(option: any) =>
                      option?.displayName + " - " + option?.brandId
                    }
                    onChange={(option: any) => {
                      if (
                        option &&
                        option.brandId === validation.values.brandId
                      ) {
                        return;
                      }
                      if (option) {
                        validation.setFieldValue(
                          "subGroupId",
                          option.reduce(
                            (acc: string[], dt: any) => {
                              if (
                                dt?.subGroupId &&
                                Array.isArray(dt.subGroupId)
                              ) {
                                dt.subGroupId.forEach((subGroup: string) => {
                                  if (
                                    !acc.includes(subGroup) &&
                                    !validation.values.subGroupId.includes(
                                      subGroup
                                    )
                                  ) {
                                    acc.push(subGroup);
                                  }
                                });
                              }
                              return acc;
                            },
                            [...validation.values.subGroupId]
                          )
                        );
                        validation.setFieldValue(
                          "brandId",
                          option.map((dt: any) => ({
                            subGroupId: dt?.subGroupId || [],
                            brandId: dt?.brandId || "",
                          }))
                        );
                        validation.setFieldValue("campaignId", []);
                        validation.setFieldValue("phoneNumber", []);
                        validation.setFieldValue("tag", []);
                      } else {
                        validation.setFieldValue("brandId", []);
                      }
                    }}
                    additional={{
                      page: 1,
                    }}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                  <AsyncPaginate
                    key={
                      validation.values.brandId
                        ?.map((dt: any) => dt.brandId)
                        .toString() ||
                      validation.values.subGroupId.toString() ||
                      validation.values.groupId ||
                      "campaignId"
                    }
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    styles={styleObj(
                      validation?.touched?.campaignId &&
                        validation?.errors?.campaignId
                    )}
                    theme={customSelectTheme}
                    value={
                      validation.values.campaignId?.length > 0
                        ? validation.values.campaignId
                        : null
                    }
                    loadOptions={asyncCampaignList(
                      removeEmptyAndNullValues({
                        groupId: validation.values.groupId || "",
                        subGroupId:
                          validation.values.subGroupId.length > 0
                            ? validation.values.subGroupId
                            : "",
                        brandId:
                          validation.values.brandId.length > 0
                            ? validation.values.brandId?.map(
                                (dt: any) => dt.brandId
                              )
                            : "",
                      }),
                      "campaignId"
                    )}
                    getOptionValue={(option: any) => option?.campaignId}
                    getOptionLabel={(option: any) => option?.campaignId}
                    onChange={(option: any) => {
                      if (
                        option &&
                        option.campaignId === validation.values.campaignId
                      ) {
                        return;
                      }
                      if (option) {
                        validation.setFieldValue(
                          "subGroupId",
                          option.reduce(
                            (acc: string[], dt: any) => {
                              if (
                                dt?.subGroupId &&
                                Array.isArray(dt.subGroupId)
                              ) {
                                dt.subGroupId.forEach((subGroup: string) => {
                                  if (
                                    !acc.includes(subGroup) &&
                                    !validation.values.subGroupId.includes(
                                      subGroup
                                    )
                                  ) {
                                    acc.push(subGroup);
                                  }
                                });
                              }
                              return acc;
                            },
                            [...validation.values.subGroupId]
                          )
                        );
                        validation.setFieldValue(
                          "brandId",
                          option.reduce(
                            (acc: any[], dt: any) => {
                              const existingItem =
                                validation.values.brandId.find(
                                  (item: any) =>
                                    item.brandId === dt.brandId &&
                                    JSON.stringify(item.subGroupId) ===
                                      JSON.stringify(dt.subGroupId)
                                );
                              if (!existingItem) {
                                acc.push({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                });
                              }
                              return acc;
                            },
                            [...validation.values.brandId]
                          )
                        );
                        validation.setFieldValue(
                          "campaignId",
                          option.map((dt: any) => ({
                            subGroupId: dt?.subGroupId || [],
                            brandId: dt?.brandId || "",
                            campaignId: dt?.campaignId || "",
                          }))
                        );
                        validation.setFieldValue("phoneNumber", []);
                        validation.setFieldValue("tag", []);
                      } else {
                        validation.setFieldValue("campaignId", []);
                      }
                    }}
                    additional={{
                      page: 1,
                    }}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
                  <AsyncPaginate
                    key={
                      validation.values.campaignId
                        ?.map((dt: any) => dt.campaignId)
                        .toString() ||
                      validation.values.brandId
                        ?.map((dt: any) => dt.brandId)
                        .toString() ||
                      validation.values.subGroupId.toString() ||
                      validation.values.groupId ||
                      "phoneNumber"
                    }
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    styles={styleObj(
                      validation?.touched?.phoneNumber &&
                        validation?.errors?.phoneNumber
                    )}
                    theme={customSelectTheme}
                    value={
                      validation.values.phoneNumber
                        ? validation.values.phoneNumber
                        : null
                    }
                    loadOptions={asyncActivePhoneNumberList(
                      removeEmptyAndNullValues({
                        groupId: validation.values.groupId || "",
                        subGroupId:
                          validation.values.subGroupId.length > 0
                            ? validation.values.subGroupId
                            : "",
                        brandId:
                          validation.values.brandId.length > 0
                            ? validation.values.brandId?.map(
                                (dt: any) => dt.brandId
                              )
                            : "",
                        campaignId:
                          validation.values.campaignId.length > 0
                            ? validation.values.campaignId?.map(
                                (dt: any) => dt.campaignId
                              )
                            : "",
                      }),
                      "phoneNumber"
                    )}
                    getOptionValue={(option: any) => option?.phoneNumber}
                    getOptionLabel={(option: any) => option?.phoneNumber}
                    onChange={(option: any) => {
                      if (
                        option &&
                        option.phoneNumber === validation.values.phoneNumber
                      ) {
                        return;
                      }

                      if (option) {
                        validation.setFieldValue(
                          "subGroupId",
                          option.reduce(
                            (acc: string[], dt: any) => {
                              if (
                                dt?.subGroupId &&
                                Array.isArray(dt.subGroupId)
                              ) {
                                dt.subGroupId.forEach((subGroup: string) => {
                                  if (
                                    !acc.includes(subGroup) &&
                                    !validation.values.subGroupId.includes(
                                      subGroup
                                    )
                                  ) {
                                    acc.push(subGroup);
                                  }
                                });
                              }
                              return acc;
                            },
                            [...validation.values.subGroupId]
                          )
                        );
                        validation.setFieldValue(
                          "brandId",
                          option.reduce(
                            (acc: any[], dt: any) => {
                              const existingItem =
                                validation.values.brandId.find(
                                  (item: any) =>
                                    item.brandId === dt.brandId &&
                                    JSON.stringify(item.subGroupId) ===
                                      JSON.stringify(dt.subGroupId)
                                );
                              if (!existingItem) {
                                acc.push({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                });
                              }
                              return acc;
                            },
                            [...validation.values.brandId]
                          )
                        );
                        validation.setFieldValue(
                          "campaignId",
                          option.reduce(
                            (acc: any[], dt: any) => {
                              const existingItem =
                                validation.values.campaignId.find(
                                  (item: any) =>
                                    item.campaignId === dt.campaignId &&
                                    item.brandId === dt.brandId &&
                                    JSON.stringify(item.subGroupId) ===
                                      JSON.stringify(dt.subGroupId)
                                );
                              if (!existingItem) {
                                acc.push({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt.campaignId || "",
                                });
                              }
                              return acc;
                            },
                            [...validation.values.campaignId]
                          )
                        );
                        validation.setFieldValue(
                          "phoneNumber",
                          option.map((dt: any) => ({
                            subGroupId: dt?.subGroupId || [],
                            brandId: dt?.brandId || "",
                            campaignId: dt.campaignId || "",
                            phoneNumber: dt?.phoneNumber || "",
                          }))
                        );
                        validation.setFieldValue("tag", []);
                      } else {
                        validation.setFieldValue("phoneNumber", []);
                      }
                    }}
                    additional={{
                      page: 1,
                    }}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label htmlFor="tag">Tags</Form.Label>
                  <AsyncPaginate
                    key={
                      validation.values.phoneNumber
                        ?.map((dt: any) => dt.phoneNumber)
                        .toString() ||
                      validation.values.campaignId
                        ?.map((dt: any) => dt.campaignId)
                        .toString() ||
                      validation.values.brandId
                        ?.map((dt: any) => dt.brandId)
                        .toString() ||
                      validation.values.subGroupId.toString() ||
                      validation.values.groupId ||
                      "tag"
                    }
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    styles={styleObj(
                      validation?.touched?.tag && validation?.errors?.tag
                    )}
                    theme={customSelectTheme}
                    value={validation.values.tag ? validation.values.tag : null}
                    loadOptions={asyncTagsList(
                      removeEmptyAndNullValues({
                        groupId: validation.values.groupId || "",
                        subGroupId:
                          validation.values.subGroupId.length > 0
                            ? validation.values.subGroupId
                            : "",
                        brandId:
                          validation.values.brandId.length > 0
                            ? validation.values.brandId?.map(
                                (dt: any) => dt.brandId
                              )
                            : "",
                        campaignId:
                          validation.values.campaignId.length > 0
                            ? validation.values.campaignId?.map(
                                (dt: any) => dt.campaignId
                              )
                            : "",
                        phoneNumber:
                          validation.values.phoneNumber.length > 0
                            ? validation.values.phoneNumber?.map(
                                (dt: any) => dt.phoneNumber
                              )
                            : "",
                        isReleased: false,
                      }),
                      "tagSearch"
                    )}
                    getOptionValue={(option: any) => option?.tag}
                    getOptionLabel={(option: any) => option?.tag}
                    onChange={(option: any) => {
                      if (option && option.tag === validation.values.tag) {
                        return;
                      }

                      if (option) {
                        validation.setFieldValue(
                          "subGroupId",
                          option.reduce(
                            (acc: string[], dt: any) => {
                              if (
                                dt?.subGroupId &&
                                Array.isArray(dt.subGroupId)
                              ) {
                                dt.subGroupId.forEach((subGroup: string) => {
                                  if (
                                    !acc.includes(subGroup) &&
                                    !validation.values.subGroupId.includes(
                                      subGroup
                                    )
                                  ) {
                                    acc.push(subGroup);
                                  }
                                });
                              }
                              return acc;
                            },
                            [...validation.values.subGroupId]
                          )
                        );
                        validation.setFieldValue(
                          "brandId",
                          option.reduce(
                            (acc: any[], dt: any) => {
                              const existingItem =
                                validation.values.brandId.find(
                                  (item: any) =>
                                    item.brandId === dt.brandId &&
                                    JSON.stringify(item.subGroupId) ===
                                      JSON.stringify(dt.subGroupId)
                                );
                              if (!existingItem) {
                                acc.push({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                });
                              }
                              return acc;
                            },
                            [...validation.values.brandId]
                          )
                        );
                        validation.setFieldValue(
                          "campaignId",
                          option.reduce(
                            (acc: any[], dt: any) => {
                              const existingItem =
                                validation.values.campaignId.find(
                                  (item: any) =>
                                    item.campaignId === dt.campaignId &&
                                    item.brandId === dt.brandId &&
                                    JSON.stringify(item.subGroupId) ===
                                      JSON.stringify(dt.subGroupId)
                                );
                              if (!existingItem) {
                                acc.push({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt.campaignId || "",
                                });
                              }
                              return acc;
                            },
                            [...validation.values.campaignId]
                          )
                        );
                        validation.setFieldValue(
                          "phoneNumber",
                          option.reduce(
                            (acc: any[], dt: any) => {
                              const existingItem =
                                validation.values.phoneNumber.find(
                                  (item: any) =>
                                    item.phoneNumber === dt.phoneNumber &&
                                    item.campaignId === dt.campaignId &&
                                    item.brandId === dt.brandId &&
                                    JSON.stringify(item.subGroupId) ===
                                      JSON.stringify(dt.subGroupId)
                                );
                              if (!existingItem) {
                                acc.push({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt.campaignId || "",
                                  phoneNumber: dt.phoneNumber || "",
                                });
                              }
                              return acc;
                            },
                            [...validation.values.phoneNumber]
                          )
                        );
                        validation.setFieldValue("tag", option);
                      } else {
                        validation.setFieldValue("tag", []);
                      }
                    }}
                    additional={{
                      page: 1,
                    }}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label htmlFor="link">Link</Form.Label>
                  <AsyncPaginate
                    key={
                      validation.values.phoneNumber
                        ?.map((dt: any) => dt.phoneNumber)
                        .toString() ||
                      validation.values.campaignId
                        ?.map((dt: any) => dt.campaignId)
                        .toString() ||
                      validation.values.brandId
                        ?.map((dt: any) => dt.brandId)
                        .toString() ||
                      validation.values.subGroupId.toString() ||
                      validation.values.groupId ||
                      "link"
                    }
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    styles={styleObj(
                      validation?.touched?.link && validation?.errors?.link
                    )}
                    theme={customSelectTheme}
                    value={
                      validation.values.link ? validation.values.link : null
                    }
                    loadOptions={asyncLinkList(
                      removeEmptyAndNullValues({
                        subGroupId:
                          validation.values.subGroupId.length > 0
                            ? validation.values.subGroupId
                            : "",
                        brandId:
                          validation.values.brandId.length > 0
                            ? validation.values.brandId?.map(
                                (dt: any) => dt.brandId
                              )
                            : "",
                        campaignId:
                          validation.values.campaignId.length > 0
                            ? validation.values.campaignId?.map(
                                (dt: any) => dt.campaignId
                              )
                            : "",
                        phoneNumber:
                          validation.values.phoneNumber.length > 0
                            ? validation.values.phoneNumber?.map(
                                (dt: any) => dt.phoneNumber
                              )
                            : "",
                      })
                    )}
                    getOptionValue={(option: any) => option?.originalUrl}
                    getOptionLabel={(option: any) =>
                      `${option?.friendlyName || ""} - ${option?.originalUrl}`
                    }
                    onChange={(option: any) => {
                      validation.setFieldValue("link", option);
                    }}
                    additional={{
                      page: 1,
                    }}
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label htmlFor="dateRange">Date Range</Form.Label>
                  <Select
                    styles={styleObj(false)}
                    theme={customSelectTheme}
                    options={dateRangeArr}
                    onChange={(e: any) => {
                      setDateRangeTemp(e);

                      const { startDate, endDate } = handleDateFunction(
                        e.value
                      );

                      validation.setFieldValue("createdAt", "");
                      validation.setFieldValue("startDate", startDate);
                      validation.setFieldValue("endDate", endDate);
                    }}
                    value={dateRangeTemp}
                  />
                </Col>
                {dateRangeTemp?.value === "Custom" ? (
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="createdAt">Select Range</Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="createdAt"
                      placeholder="Select Range"
                      options={{
                        enableTime: false,
                        onOpen: () => {
                          setDatePickerModal(true);
                        },
                        onClose: () => {
                          setDatePickerModal(false);
                        },
                        altFormat: "F j, Y",
                        dateFormat: "m-d-Y",
                        mode: "range",
                        onChange: (
                          value: any,
                          dateStr: string,
                          instance: any
                        ) => {
                          validation.setFieldValue("createdAt", dateStr || "");
                          value?.[0] &&
                            validation.setFieldValue(
                              "startDate",
                              moment(value?.[0]).format("MM-DD-YYYY")
                            );
                          value?.[1] &&
                            validation.setFieldValue(
                              "endDate",
                              moment(value?.[1]).format("MM-DD-YYYY")
                            );
                        },
                      }}
                      value={validation.values.createdAt || ""}
                    />
                  </Col>
                ) : null}
              </Row>
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  className="btn btn-secondary me-2"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    let values = {
                      ...validation.initialValues,
                      groupId: groupDetails?.records?.[0]?.group_id,
                      subGroupId: [],
                      brandId: [],
                      campaignId: [],
                      phoneNumber: [],
                      tag: [],
                      link: [],
                      createdAt: "",
                      startDate: moment()
                        .subtract(6, "days")
                        .startOf("day")
                        .format("MM-DD-YYYY"),
                      endDate: moment().format("MM-DD-YYYY HH:mm:ss"),
                    };
                    validation.setValues(values);
                    setFilterObj(values);
                    handleGetData(values);
                    validation.resetForm();
                    validation.setFieldValue(
                      "groupId",
                      groupDetails?.records?.[0]?.group_id
                    );
                    setDatePickerModal(false);
                    setDateRangeTemp(dateRangeArr[1]);
                    setLocal(
                      "analyticsDateRangeTemp",
                      JSON.stringify(dateRangeArr[1])
                    );
                    setLocal(
                      "analyticsStartDate",
                      JSON.stringify(
                        moment()
                          .subtract(6, "days")
                          .startOf("day")
                          .format("MM-DD-YYYY")
                      )
                    );
                    setLocal(
                      "analyticsEndDate",
                      JSON.stringify(moment().format("MM-DD-YYYY HH:mm:ss"))
                    );
                  }}
                >
                  Clear
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading && <Spinner size="sm" animation="border" />} Apply
                </Button>
              </div>
            </Form>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default withRouter(AnalyticsHeader);
