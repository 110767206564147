import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updatePasswordThunk,
  fetchAuthHistoryThunk,
  openModal,
  updateAuthUserThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import Datatable from "Common/Datatable";
import { dateFormat } from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";

const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%*?&]{8,}$/;

const Security = () => {
  const dispatch = useDispatch<any>();

  const [passwordShow1, setPasswordShow1] = useState<boolean>(false);
  const [passwordShow2, setPasswordShow2] = useState<boolean>(false);
  const [passwordShow3, setPasswordShow3] = useState<boolean>(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selectAccount = createSelector(
    (state: any) => state.ForgetPassword,
    (state: any) => state.AuthUser,
    (forgetPassword, authUser) => ({
      loading: forgetPassword.loading,
      loading2: authUser.loading3,
      success: authUser.success,
      error: authUser.error,
      historyRecords: authUser.historyRecords,
      authUser: authUser?.authUser,
    })
  );

  const { loading, loading2, historyRecords, authUser } =
    useSelector(selectAccount);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Device</span>,
      selector: (row: {
        device: string;
        deviceInfo: { mobileModel: string; mobileVendor: string };
      }) => (
        <span className="d-flex align-items-center">
          {(row.deviceInfo.mobileModel &&
            row.deviceInfo.mobileModel !== "none") ||
          (row.deviceInfo.mobileVendor &&
            row.deviceInfo.mobileVendor !== "none") ? (
            <i className="bi bi-phone me-2 fs-lg text-secondary"></i>
          ) : (
            <i className="bi bi-laptop me-2 fs-lg text-secondary"></i>
          )}
          {row.device}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.device.localeCompare(b.device),
    },
    {
      name: <span className="font-weight-bold fs-sm">IP Address</span>,
      selector: (row: { ip: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-globe me-2 fs-lg text-secondary" />
          {row.ip}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.ip.localeCompare(b.ip),
    },
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      selector: (row: { created: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.created)}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
    },
    {
      name: <span className="font-weight-bold fs-sm">Address</span>,
      selector: (row: { location: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-geo-alt me-2 fs-lg text-secondary" />
          {row.location}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.location.localeCompare(b.location),
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: () => {
        return (
          <span className="badge bg-primary-subtle text-primary fs-sm">
            Logout
            <i className="bi bi-box-arrow-right ms-2" />
          </span>
        );
      },
      sortable: false,
    },
  ];

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Please Enter Old Password")
        .min(8)
        .max(128)
        .matches(
          passwordRegex,
          "Password Must Include at Least One Uppercase Letter, One Lowercase Letter, One Digit, and One Special Character (@, $, !, %, ?, or &)"
        ),
      newPassword: Yup.string()
        .required("Please Enter New Password")
        .min(8)
        .max(128)
        .matches(
          passwordRegex,
          "Password Must Include at Least One Uppercase Letter, One Lowercase Letter, One Digit, and One Special Character (@, $, !, %, ?, or &)"
        ),
      confPassword: Yup.string()
        .required("Please Enter Confirm Password")
        .min(8)
        .max(128)
        .matches(
          passwordRegex,
          "Password Must Include at Least One Uppercase Letter, One Lowercase Letter, One Digit, and One Special Character (@, $, !, %, ?, or &)"
        )
        .oneOf([Yup.ref("newPassword"), ""], "Passwords must match"),
    }),
    onSubmit: (values) => {
      dispatch(
        updatePasswordThunk({
          oldPassword: values?.oldPassword,
          newPassword: values?.newPassword,
        })
      );
    },
  });

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(fetchAuthHistoryThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Security Settings" />
        <Card>
          <Card.Body>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation2.handleSubmit();
              }}
            >
              <Row className=" g-2 justify-content-lg-between">
                <Col lg={4}>
                  <div>
                    <Form.Label htmlFor="oldPassword" className="form-label">
                      Old Password <span className="text-primary">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordShow1 ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Enter current password"
                        name="oldPassword"
                        value={validation2.values.oldPassword || ""}
                        onChange={validation2.handleChange}
                        onBlur={validation2.handleBlur}
                        isInvalid={
                          validation2.touched.oldPassword &&
                          validation2.errors.oldPassword
                            ? true
                            : false
                        }
                      />
                      {validation2.touched.oldPassword &&
                      validation2.errors.oldPassword ? (
                        <Form.Control.Feedback type="invalid">
                          {validation2.errors.oldPassword}
                        </Form.Control.Feedback>
                      ) : null}
                      <Button
                        variant="link"
                        className="position-absolute top-0 end-0 text-decoration-none text-muted password-addon"
                        onClick={() => setPasswordShow1(!passwordShow1)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="auth-pass-inputgroup">
                    <Form.Label htmlFor="newPassword" className="form-label">
                      New Password <span className="text-primary">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordShow2 ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Enter new password"
                        name="newPassword"
                        value={validation2.values.newPassword || ""}
                        onChange={validation2.handleChange}
                        onBlur={validation2.handleBlur}
                        isInvalid={
                          validation2.touched.newPassword &&
                          validation2.errors.newPassword
                            ? true
                            : false
                        }
                      />
                      {validation2.touched.newPassword &&
                      validation2.errors.newPassword ? (
                        <Form.Control.Feedback type="invalid">
                          {validation2.errors.newPassword}
                        </Form.Control.Feedback>
                      ) : null}
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        onClick={() => setPasswordShow2(!passwordShow2)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="auth-pass-inputgroup">
                    <Form.Label htmlFor="confPassword">
                      Confirm Password <span className="text-primary">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordShow3 ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Confirm password"
                        name="confPassword"
                        value={validation2.values.confPassword || ""}
                        onChange={validation2.handleChange}
                        onBlur={validation2.handleBlur}
                        isInvalid={
                          validation2.touched.confPassword &&
                          validation2.errors.confPassword
                            ? true
                            : false
                        }
                      />
                      {validation2.touched.confPassword &&
                      validation2.errors.confPassword ? (
                        <Form.Control.Feedback type="invalid">
                          {validation2.errors.confPassword}
                        </Form.Control.Feedback>
                      ) : null}
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        onClick={() => setPasswordShow3(!passwordShow3)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </div>
                </Col>
                <div className="d-flex align-items-center justify-content-between">
                  <Link
                    to={process.env.PUBLIC_URL + "/forgot-password"}
                    className="link-primary text-decoration-underline"
                  >
                    Forgot Password?
                  </Link>
                  <div className="">
                    <Button type="submit" variant="primary" disabled={loading}>
                      Change Password
                    </Button>
                  </div>
                </div>
              </Row>
            </Form>

            <Col lg={12}>
              <Row className="border mt-3 bg-light-gray rounded-3 p-3">
                <Col lg={12} className="">
                  <div className="mt-3 mb-1">
                    <h5>Two-factor authentication</h5>
                  </div>
                </Col>
                <Row>
                  <Col className="mb-3">
                    Two-factor authentication (2FA) enhances your account
                    security by requiring SMS verification. Each time you log
                    in, you'll need a code sent to your mobile device, ensuring
                    only you can access your account.
                  </Col>
                  <Col lg={"auto"}>
                    <div
                      className="form-check form-switch form-switch-md"
                      dir="ltr"
                    >
                      <Form.Check
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={authUser?.twoFAEnabled}
                        onChange={() => {
                          authUser?.phone
                            ? authUser?.numberVerified === "verified"
                              ? dispatch(
                                  updateAuthUserThunk({
                                    twoFAEnabled: !authUser?.twoFAEnabled,
                                  })
                                )
                              : dispatch(
                                  openModal({
                                    path: "2fa",
                                    data: {
                                      title: "Verify Number",
                                      footer: false,
                                    },
                                  })
                                )
                            : dispatch(
                                openModal({
                                  modalbody:
                                    "Please add phone number in profile page",
                                  data: {
                                    title: "No Phone Number Found",
                                    footer: true,
                                    cancelBtn: true,
                                  },
                                })
                              );
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Row>
            </Col>
            <div className="mt-4 mb-4 pb-3 border-bottom d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Login History</h5>
              <div className="flex-shrink-0">
                <Button variant="secondary">All Logout</Button>
              </div>
            </div>
            <Row>
              <Col lg={12}>
                {loading2 ? (
                  <div className={``} style={{ height: 60 }}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(historyRecords?.records)
                        ? historyRecords?.records
                        : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={historyRecords?.totalRecords}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Security;
