//Register
export const REGISTER = "/auth/register";
export const REGISTER_COMPANY_ADMIN = "/auth/register/companyAdmin";
export const REGISTER_SUBUSER = "/auth/subUser/register";
export const REGISTER_COMPANY_ADMIN_BY_ADMIN =
  "/auth/register/companyAdminByAdmin";
export const REGISTER_COMPANY_BILLING = "/auth/register/companyBilling";
export const REGISTER_COMPANY_DEVELOPER = "/auth/register/companyDeveloper";
export const REGISTER_COMPANY_STANDARD = "/auth/register/companyStandard";
export const SUBUSER_GROUPS_ASSIGN = "/user/groups/assign";
export const SUBUSER_SUBGROUPS_ASSIGN = "/user/subGroups/assign";
export const SUBUSERS_LIST = "/user/subUsers";
export const SUBUSER_STATUS = "/user/subUsers/subUsersStatus";
export const SUBUSER_LOGIN_DETAIL = "/auth/subUser/loggedIn";
export const UPDATE_SUBUSER_PASS = "/auth/subUser/password";
export const VERIFY_EMAIL = "auth/verifyEmail";
export const LOGIN = "/auth/login";
export const CHECK_USER = "/auth/checkUser";
export const FORGOT_PASS = "/create-password";
export const UPDATE_PASS = "/auth/updatePassword";
export const CREATE_PASS = "/auth/forgotPassword";

export const GET_AUTH_USER = "/user/info";
export const authHistory = "/auth/history";
export const UPLOAD_FILE = "/file/upload";
export const GET_FILE = "/file/getFiles";
export const UPDATE_AUTH_USER = "/user";
export const GET_BRAND_LIST = "/brand/basicBrandDetails";
export const GET_BRAND_DETAILS = "/brand";
export const UPDATE_BRAND = (brandId: string) => `/brand/${brandId}`;
export const REVERT_BRAND = (brandId: string) => `/brand/${brandId}/revet`;
export const DELETE_BRAND = (brandId: string) => `/brand/${brandId}`;
export const GET_VERTICAL_LIST = "/enum/vertical";
export const GET_STOCK_EXCHANGE_LIST = "/enum/stockExchange";
export const GET_COUNTRY_LIST = "/enum/country";
export const GET_STATE_LIST = "/enum/usState";
export const GET_AREA_CODE = "/phoneNumber/areaCodes";
export const GET_BRAND_APPEAL_CATEGORY_LIST = "/enum/brandAppealCategory";
export const GET_ENTITY_TYPE_LIST = "/enum/entityType";
export const GET_BRAND_RELATIONSHIP_LIST = "/enum/brandRelationship";
export const CREATE_BRAND = "/brand/nonBlocking";
export const GET_PHONE_NUMBER = "/phoneNumber/getPhoneNumber";
export const BUY_PHONE_NUMBER = "/phoneNumber/buyPhoneNumber";
export const Configure_Phone_Number = "/phoneNumber/configurePhoneNumber";
export const Configure_Phone_Number_Edit =
  "/phoneNumber/fetchConfigurePhoneNumber";
export const PORTING_PHONE_NUMBER = "/phoneNumber/portingNumbers";
export const Active_Phone_Number = "/phoneNumber/myPhoneNumbers";
export const RELEASE_PHONE_NUMBER = "/phoneNumber/releasePhoneNumber";
export const GET_VERIFIED_CALLER_IDS = "/phoneNumber/myVerifiedPhoneNumbers";
export const CREATE_VERIFIED_CALLER = "/phoneNumber/createVerification";
export const CHECK_VERIFICATION = "/phoneNumber/checkVerification";
export const GET_RELEASED_PHONE_NUMBERS = "/phoneNumber/myReleasedPhoneNumbers";
export const GET_PORT_REQUEST = "/phoneNumber/portRequest";
export const GET_OUT_PORT_REQUEST = "/phoneNumber/portOutRequest";
export const CREATE_PORT_REQUEST = "/phoneNumber/portNumber";
export const SAVE_TAGS = "/phoneNumber/addTags";
export const GET_OWN_NUMBER_DETAILS = "/phoneNumber/getPhoneNumberDetails";
export const GET_CAMPAIGN_LIST = "/campaign/details/basicCampaign";
export const GET_CAMPAIGN_DCA_ELECTED_STATUS_LIST =
  "/campaign/campaignDcaElected/Status";
export const GET_USECASE = "/enum/usecase";
export const GET_CONNECTIVITY_PARTNERS = "/enum/cnp";
export const CREATE_CAMPAIGN = "/campaign/storeForReview";
export const GET_CAMPAIGN_BRAND = "/campaign/campaignBuilder/brand";
export const GET_TnC = "/campaign/campaignBuilder/termsAndConditions";
export const GET_RESELLER_LIST = "/reseller";
export const GET_CAMPAIGN_DETAILS = "/campaign";
export const GET_PARTNER_CAMPAIGN_DETAILS = "/partnerCampaign";
export const GET_REVIEW_CAMPAIGN_DETAILS = "/campaign/review";
export const UPDATE_PHONE = (campaignUuid: string) =>
  `/campaign/${campaignUuid}/numbers`;
export const GET_CAMPAIGN_ATTACHMENT = "/campaign/attachment";
export const WALLET_SUMMARY = "/wallet/summary";
export const CREATE_PAYMENT_INTENT = "/wallet/createPaymentIntent";
export const WALLET_CUSTOMER = "/wallet/customer";
export const PAYMENT_METHOD = "/wallet/paymentMethod";
export const PAY_WITH_PAYMENT_METHOD = "/wallet/payWithPaymentMethod";
export const GET_PAYMENT_DETAIL = (transactionId: string) =>
  `/wallet/paymentDetail/${transactionId}`;
export const APPEAL = (brandId: string) => `/brand/${brandId}/appeal`;
export const FILE_UPLOAD = (brandId: string) =>
  `/brand/${brandId}/appeal/evidence`;
export const BRAND_AND_CAMPAIGN_ATTACHMENT = (attachmentId: string) =>
  `/campaign/attachment/${attachmentId}`;
export const CSP_WEBHOOK_EVENTS = "/csp/webhook/events";
export const OSR_PHONES = "/osr/phones";
export const Vetting = (brandId: string) => `/brand/${brandId}/externalVetting`;
export const VETTING_CLASS = "/enum/vettingClass";
export const VETTING_CLASS_AND_IMPORT = "enum/extVettingProvider";
export const VETTING_CLASS_SUBMIT = (brandId: string) =>
  `/brand/${brandId}/externalVetting`;
export const VETTING_CLASS_IMPORT_SUBMIT = (brandId: string) =>
  `/brand/${brandId}/externalVetting`;
export const VETTING_CLASS_REPORT = (brandId: string) =>
  `/brand/${brandId}/externalVetting`;
export const MNO = "/enum/mno";
export const ALL_MESSAGE_LOGS = "/message/logs";
export const MESSAGE_LOGS = "/message/getMessageLog";
export const MESSAGE_LOGS_DETAILS = "/message/getMessageLogDetails";
export const MMS_LOGS = "/message/getMMSMessageLog";
export const MMS_LOGS_DETAILS = "/message/getMMSMessageLogDetails";
export const ALL_MSG_SHORT_LINK = "/message/allShortLinks";
export const CREATE_MSG_SHORT_LINK = "/message/shortLink";
export const USAGE_SUMMARY = "/wallet/usageSummary";
export const USAGE_TRANSACTIONS = "/wallet/usageTransactions";
export const WALLET_AUTO_RECHARGE = "/wallet/autoRecharge";
export const SEND_SMS = "/message/sendSMS";
export const SEND_MMS = "/message/sendMMS";
export const UPLOAD_MMS_FILE = "/message/uploadMMSfile";
export const GET_MMS_FILE = "/message/mmsAttachment";
export const MESSAGE_CREATE_TEMPLATE = "/message/createTemplate";
export const MESSAGE_FIND_TEMPLATE = "/message/findTemplate";
export const MESSAGE_TEMPLATE_DETAILS = "/message/getTemplateDetails";
export const MESSAGE_EDIT_TEMPLATE = "/message/editTemplate";
export const MESSAGE_DELETE_TEMPLATE = (templateId: string) =>
  `/message/deleteTemplate?templateId=${templateId}`;
export const USER_STEPS = "/user/stepts";
export const GET_PERSONAL_MESSAGE_LOGS = (phoneNumber: any) =>
  `/message/logs/${phoneNumber}`;
export const PHONE_NUMBER_LOOKUP = "/phoneNumber/syniverseLookUp";
export const CREATE_PORT_NUMBER_REQUEST = "/phoneNumber/portNumber";
export const ATTACHMENT_ID_SENDER_NUMBER =
  "/phoneNumber/attachmentIdSenderNumber";
export const TEN_DLC_WHITE_LIST = "/phoneNumber/tenDLCWhitelist";
export const ACCOUNT_PRICING = "/wallet/pricing";
export const GET_OWN_NUMBERS_EVENT_LOGS = () => "/message/getEventLogs";
export const GET_REGULATORY_INFORMATION = () =>
  "/phoneNumber/regulatoryInformation";
export const WEBHOOK = "/webhook";
export const GET_WEBHOOK_LOGS = "/webhook/logs";
export const GET_WEBHOOK_EVENTS = "/webhook/events";
export const GET_PORTING_STATUS_LOGS = () =>
  "/phoneNumber/getPortingStatusLogs";
export const NUMBER_VERIFY_LOOKUP = (phoneNumber: string) =>
  `/phoneNumber/numVerifyLookUp?phoneNumber=${phoneNumber}`;
export const ADD_FRIENDLY_NAME = "/phoneNumber/addFriendlyName";
export const GET_LOOKUP_LOGS = () => "/phoneNumber/lookupLogs";
export const GET_ALL_GROUPS = () => "/user/getGroups";
export const CREATE_NEW_GROUP = "/user/createNewGroup";
export const UPDATE_GROUP = "/user/updateGroup";
export const GROUP_DETAILS = "/user/getGroupDetails";
export const DELETE_GROUP = (groupId: string) => `/user/deleteGroup?${groupId}`;
export const GET_SUBGROUP_LIST = "/user/subGroup/get";
export const GET_SUBGROUP_SEARCH = "/user/subGroup/search";

export const GET_SUBGROUP_DETAILS = "/user/subGroup/Details";
export const GET_SUBGROUP_DETAILS_COUNT = "/user/subGroups/data";
export const CREATE_SUBGROUP = "/user/subGroup/create";
export const UPDATE_SUBGROUP = "/user/subGroup/update";

// 2fa endpoints
export const NUM_VERIFY = "/auth/otp/numVerify";
export const LOGIN_OTP_VERIFY = "/auth/otp/loginVerify";
export const GET_OTP = "/auth/otp";

// analytics
export const ANALYTICS_SMS_V1 = "/analytics/analyticsSmsV1";
export const ANALYTICS_MMS_V1 = "/analytics/analyticsMmsV1";
export const SMS_STATUS_LOGS = "/analytics/smsStatusLogs";
export const MMS_STATUS_LOGS = "/analytics/mmsStatusLogs";
export const STATUS_LOGS = "/analytics/analyticsLogs";

// notification
export const NOTIFICATION_LOGS = "/notification/notificationEvents";
export const CREATE_NOTIFICATION_EVENTS =
  "/notification/createNotificationEvents";
export const WEB_NOTIFICATIONS = "/notification/webNotifications";
export const GET_NOTIFICATIONS_LIST = "/notification/notifications";
export const GET_TAGS_LIST = "/phoneNumber/tags";
export const ANALYTICS_OUTBOUND = "/analytics/analyticsOutbound";
export const ANALYTICS_INBOUND = "/analytics/analyticsInbound";
export const DASHBOARD_ANALYTICS = "/analytics/dashboardAnalytics";
