// action
import {
  buyNumberApi,
  getNumbersApi,
  ownNumberDetail,
  releasedPhoneNumbersListApi,
  createPhoneNumberLookupApi,
  attachmentIdSenderNumberApi,
  tenDLCWhitelistApi,
  getNumVerifyLookUpApi,
  getLookupLogsApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  fetchNumbersSuccessful,
  fetchNumbersFailed,
  purchaseNumberSuccessful,
  purchaseNumberFailed,
  releasedPhoneNumberDataSuccessful,
  releasedPhoneNumberDataFailed,
  ownNumberDetailApiSuccess,
  ownNumberDetailApiFailed,
  ownNumberDetailInitialState,
  phoneNumberLookupSuccessful,
  phoneNumberLookupFailed,
  attachmentSuccess,
  attachmentFailure,
  whitelistSuccess,
  whitelistFailure,
  getNumVerifyLookUpSuccessful,
  getNumVerifyLookUpFailed,
  resetNumVerifyLookUpData,
  getNumberLookupLogsSuccessful,
  getNumberLookupLogsFailed,
  resetNumberLookupLogsData,
} from "./reducer";
import { toast } from "react-toastify";

export const fetchNumbersListThunk =
  (page?: any, recordsPerPage?: any, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      let response = getNumbersApi(page, recordsPerPage, otherParams);
      const data: any = await response;
      if (data?.code || data?.errorCode) {
        dispatch(fetchNumbersFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(fetchNumbersSuccessful(data));
      }
    } catch (error: any) {
      dispatch(fetchNumbersFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const purchaseNumberThunk =
  (payload: any, cb?: any) => async (dispatch: any) => {
    // dispatch(apiLoading());
    try {
      const data: any = await buyNumberApi(payload);
      if (data?.code || data?.errorCode) {
        dispatch(purchaseNumberFailed(data));
        toast.error(data?.message);
      } else {
        dispatch(purchaseNumberSuccessful(data));
        toast.success(data?.message || "Phone number purchased successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(purchaseNumberFailed(error?.response?.data));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getDetailsOfOwnNumber =
  (payload: any, cb?: any) => async (dispatch: any) => {
    // dispatch(apiLoading());
    try {
      const data: any = await ownNumberDetail(payload);
      if (data) {
        //  toast.success(data?.message );
        dispatch(ownNumberDetailApiSuccess(data));
      } else {
        dispatch(ownNumberDetailApiFailed(data));
      }
    } catch (error: any) {
      dispatch(ownNumberDetailApiFailed(error?.response?.data));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const setOwnNumberDetailInitialState = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(ownNumberDetailInitialState());
};

export const releasedPhoneNumberListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const data: any = await releasedPhoneNumbersListApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode || data?.message) {
        dispatch(releasedPhoneNumberDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(
          releasedPhoneNumberDataSuccessful({
            ...data,
            data: data.records,
            currentPage: data.page,
          })
        );
      }
    } catch (error: any) {
      dispatch(releasedPhoneNumberDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const PhoneNumberLookupThunk =
  (payload: any) => async (dispatch: any, data: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await createPhoneNumberLookupApi(payload);

      if (data?.code) {
        dispatch(phoneNumberLookupFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(phoneNumberLookupSuccessful(data));
      }
    } catch (error: any) {
      dispatch(phoneNumberLookupFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// Phone Number Lookup Logs Thunk
export const getLookupLogsThunk =
  (pagination: { page: number; limit: any }, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      // Pass additional parameters to getMessageLogsApi function
      const data: any = await getLookupLogsApi(pagination, otherParams);
      if (data?.code) {
        dispatch(getNumberLookupLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getNumberLookupLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getNumberLookupLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getNumVerifyLookUpThunk =
  (phoneNumber: string) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getNumVerifyLookUpApi(phoneNumber);

      if (data?.code) {
        dispatch(getNumVerifyLookUpFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getNumVerifyLookUpSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getNumVerifyLookUpFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const clearNumVerifyLookUpData = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(resetNumVerifyLookUpData());
};

export const attachmentIdSenderNumberThunk =
  (payload: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const response: any = await attachmentIdSenderNumberApi(payload);
      if (response?.code || response?.errorCode) {
        dispatch(attachmentFailure(response));
        toast.error(response?.message || "Something went wrong");
        return Promise.reject(response);
      } else {
        dispatch(attachmentSuccess(response));
        toast.success(response?.message);
        return Promise.resolve(response);
      }
    } catch (error: any) {
      const errorData = error?.response?.data || error;
      console.error("Error in attachmentIdSenderNumberThunk:", errorData);
      dispatch(attachmentFailure(errorData));
      toast.error(errorData?.message || "Something went wrong");
      return Promise.reject(errorData);
    }
  };

// Thunk for tenDLCWhitelist
export const tenDLCWhitelistThunk = (payload: any) => async (dispatch: any) => {
  dispatch(apiLoading());
  try {
    const response: any = await tenDLCWhitelistApi(payload);
    if (response?.code || response?.errorCode) {
      dispatch(whitelistFailure(response));
      toast.error(response?.message || "Something went wrong");
      return Promise.reject(response);
    } else {
      dispatch(whitelistSuccess(response));
      toast.success(response?.message);
      return Promise.resolve(response);
    }
  } catch (error: any) {
    const errorData = error?.response?.data || error;
    console.error("Error in tenDLCWhitelistThunk:", errorData);
    dispatch(whitelistFailure(errorData));
    toast.error(errorData?.message || "Something went wrong");
    return Promise.reject(errorData);
  }
};
