import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Nav,
  Tab,
  ProgressBar,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import withRouter from "Common/withRouter";
import {
  getVerticalListThunk,
  getStockExchangeListThunk,
  getCountryListThunk,
  getStateListThunk,
  getBrandAppealCategoryListThunk,
  getEntityTypeListThunk,
  getBrandRelationshipListThunk,
  createbrandThunk,
  getBrandDetailsThunk,
  updateBrandThunk,
  openModal,
  getCampaignListThunk,
  getActiveCampaignListThunk,
  resetCampaignList,
  fatchAccountPricingThunk,
  getSubgroupDetailsThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { removeEmptyAndNullValues } from "helpers/common";
import { useParams, useSearchParams } from "react-router-dom";
import { City, State } from "country-state-city";
import Step1 from "./Step1";
import Step3 from "./Step3";
import { subgroupListApi } from "helpers/apiroute_helper";
import { FocusError } from "focus-formik-error";

const CreateBrand = (props: any) => {
  document.title = "Signal House Portal Add Brand";

  const { brand_id } = useParams();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");

  const dispatch = useDispatch<any>();

  const [allState, setAllState] = useState<any>({});
  const [allCity, setAllCity] = useState<any>([]);
  const [modal1, setModal1] = useState<boolean>(false);
  const [subgroupTemp, setSubgroupTemp] = useState<any>([]);
  const [isActiveCamp, setIsActiveCamp] = useState<boolean>(false);

  const brandDetails = useSelector((state: any) => state.Brands?.brandDetails);
  const selectEnums = createSelector(
    (state: any) => state.Enums,
    (state: any) => state.Groups,
    (state: any) => state.Brands,
    (state: any) => state.Campaigns,
    (state: any) => state.AccountPricing,
    (enums, groups, brands, campaigns, accountPricing) => ({
      loading: enums.loading,
      stateObj: enums.stateObj,
      brandRelationshipArr: enums.brandRelationshipArr,
      error: enums.error,
      groupDetails: groups?.AllGroups,
      loading2: brands.loading,
      activeCampaignsData: campaigns.activeCampaignsData,
      walletPricingData: accountPricing.walletPricingData,
    })
  );

  const {
    loading,
    stateObj,
    brandRelationshipArr,
    groupDetails,
    loading2,
    activeCampaignsData,
    walletPricingData,
  } = useSelector(selectEnums);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      entityType: "",
      displayName: "",
      companyName: "",
      ein: "",
      einIssuingCountry: "",
      altBusinessId: "",
      altBusinessIdType: "",
      firstName: "",
      lastName: "",
      mobilePhone: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      stockSymbol: "",
      stockExchange: "",
      website: "",
      vertical: "",
      groupId: groupDetails?.records?.[0]?.group_id || "",
      subGroupId: subGroupId ? [subGroupId] : [],
      // brandRelationship: "",
      phone: "",
      email: "",
      // cspId: "",
      // brandId: "",
      // universalEin: "",
      // mock: false,
    },
    validationSchema: Yup.object({
      entityType: Yup.string().required("Please Select Type"),
      displayName: Yup.string().max(255).required("Please Enter Brand Name"),
      companyName: Yup.string()
        .min(2)
        .max(255)
        .when("entityType", ([entityType]) => {
          return entityType === "SOLE_PROPRIETOR"
            ? Yup.string().max(255).notRequired()
            : Yup.string().max(255).required("Please Enter Company Name");
        }),
      ein: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string()
              .max(21)
              .matches(
                /^\d{2}-\d{7}$/,
                "Invalid - It should be in XX-XXXXXXX format"
              )
              .notRequired()
          : Yup.string()
              .max(21)
              .matches(
                /^\d{2}-\d{7}$/,
                "Invalid - It should be in XX-XXXXXXX format"
              )
              .required("Please enter above field");
      }),
      altBusinessId: Yup.string().max(50),
      altBusinessIdType: Yup.string(),
      firstName: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().min(2).max(50).required("Please Enter First Name")
          : Yup.string().min(2).max(50).notRequired();
      }),
      lastName: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().min(2).max(50).required("Please Enter Last Name")
          : Yup.string().min(2).max(50).notRequired();
      }),
      mobilePhone: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().max(20).required("Please Enter Mobile Phone")
          : Yup.string().max(20).notRequired();
      }),
      street: Yup.string().min(3).max(100).required("Please Enter Street"),
      city: Yup.string().min(2).max(100).required("Please Enter City"),
      state: Yup.string().min(2).max(20).required("Please Enter State"),
      postalCode: Yup.string().when("country", ([country]) => {
        return country === "US"
          ? Yup.string()
              .matches(
                /^\d{5}(-\d{4})?$/,
                "Invalid US postal code. Must be ##### or #####-#### format"
              )
              .required("Please Enter Postal Code")
          : Yup.string().max(10).required("Please Enter Postal Code");
      }),
      country: Yup.string().min(2).max(50).required("Please Select Country"),
      stockSymbol: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "PUBLIC_PROFIT"
          ? Yup.string().max(10).required("Please Enter Stock Symbol")
          : Yup.string().max(10).notRequired();
      }),
      stockExchange: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "PUBLIC_PROFIT"
          ? Yup.string().max(10).required("Please Enter Stock Exchange")
          : Yup.string().max(10).notRequired();
      }),
      website: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .max(100)
        .notRequired(),
      vertical: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().notRequired()
          : Yup.string().required("Please Select Vertical Type");
      }),
      // tag: Yup.string(),
      // mock: Yup.boolean(),
      groupId: Yup.string().required("Please Select Group"),
      // subGroupId: Yup.array()
      //   .min(1, "At least one subgroup must be selected")
      //   .required("Subgroups are required"),
      // brandRelationship: Yup.string().required(
      //   "Please Select Brand Relationship"
      // ),
      phone: Yup.string()
        .min(10)
        .max(15)
        .required("Please Enter Support Phone Number"),
      email: Yup.string()
        .email("Invalid email address")
        .max(100)
        .required("Please Enter Support Email"),
    }),
    onSubmit: () => {
      brandDetails?.companyName &&
      validation.values.companyName &&
      brandDetails?.companyName === validation.values.companyName &&
      brandDetails?.ein &&
      validation.values.ein &&
      brandDetails?.ein?.replace(/-/g, "") ===
        validation.values.ein?.replace(/-/g, "") &&
      brandDetails?.einIssuingCountry &&
      validation.values.einIssuingCountry &&
      brandDetails?.einIssuingCountry === validation.values.einIssuingCountry &&
      brandDetails?.entityType &&
      validation.values.entityType &&
      brandDetails?.entityType === validation.values.entityType
        ? handleSubmit()
        : setModal1(true);
    },
  });

  const handleSubmit = () => {
    if (brand_id) {
      //  Update Call Here
      const temp = {
        ...validation?.values,
        companyName:
          validation.values.entityType !== "SOLE_PROPRIETOR"
            ? validation?.values.companyName
            : "",
        brandRelationship:
          validation.values.entityType === "SOLE_PROPRIETOR"
            ? "BASIC_ACCOUNT"
            : "MEDIUM_ACCOUNT",
      };

      if (isActiveCamp) {
        delete temp.companyName;
        delete temp.ein;
        delete temp.einIssuingCountry;
        delete temp.entityType;
      }

      dispatch(
        updateBrandThunk(
          brand_id,
          removeEmptyAndNullValues(temp),
          props.router.navigate
        )
      );
    } else {
      const cb = (brandId?: string) => {
        dispatch(
          openModal({
            path: "CreateBrandSuccess",
            data: { footer: true, cancelBtn: true },
          })
        );
        brandId
          ? props.router.navigate(`/brands/${brandId}`)
          : props.router.navigate(`/brands`);
      };

      dispatch(
        createbrandThunk(
          removeEmptyAndNullValues({
            ...validation?.values,
            companyName:
              validation.values.entityType !== "SOLE_PROPRIETOR"
                ? validation?.values.companyName
                : "",
            brandRelationship:
              validation.values.entityType === "SOLE_PROPRIETOR"
                ? "BASIC_ACCOUNT"
                : "MEDIUM_ACCOUNT",
          }),
          cb
        )
      );
    }
  };

  useEffect(() => {
    dispatch(fatchAccountPricingThunk());
    dispatch(getVerticalListThunk());
    dispatch(getStockExchangeListThunk());
    dispatch(getCountryListThunk());
    dispatch(getStateListThunk());
    dispatch(getBrandAppealCategoryListThunk());
    dispatch(getEntityTypeListThunk());
    dispatch(getBrandRelationshipListThunk());

    subGroupId && fetchSubGroupNames(subGroupId);

    return () => {
      dispatch(resetCampaignList());
    };
  }, []);

  useEffect(() => {
    if (validation.values.country && validation.values.country !== "US") {
      const temp = State.getStatesOfCountry(validation.values.country);
      const temp2 = temp.reduce(
        (acc, cur) => ({ ...acc, [cur.isoCode]: cur.name }),
        {}
      );
      setAllState(temp2);
    } else {
      setAllState(stateObj);
    }
  }, [validation.values.country]);

  useEffect(() => {
    if (validation.values.state) {
      const temp = City.getCitiesOfState(
        validation.values.country,
        validation.values.state
      );
      setAllCity(temp);
    }
  }, [validation.values.state]);

  useEffect(() => {
    if (brand_id) {
      dispatch(getBrandDetailsThunk(brand_id));
      dispatch(
        getCampaignListThunk(1, 100, {
          brandId: brand_id,
        })
      );
      dispatch(
        getActiveCampaignListThunk(1, 100, {
          brandId: brand_id,
          status: "ACTIVE",
        })
      );
    }
  }, [brand_id]);

  useEffect(() => {
    activeCampaignsData?.records?.filter(
      (e: any) => e.status.toLowerCase() === "active"
    )?.length > 0
      ? setIsActiveCamp(true)
      : setIsActiveCamp(false);
  }, [activeCampaignsData]);

  useEffect(() => {
    if (brandDetails && brand_id && brandDetails?.brandId === brand_id) {
      brandDetails?.subGroupId?.[0] &&
        dispatch(getSubgroupDetailsThunk(brandDetails?.subGroupId?.[0]));

      const formattedValue = brandDetails?.ein
        ? brandDetails?.ein
            ?.replace(/[^0-9a-zA-Z]/g, "")
            ?.replace(/^(.{2})(.{0,7})/, "$1-$2")
            ?.slice(0, 10)
        : "";
      validation.setFieldValue("entityType", brandDetails?.entityType);
      validation.setFieldValue("displayName", brandDetails?.displayName);
      validation.setFieldValue("companyName", brandDetails?.companyName);
      validation.setFieldValue("ein", formattedValue);
      validation.setFieldValue(
        "einIssuingCountry",
        brandDetails?.einIssuingCountry
      );
      validation.setFieldValue("altBusinessId", brandDetails?.altBusinessId);
      validation.setFieldValue(
        "altBusinessIdType",
        brandDetails?.altBusinessIdType
      );
      // firstName: "",
      // lastName: "",
      // mobilePhone: "",
      validation.setFieldValue("street", brandDetails?.street);
      validation.setFieldValue("city", brandDetails?.city);
      validation.setFieldValue("state", brandDetails?.state);
      validation.setFieldValue("postalCode", brandDetails?.postalCode);
      validation.setFieldValue("country", brandDetails?.country);
      validation.setFieldValue("stockSymbol", brandDetails?.stockSymbol);
      validation.setFieldValue("stockExchange", brandDetails?.stockExchange);
      validation.setFieldValue("website", brandDetails?.website);
      validation.setFieldValue("vertical", brandDetails?.vertical);
      validation.setFieldValue("groupId", brandDetails?.groupId);
      validation.setFieldValue("subGroupId", brandDetails?.subGroupId || []);
      fetchSubGroupNames(brandDetails?.subGroupId);
      validation.setFieldValue("cspId", brandDetails?.cspId);
      validation.setFieldValue("brandId", brandDetails?.brandId);
      validation.setFieldValue("universalEin", brandDetails?.universalEin);
      // validation.setFieldValue(
      //   "brandRelationship",
      //   brandDetails?.brandRelationship
      // );
      validation.setFieldValue("phone", brandDetails?.phone);
      validation.setFieldValue("email", brandDetails?.email);
    }
  }, [brandDetails]);

  const fetchSubGroupNames = async (ids: any) => {
    if (ids.length > 0) {
      try {
        let data: any = await subgroupListApi(
          1,
          100,
          removeEmptyAndNullValues({
            groupId: validation.values.groupId || "",
            subGroupId: ids,
          })
        );

        if (!data?.code && !data?.errorCode) {
          setSubgroupTemp(data?.records || []);
        }
      } catch (error: any) {
        console.error("Error fetching sub group names:", error);
      }
    } else {
      setSubgroupTemp([]);
    }
  };

  const handlePincodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    validation.handleBlur(event);

    const postalCode = validation.values.postalCode;

    if (postalCode) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          componentRestrictions: {
            postalCode: postalCode,
          },
        },
        (results: any, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            let city = "";
            let country = "";
            let state = "";

            for (let component of results[0].address_components) {
              if (component.types.includes("locality")) {
                city = component.long_name;
              }
              if (component.types.includes("country")) {
                country = component.short_name;
              }
              if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
              }
            }

            if (city) validation.setFieldValue("city", city);
            if (state) validation.setFieldValue("state", state);
            if (country) validation.setFieldValue("country", country);
            if (country)
              validation?.setFieldValue("einIssuingCountry", country);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  };

  const entityTypeOnSelectionChange = (value: string) => {
    validation.setFieldValue("entityType", value);
    if (value === "GOVERNMENT") {
      validation?.setFieldValue("country", "US");
      validation?.setFieldValue("einIssuingCountry", "US");
    }
    // validation?.setFieldValue("brandRelationship", "MEDIUM_ACCOUNT");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={brand_id ? "Edit Brand" : "Register Brand"}
            isBack={true}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
          />

          <div className="position-relative wrapper">
            {loading ? (
              <div>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <FocusError formik={validation} />
                  <Card>
                    <Card.Header className="">
                      <h6 className="card-title mb-2">Brand Details</h6>
                    </Card.Header>
                    <Card.Body className="">
                      <Step1
                        validation={validation}
                        subgroupTemp={subgroupTemp}
                        setSubgroupTemp={setSubgroupTemp}
                        isActiveCamp={isActiveCamp}
                        entityTypeOnSelectionChange={
                          entityTypeOnSelectionChange
                        }
                        allState={allState}
                        allCity={allCity}
                        handlePincodeBlur={handlePincodeBlur}
                        brandName={
                          brandDetails?.displayName || brandDetails?.companyName
                        }
                      />
                    </Card.Body>
                  </Card>

                  {/* <Card>
                    <Card.Header className="">
                      <h6 className="card-title mb-2">Brand Relationship</h6>
                    </Card.Header>
                    <Card.Body className="">
                      <Step2
                        validation={validation}
                        brandDetails={brandDetails}
                        brandRelationshipArr={brandRelationshipArr}
                      />
                    </Card.Body>
                  </Card> */}

                  <Card>
                    <Card.Header className="">
                      <h6 className="card-title mb-2">Brand Contact Details</h6>
                    </Card.Header>
                    <Card.Body className="">
                      <Step3 validation={validation} />
                    </Card.Body>
                  </Card>
                  <div className="hstack gap-2 justify-content-end mb-4">
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        validation.resetForm();
                        setSubgroupTemp([]);
                        props.router.navigate("/brands");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={loading || loading2}
                    >
                      {loading2 && <Spinner size="sm" animation="border" />}{" "}
                      Submit
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </div>
        </Container>
      </div>

      <Modal centered show={modal1} onHide={() => setModal1(false)}>
        <Modal.Body className="p-4 text-center fs-5">
          <div>
            A one-time, non-refundable payment of $
            {walletPricingData["brand.create"] || "4.00"} is required to
            register each BRAND. It will be added to your next balance.
          </div>
          <div>
            Click <b>I agree to confirm.</b>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="hstack gap-2 justify-content-end">
            <Button
              className="btn btn-outline-primary"
              onClick={() => setModal1(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              id="add-btn"
              onClick={() => {
                setModal1(false);
                handleSubmit();
              }}
            >
              I Agree
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(CreateBrand);
