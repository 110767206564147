import Loader from "assets/images/spinner-dark.svg";
import MessageTable from "pages/Messaging/MessageTable";

const SmsAndMmsLogs = ({
  loading,
  rowsPerPage,
  currPage,
  logsData,
  handleRowClick,
  handlePageChange,
  handleRowsPerPageChange,
  onSort,
}: any) => {
  return (
    <div className="position-relative">
      {loading ? (
        <div style={{ height: 60 }}>
          <img
            src={Loader}
            className={`position-absolute top-50 start-50 translate-middle`}
            alt="loading"
          />
        </div>
      ) : (
        <MessageTable
          data={Array.isArray(logsData?.records) ? logsData?.records : []}
          handleRowClick={handleRowClick}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          currPage={currPage}
          rowsPerPage={rowsPerPage}
          totalRecords={logsData?.totalRecords}
          onSort={onSort}
        />
      )}
    </div>
  );
};
export default SmsAndMmsLogs;
