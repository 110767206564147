import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Container,
  Nav,
  Tab,
  ProgressBar,
  Modal,
  // Form,
  // Spinner,
} from "react-bootstrap";
import withRouter from "Common/withRouter";
import {
  getBrandListThunk,
  getUsecaseListThunk,
  getConnectivityPartnersListThunk,
  getUsecaseFromBrandListThunk,
  getTermsFromUsecaseListThunk,
  getTermsAndConditionsThunk,
  createCampaignThunk,
  msgFileUploadThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
// import Loader from "assets/images/spinner-dark.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { toast } from "react-toastify";
import { removeEmptyAndNullValues } from "helpers/common";
import Lightbox from "react-image-lightbox";
import { useSearchParams } from "react-router-dom";

const CreateCampaign = (props: any) => {
  document.title = "Signal House Portal Add Campaign";
  const dispatch = useDispatch<any>();
  const isDataProcessed = useRef(false);
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");

  const [activeTab, setactiveTab] = useState<number>(0);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [standardType, setStandardType] = useState<any>({});
  const [specialType, setSpecialType] = useState<any>([]);
  const [subUseCase, setSubUseCase] = useState<any>([]);
  const [minSubUsecases, SetMinSubUsecases] = useState<number>(0);
  const [maxSubUsecases, SetMaxSubUsecases] = useState<number>(0);
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [modal3, setModal3] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [resellerModal, setResellerModal] = useState<boolean>(false);
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [isOpenVideoLightBox, setIsOpenVideoLightBox] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [brandTemp, setBrandTemp] = useState<any>({});

  const selectEnums = createSelector(
    (state: any) => state.Campaigns,
    (state: any) => state.Enums,
    // (state: any) => state.Resellers,
    (
      campaigns,
      enums
      // resellers
    ) => ({
      loading2: campaigns.loading,
      loading5: campaigns.loading5,
      loading3: enums.loading,
      error2: campaigns.error,
      campaignUseCaseData: campaigns.campaignUseCaseData,
      campaignTerms: campaigns.campaignTerms,
      campaignTnC: campaigns.campaignTnC,
      usecaseObj: enums.usecaseObj,
      // resellersArr: resellers.resellersArr,
      // loading3: resellers.loading,
    })
  );

  const {
    loading2,
    loading5,
    loading3,
    campaignUseCaseData,
    campaignTerms,
    campaignTnC,
    usecaseObj,
    // resellersArr,
    // loading3,
  } = useSelector(selectEnums);

  const validation1: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: "",
      usecase: "",
      subUsecases: [],
    },
    validationSchema: Yup.object({
      brandId: Yup.string().required("Please Select Brand Name"),
      usecase: Yup.string().required("Please Select Use Case"),
      subUsecases: Yup.array()
        .min(minSubUsecases, `Select minimum ${minSubUsecases} subcases.`)
        .max(maxSubUsecases, `Select maximum ${maxSubUsecases} subcases.`),
    }),
    onSubmit: (values) => {
      values.usecase === "EMERGENCY"
        ? setModal3(true)
        : values.usecase === "CARRIER_EXEMPT"
        ? setModal2(true)
        : openModal1();
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      mnoIds: [],
    },
    validationSchema: Yup.object({
      mnoIds: Yup.array().of(Yup.string().required("mnoIds are required")),
    }),
    onSubmit: () => {
      setactiveTab(2);
    },
  });

  const validation3: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneNumbers: [],
      privacyPolicyLink: "",
      termsAndConditionsLink: "",
      description: "",
      messageFlow: "",
      messages: [],
      mmsMediaUuid: [],
      subscriberOptin: null,
      embeddedLink: null,
      subscriberOptout: null,
      embeddedPhone: null,
      subscriberHelp: null,
      ageGated: null,
      numberPool: null,
      termsAndConditions: null,
      directLending: null,
      // resellerId: "",
      optinKeywords: "",
      optinMessage: "",
      optoutKeywords: "STOP",
      optoutMessage: "",
      helpKeywords: "HELP",
      helpMessage: "",
    },
    validationSchema: Yup.object({
      privacyPolicyLink: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .notRequired(),
      termsAndConditionsLink: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .notRequired(),
      description: Yup.string()
        .min(40, "Campaign description must be at least 40 characters")
        .max(4096)
        .required("Please Enter Campaign Description"),
      messageFlow: Yup.string()
        .min(40, "Call to action must be at least 40 characters")
        .max(4096)
        .required("Please Enter Call to Action"),
      messages: Yup.array().of(
        Yup.string()
          .min(20, "message must be at least 20 characters")
          .max(1024)
          .required("Message is required")
      ),
      subscriberOptin: Yup.boolean().required("Select Any One"),
      optinKeywords: Yup.string().when(
        "subscriberOptin",
        ([subscriberOptin]) => {
          return !subscriberOptin
            ? Yup.string().max(255).notRequired()
            : Yup.string().max(255).required("Please Enter Keywords");
        }
      ),
      optinMessage: Yup.string().when(
        "subscriberOptin",
        ([subscriberOptin]) => {
          return !subscriberOptin
            ? Yup.string()
                .min(20, "message must be at least 20 characters")
                .max(320)
                .notRequired()
            : Yup.string()
                .min(20, "message must be at least 20 characters")
                .max(320)
                .required("Please Enter Message");
        }
      ),
      embeddedLink: Yup.boolean().required("Select Any One"),
      subscriberOptout: Yup.boolean().required("Select Any One"),
      optoutKeywords: Yup.string().when(
        "subscriberOptout",
        ([subscriberOptout]) => {
          return !subscriberOptout
            ? Yup.string().max(255).notRequired()
            : Yup.string().max(255).required("Please Enter Keywords");
        }
      ),
      optoutMessage: Yup.string().when(
        "subscriberOptout",
        ([subscriberOptout]) => {
          return !subscriberOptout
            ? Yup.string()
                .min(20, "message must be at least 20 characters")
                .max(320)
                .notRequired()
            : Yup.string()
                .min(20, "message must be at least 20 characters")
                .max(320)
                .required("Please Enter Message");
        }
      ),
      embeddedPhone: Yup.boolean().required("Select Any One"),
      subscriberHelp: Yup.boolean().required("Select Any One"),
      helpKeywords: Yup.string().when("subscriberHelp", ([subscriberHelp]) => {
        return !subscriberHelp
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      helpMessage: Yup.string().when("subscriberHelp", ([subscriberHelp]) => {
        return !subscriberHelp
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
      ageGated: Yup.boolean().required("Select Any One"),
      numberPool: Yup.boolean().required("Select Any One"),
      termsAndConditions: Yup.boolean().required("Select Any One"),
      directLending: Yup.boolean().required("Select Any One"),
    }),
    onSubmit: (values) => {
      const tempMsg: { [key: string]: string } = {};
      values?.messages?.forEach((dt, i) => {
        tempMsg[`sample${i + 1}`] = dt;
      });

      const tempPayload = {
        ...validation1?.values,
        ...validation2?.values,
        ...values,
        ...tempMsg,
        phoneNumbers:
          values?.phoneNumbers?.length > 0
            ? values?.phoneNumbers?.map((dt: any) => dt?.phoneNumber)
            : [],
        // resellerUid: null,
        // primaryDcaUid: "",
        // referenceId: `${Date.now()}`,
        autoRenewal: true,
        tag: [],
        attachments:
          validation3.values.mmsMediaUuid?.length > 0
            ? validation3.values.mmsMediaUuid?.map((dt: any) => dt?.path)
            : [],
      };
      delete tempPayload.messages;

      dispatch(
        createCampaignThunk(
          removeEmptyAndNullValues(tempPayload),
          props.router.navigate
        )
      );
    },
  });

  // const validation4: any = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     companyName: "",
  //     email: "",
  //     phone: "",
  //   },
  //   validationSchema: Yup.object({
  //     companyName: Yup.string().required("Please Enter Comapny Name"),
  //     email: Yup.string().email().required("Please Enter Email Id"),
  //     phone: Yup.string()
  //       .required("Please Enter Phone number")
  //       .matches(
  //         /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
  //         "Phone number must start with 1-4 digit country code followed by 7-15 digit phone number, numbers only"
  //       ),
  //   }),
  //   onSubmit: (values) => {
  //     const cb = () => {
  //       setResellerModal(false);
  //       validation4.resetForm();
  //     };
  //     dispatch(
  //       createResellerThunk(
  //         { ...values },
  //         cb
  //       )
  //     );
  //   },
  // });

  useEffect(() => {
    dispatch(getBrandListThunk(1, 500));
    dispatch(getUsecaseListThunk());
    dispatch(getConnectivityPartnersListThunk());
    // dispatch(getResellerListThunk());
    dispatch(getTermsAndConditionsThunk());
  }, []);

  useEffect(() => {
    if (Object.keys(usecaseObj)?.length > 0 && !isDataProcessed.current) {
      const temp1 = Object.fromEntries(
        Object.entries(usecaseObj).filter(
          ([key, value]: any) => value?.classification === "STANDARD"
        )
      );
      setStandardType(temp1);
      const temp2 = Object.fromEntries(
        Object.entries(usecaseObj).filter(
          ([key, value]: any) => value?.classification === "SPECIAL"
        )
      );
      setSpecialType(temp2);
      const temp3 = Object.keys(usecaseObj)?.filter(
        (dt) => usecaseObj?.[dt]?.validSubUsecase
      );
      setSubUseCase(temp3);
    }
  }, [usecaseObj]);

  useEffect(() => {
    if (Array.isArray(campaignUseCaseData) && !isDataProcessed.current) {
      let temp4: { [key: string]: any } = {};
      campaignUseCaseData.forEach(
        (dt: any) => (temp4 = { ...temp4, [dt?.usecase]: dt })
      );

      const temp5 = { ...standardType };
      Object.keys(temp5).forEach((element: any) => {
        temp5[element] = { ...temp5[element], ...temp4[element] };
      });
      setStandardType(temp5);

      const temp6 = { ...specialType };
      Object.keys(temp6).forEach((element: any) => {
        temp6[element] = { ...temp6[element], ...temp4[element] };
      });
      setSpecialType(temp6);
    }
  }, [campaignUseCaseData]);

  useEffect(() => {
    if (validation1.values.brandId) {
      dispatch(getUsecaseFromBrandListThunk(validation1.values.brandId));
    }
  }, [validation1.values.brandId]);

  const handleDeleteMsg = (ind: number) => {
    const temp = [...validation3.values.messages];
    temp.splice(ind, 1);
    validation3.setFieldValue("messages", [...temp]);
  };

  const handleDownloadImg = (ind: number) => {
    setImgLoading(true);
    const temp = [...selectedFiles];
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      let link = document.createElement("a");
      link.href = temp2;
      link.download = temp?.[ind]?.name || temp?.[ind]?.fileName || "img.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setImgLoading(false);
    };
    cb(temp?.[ind]);
  };

  const handleViewImg = (ind: number) => {
    setImgLoading(true);
    const temp = [...selectedFiles];
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      setPrevSrc(temp2);
      if (img?.type.startsWith("video/")) {
        setIsOpenVideoLightBox(true);
      } else if (img?.type === "application/pdf") {
        setIsOpenDocLightBox(true);
      } else if (
        img?.type === "text/plain" ||
        img?.type === "text/html" ||
        img?.type === "application/json"
      ) {
        setIsOpenDocLightBox(true);
      } else {
        setIsOpenImgLightBox(true);
      }
      setImgLoading(false);
    };
    cb(temp?.[ind]);
  };

  const handleDeleteImg = (ind: number) => {
    setImgLoading(true);
    const temp = [...selectedFiles];
    temp.splice(ind, 1);
    setselectedFiles(temp);
    validation3.setFieldValue("mmsMediaUuid", temp);
    setImgLoading(false);
  };

  const handleAcceptedFiles = (files: any, rejFiles: any) => {
    rejFiles?.length > 0 && console.log("rejFiles", rejFiles);
    rejFiles.forEach((file: any) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === "file-too-large") {
          toast.error(err.message || "Something went wrong");
        }

        if (err.code === "file-invalid-type") {
          toast.error(err.message || "Something went wrong");
        }
      });
    });

    if (files?.length > 0) {
      setIsLoading(true);
      const cb = (files: any) => {
        if (typeof files[0] === "object" && files[0] !== null) {
          const cb2 = (data: any) => {
            setselectedFiles((prev: any) => [...prev, files[0]]);
            validation3.setFieldValue("mmsMediaUuid", [
              ...validation3.values.mmsMediaUuid,
              ...data,
            ]);
          };

          const formData = new FormData();
          formData.append("cspMedia", files[0]);
          dispatch(msgFileUploadThunk(formData, true, cb2)).then(() => {
            setIsLoading(false);
          });
        }
      };
      cb(files);
    }
  };

  const openModal1 = () => {
    setactiveTab(1);
    setModal1(true);
    validation1.values.brandId &&
      validation1.values.usecase &&
      dispatch(
        getTermsFromUsecaseListThunk(
          validation1.values.brandId,
          validation1.values.usecase
        )
      );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isOpenImgLightBox && (
          <Lightbox
            enableZoom={false}
            mainSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpenImgLightBox(false);
              setPrevSrc("");
            }}
          />
        )}
        {(isOpenDocLightBox || isOpenVideoLightBox) && (
          <div
            className="position-fixed top-0 end-0 bottom-0 start-0"
            style={{
              zIndex: 1000,
              // backgroundColor: "rgba(0,0,0,0.5)",
              backgroundColor: "white",
            }}
          >
            <div
              className="position-absolute text-white cursor-pointer"
              style={{
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                padding: "5px 10px",
                right: 10,
                top: 40,
              }}
            >
              <i
                className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
                onClick={() => {
                  setIsOpenDocLightBox(false);
                  setIsOpenVideoLightBox(false);
                  setPrevSrc("");
                }}
              />
            </div>
            {isOpenVideoLightBox ? (
              <video
                // playbackRate={2}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
                height="100%"
                width="100%"
              >
                <source src={prevSrc} type={`video/mp4`} />
              </video>
            ) : (
              <iframe
                id="iframe"
                title={prevSrc}
                src={prevSrc}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            )}
          </div>
        )}
        <Container fluid>
          <BreadCrumb
            title="Create Campaign"
            isBack={true}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
          />
          <div className="position-relative">
            <Card className="wrapper">
              {/* {loading2 ? (
                <div className={``}>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                    alt="loading"
                  />
                </div>
              ) : ( */}
              <Card.Body className="">
                <Tab.Container activeKey={activeTab}>
                  <div id="custom-progress-bar" className="progress-nav mb-5">
                    <div>
                      <ProgressBar
                        now={activeTab * 50}
                        style={{ height: "1px" }}
                      ></ProgressBar>
                    </div>

                    <Nav
                      as="ul"
                      variant="pills"
                      className="progress-bar-tab custom-nav"
                    >
                      <Nav.Item as="li">
                        <Nav.Link
                          as="button"
                          eventKey="0"
                          onClick={() => setactiveTab(0)}
                          className="rounded-pill"
                        >
                          <span>1</span>
                          <div className="position-absolute progress-label start-0">
                            Use Case Selection
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link
                          as="button"
                          eventKey="1"
                          onClick={() => {
                            validation1.values.brandId &&
                            validation1.values.usecase
                              ? setactiveTab(1)
                              : validation1.handleSubmit();
                          }}
                          className="rounded-pill"
                        >
                          <span>2</span>
                          <div className="position-absolute progress-label middleLabel">
                            Carrier Terms Preview
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link
                          as="button"
                          eventKey="2"
                          onClick={() => {
                            validation1.values.brandId &&
                            validation1.values.usecase
                              ? setactiveTab(2)
                              : validation1.handleSubmit();
                          }}
                          className="rounded-pill"
                        >
                          <span>3</span>
                          <div className="position-absolute progress-label end-0">
                            Campaign Details
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <Tab.Content>
                    <Step1
                      loading3={loading3}
                      loading5={loading5}
                      validation1={validation1}
                      standardType={standardType}
                      specialType={specialType}
                      usecaseObj={usecaseObj}
                      subUseCase={subUseCase}
                      SetMinSubUsecases={SetMinSubUsecases}
                      SetMaxSubUsecases={SetMaxSubUsecases}
                      brandTemp={brandTemp}
                      setBrandTemp={setBrandTemp}
                    />
                    <Step2
                      loading2={loading2}
                      validation2={validation2}
                      campaignTerms={campaignTerms}
                      setactiveTab={setactiveTab}
                    />
                    <Step3
                      validation3={validation3}
                      validation1={validation1}
                      handleDeleteMsg={handleDeleteMsg}
                      imgLoading={imgLoading}
                      handleDeleteImg={handleDeleteImg}
                      handleDownloadImg={handleDownloadImg}
                      handleViewImg={handleViewImg}
                      handleAcceptedFiles={handleAcceptedFiles}
                      selectedFiles={selectedFiles}
                      // resellersArr={resellersArr}
                      campaignTnC={campaignTnC}
                      setactiveTab={setactiveTab}
                      // setResellerModal={setResellerModal}
                      isLoading={isLoading}
                      campaignTerms={campaignTerms}
                      brandTemp={brandTemp}
                      loading2={loading2}
                    />
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
              {/* )} */}
            </Card>
          </div>
        </Container>
        <Modal centered show={modal1} onHide={() => setModal1(false)}>
          <Modal.Body className="p-4 text-center fs-5">
            <div>
              The terms displayed in this page may be subject to change at the{" "}
              <b>sole discretion of the MNO!</b>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-end">
              <Button
                className="btn btn-outline-primary"
                onClick={() => setModal1(false)}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal centered show={modal2} onHide={() => setModal2(false)}>
          <Modal.Body className="p-4 text-center fs-5">
            <div>
              The Use Case you selected is only intended for those CSPs that
              have an <b>existing exemption agreement</b> with MNOs. Proceeding
              without having an existing agreement may result in the Campaign
              being <b>rejected.</b>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-end">
              <Button
                className="btn btn-outline-primary"
                onClick={() => setModal2(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                id="add-btn"
                onClick={() => {
                  setModal2(false);
                  openModal1();
                }}
              >
                Proceed
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal centered show={modal3} onHide={() => setModal3(false)}>
          <Modal.Body className="p-4 text-center fs-5">
            <div>
              You have selected <b>"emergency"</b> as a use case.
              <br />
              10DLC messages are not guaranteed to reach customers with either:
              <br />
              <b>A)</b> limited rate plans (SMS/MMS caps), or
              <br />
              <b>B)</b> message blocking enabled.
              <br />
              If your messages are highly critical, you may want to explore{" "}
              <b>Free-to-end user Short Code messages,</b> and if you are
              providing public safety alerts, you may want to explore using the{" "}
              <b>WEA program.</b>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-end">
              <Button
                className="btn btn-outline-primary"
                onClick={() => setModal3(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                id="add-btn"
                onClick={() => {
                  setModal3(false);
                  openModal1();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* <Modal
          centered
          show={resellerModal}
          onHide={() => {
            setResellerModal(false);
            validation4.resetForm();
          }}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation4.handleSubmit();
            }}
          >
            <Modal.Header closeButton>
              <h4 className="mb-0">Add Reseller Details</h4>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div>
                <div className="mb-3">
                  <Form.Label htmlFor="companyName">
                    Legal Company Name <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="companyName"
                    name="companyName"
                    onChange={validation4.handleChange}
                    onBlur={validation4.handleBlur}
                    value={validation4.values.companyName || ""}
                    isInvalid={
                      validation4.touched.companyName &&
                      validation4.errors.companyName
                        ? true
                        : false
                    }
                  />
                  {validation4.touched.companyName &&
                  validation4.errors.companyName ? (
                    <Form.Control.Feedback type="invalid">
                      {validation4.errors.companyName}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Form.Label htmlFor="email">
                    Email Address <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    onChange={validation4.handleChange}
                    onBlur={validation4.handleBlur}
                    value={validation4.values.email || ""}
                    isInvalid={
                      validation4.touched.email && validation4.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation4.touched.email && validation4.errors.email ? (
                    <Form.Control.Feedback type="invalid">
                      {validation4.errors.email}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Form.Label htmlFor="phone">
                    Phone Number <span className="text-primary">*</span>
                  </Form.Label>
                  <br />
                  <Form.Control
                    type="number"
                    id="phone"
                    name="phone"
                    placeholder="Enter phone number"
                    onChange={validation4.handleChange}
                    onBlur={validation4.handleBlur}
                    value={validation4.values.phone || ""}
                    isInvalid={
                      validation4.touched.phone && validation4.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation4.touched.phone && validation4.errors.phone ? (
                    <Form.Control.Feedback type="invalid">
                      {validation4.errors.phone}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setResellerModal(false);
                    validation4.resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id="add-btn"
                  type="submit"
                  variant="primary"
                  disabled={!validation4?.dirty || loading3}
                >
                  {loading3 && <Spinner size="sm" animation="border" />} Add
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateCampaign);
