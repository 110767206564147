import React, { useEffect, useState, useRef } from "react";
import { Button, OverlayTrigger, Popover, Modal } from "react-bootstrap";
import Datatable from "../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import {
  capitalizeString,
  dateFormat,
  formatBytes,
  getSortIcon,
} from "helpers/common";
import Tooltip from "rc-tooltip";
import { getFileApi } from "helpers/apiroute_helper";
import withRouter from "Common/withRouter";
import {
  BsFileEarmarkPdf,
  BsFileEarmarkWord,
  BsFileEarmarkPlay,
  BsFileEarmarkMusic,
  BsFileEarmark,
  BsImage,
} from "react-icons/bs";

interface AttachmentType {
  id: string;
  fileSize: number;
}

interface RowType {
  _id: string;
  type: string;
  attachments?: AttachmentType[];
  mediaURL?: string[];
}

interface URLsState {
  [key: string]: File[];
}

interface AttachmentsPopoverProps {
  row: RowType;
}

// Cache object outside the component to persist across renders
const attachmentsCache: {
  [key: string]: {
    attachments?: any;
    urls?: File[];
  };
} = {};

const AttachmentsPopover: React.FC<AttachmentsPopoverProps> = ({ row }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<any>({});
  const [urls, setUrls] = useState<URLsState>({});
  const [hoverId, setHoverId] = useState<string>("");
  const hoverTimeoutRef = useRef<NodeJS.Timeout>();
  const isLoadingRef = useRef<boolean>(false);

  const loadAttachments = async (): Promise<void> => {
    // Return if no row ID or if already loading
    if (!row._id || isLoadingRef.current) return;

    // Check cache first
    const cachedData = attachmentsCache[row._id];
    if (cachedData) {
      if (cachedData.attachments) {
        setAttachments((prev: any) => ({
          ...prev,
          [row._id]: cachedData.attachments,
        }));
      }
      if (cachedData.urls) {
        setUrls((prev) => ({ ...prev, [row._id]: cachedData.urls! }));
      }
      return;
    }

    // Set loading ref to prevent multiple simultaneous loads
    isLoadingRef.current = true;

    const getMediaFile = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const promises = row?.attachments?.map(async (dt: AttachmentType) => {
          return await getFileApi(dt?.id || dt);
        });
        const results = await Promise.all(promises || []);
        const urlObjects = results?.map((dt: any) => dt);

        setAttachments((prev: any) => ({
          ...prev,
          [row._id]: urlObjects,
        }));

        // Cache the results
        attachmentsCache[row._id] = {
          ...attachmentsCache[row._id],
          attachments: urlObjects,
        };
      } catch (error) {
        console.error("Error loading attachments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const getFileFromUrl = async (url: string): Promise<File> => {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      return new File([blob], url.substring(url.lastIndexOf("/") + 1), {
        type: blob.type,
      });
    };

    const loadMediaUrls = async (): Promise<void> => {
      setIsLoading2(true);
      try {
        const promises = row?.mediaURL?.map(async (url: string) => {
          return await getFileFromUrl(url);
        });
        const results = await Promise.all(promises || []);

        setUrls((prev) => ({
          ...prev,
          [row._id]: results,
        }));

        // Cache the results
        attachmentsCache[row._id] = {
          ...attachmentsCache[row._id],
          urls: results,
        };
      } catch (error) {
        console.error("Error loading media URLs:", error);
      } finally {
        setIsLoading2(false);
      }
    };

    try {
      if (row.attachments?.length && !attachments[row._id]) {
        await getMediaFile();
      }
      if (row.mediaURL?.length && !urls[row._id]) {
        await loadMediaUrls();
      }
    } finally {
      isLoadingRef.current = false;
    }
  };

  const handleMouseEnter = (): void => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setShowPopover(true);
    hoverTimeoutRef.current = setTimeout(() => {
      loadAttachments();
    }, 500);
  };

  const handleMouseLeave = (): void => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setShowPopover(false);
  };

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <OverlayTrigger
        show={showPopover}
        placement="top"
        overlay={
          <Popover
            id="popover-positioned-top"
            className="media-popover"
            style={{
              maxWidth: "400px",
              width: "auto",
            }}
          >
            <Popover.Body className="p-3">
              <div className="media-container">
                {/* Media URLs Section */}
                {(row?.mediaURL?.length ?? 0) > 0 && (
                  <div className="media-section">
                    {isLoading2 ? (
                      <div className="loader-wrapper">
                        <img
                          src={Loader}
                          alt="loading"
                          className="loader-image"
                        />
                      </div>
                    ) : urls[row._id] ? (
                      <div className="media-grid">
                        {row.mediaURL?.map((url: string, i: number) => (
                          <div
                            key={`url-${i}`}
                            className="media-item"
                            onMouseEnter={() => setHoverId(`url-${i}`)}
                            onMouseLeave={() => setHoverId("")}
                          >
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="media-link"
                            >
                              {(() => {
                                if (
                                  urls[row._id]?.[i]?.type?.startsWith("image/")
                                ) {
                                  return (
                                    <img
                                      src={url}
                                      alt={`Media ${i + 1}`}
                                      className="media-image"
                                    />
                                  );
                                }

                                const getFileIcon = () => {
                                  const contentType =
                                    urls[row._id]?.[i]?.type?.toLowerCase() ||
                                    "";
                                  if (contentType.includes("pdf")) {
                                    return <BsFileEarmarkPdf size={30} />;
                                  } else if (
                                    contentType.includes("word") ||
                                    contentType.includes("document")
                                  ) {
                                    return <BsFileEarmarkWord size={30} />;
                                  } else if (contentType.includes("video")) {
                                    return <BsFileEarmarkPlay size={30} />;
                                  } else if (contentType.includes("audio")) {
                                    return <BsFileEarmarkMusic size={30} />;
                                  } else if (contentType.includes("image")) {
                                    return <BsImage size={30} />;
                                  }
                                  return <BsFileEarmark size={30} />;
                                };

                                return (
                                  <div
                                    className="file-preview d-flex flex-column align-items-center justify-content-center"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      backgroundColor: "#f8f9fa",
                                      borderRadius: "6px",
                                      padding: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {getFileIcon()}
                                  </div>
                                );
                              })()}
                              {hoverId === `url-${i}` && (
                                <div className="media-overlay">
                                  <span className="file-size">
                                    {formatBytes(urls[row._id]?.[i]?.size || 0)}
                                  </span>
                                  <i className="bi bi-download download-icon"></i>
                                </div>
                              )}
                            </a>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="placeholder-content">
                        Hover to load media...
                      </div>
                    )}
                  </div>
                )}

                {/* Attachments Section */}
                {(row?.attachments?.length ?? 0) > 0 && (
                  <div className="media-section">
                    {isLoading ? (
                      <div className="loader-wrapper">
                        <img
                          src={Loader}
                          alt="loading"
                          className="loader-image"
                        />
                      </div>
                    ) : attachments[row._id] ? (
                      <div className="media-grid">
                        {row.attachments?.map(
                          (att: AttachmentType, i: number) => (
                            <div
                              key={`att-${i}`}
                              className="media-item"
                              onMouseEnter={() => setHoverId(`att-${i}`)}
                              onMouseLeave={() => setHoverId("")}
                            >
                              <a
                                href={
                                  attachments[row._id]?.[i]
                                    ? URL.createObjectURL(
                                        attachments[row._id]?.[i]
                                      )
                                    : "#"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                              >
                                {(() => {
                                  if (
                                    attachments[row._id]?.[i]?.type?.startsWith(
                                      "image/"
                                    )
                                  ) {
                                    return (
                                      <img
                                        src={
                                          attachments[row._id]?.[i]
                                            ? URL.createObjectURL(
                                                attachments[row._id]?.[i]
                                              )
                                            : ""
                                        }
                                        alt={`Attachment ${i + 1}`}
                                        className="media-image"
                                      />
                                    );
                                  }

                                  const getFileIcon = () => {
                                    const contentType =
                                      attachments[row._id]?.[
                                        i
                                      ]?.type?.toLowerCase() || "";
                                    if (contentType.includes("pdf")) {
                                      return <BsFileEarmarkPdf size={30} />;
                                    } else if (
                                      contentType.includes("word") ||
                                      contentType.includes("document")
                                    ) {
                                      return <BsFileEarmarkWord size={30} />;
                                    } else if (contentType.includes("video")) {
                                      return <BsFileEarmarkPlay size={30} />;
                                    } else if (contentType.includes("audio")) {
                                      return <BsFileEarmarkMusic size={30} />;
                                    } else if (contentType.includes("image")) {
                                      return <BsImage size={30} />;
                                    }
                                    return <BsFileEarmark size={30} />;
                                  };

                                  return (
                                    <div
                                      className="file-preview d-flex flex-column align-items-center justify-content-center"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "#f8f9fa",
                                        borderRadius: "6px",
                                        padding: "8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {getFileIcon()}
                                    </div>
                                  );
                                })()}
                                {hoverId === `att-${i}` && (
                                  <div className="media-overlay">
                                    <span className="file-size">
                                      {formatBytes(
                                        attachments[row._id]?.[i].size || 0
                                      )}
                                    </span>
                                    <i className="bi bi-download download-icon"></i>
                                  </div>
                                )}
                              </a>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="placeholder-content">
                        Hover to load attachments...
                      </div>
                    )}
                  </div>
                )}

                {/* No Media Available Message */}
                {!row.mediaURL?.length && !row.attachments?.length && (
                  <div className="no-media">No Media Available</div>
                )}
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="gallery-icon-wrapper position-relative d-inline-block">
          <i className="bi bi-images align-middle text-secondary fs-4">
            {(row.mediaURL?.length || 0) + (row.attachments?.length || 0) >
              0 && (
              <span
                className="media-count-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary"
                style={{
                  fontSize: "0.65rem",
                  padding: "0.25em 0.45em",
                  transform: "translate(25%, -25%)",
                  border: "2px solid #fff",
                }}
              >
                {(row.mediaURL?.length || 0) + (row.attachments?.length || 0) >
                9
                  ? "9+"
                  : (row.mediaURL?.length || 0) +
                    (row.attachments?.length || 0)}
              </span>
            )}
          </i>
        </div>
      </OverlayTrigger>
    </div>
  );
};

const ShortLinksPopover = ({ row }: any) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleMouseEnter = (): void => {
    setShowPopover(true);
  };

  const handleMouseLeave = (): void => {
    setShowPopover(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <OverlayTrigger
        show={showPopover}
        placement="top"
        overlay={
          <Popover
            id="popover-positioned-top"
            className="media-popover"
            style={{
              maxWidth: "400px",
              width: "auto",
            }}
          >
            <Popover.Body className="p-3">
              <ul
                className="vstack gap-2 list-unstyled mb-0"
                style={{ maxHeight: 200, overflow: "auto" }}
              >
                {row.clickLog?.map((dt: any, i: number) => (
                  <li key={i}>
                    <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <a
                            href={dt?.shortLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mb-0 text-break"
                          >
                            {dt?.shortLink}
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="gallery-icon-wrapper position-relative d-inline-block">
          <i className="bi bi-link-45deg align-middle text-secondary fs-4">
            <span
              className="media-count-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary"
              style={{
                fontSize: "0.65rem",
                padding: "0.25em 0.45em",
                transform: "translate(25%, -25%)",
                border: "2px solid #fff",
              }}
            >
              {(row.clickLog?.length || 0) > 9
                ? "9+"
                : row.clickLog?.length || 0}
            </span>
          </i>
        </div>
      </OverlayTrigger>
    </div>
  );
};

const MessageTable = (props: any) => {
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      fieldName: "CreatedDate",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "CreatedDate" ? "hideIcon" : ""
          }`}
        >
          Date and Time{" "}
          <span className="table-header-icon">
            {getSortIcon("CreatedDate", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { CreatedDate: string }) => row.CreatedDate,
      cell: (row: { CreatedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.CreatedDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      minWidth: "210px",
      sortable: true,
    },
    {
      fieldName: "direction",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "direction" ? "hideIcon" : ""
          }`}
        >
          Direction{" "}
          <span className="table-header-icon">
            {getSortIcon("direction", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (cell: { direction: any }) => cell.direction,
      cell: (cell: { direction: any }) => {
        switch (cell.direction?.toLowerCase()) {
          case "mt":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-info text-info"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "inbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "outbound-api":
          case "outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-api", ""))}
              </span>
            );
          case "group-outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-", " "))}
              </span>
            );
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-danger text-danger"
              >
                {capitalizeString(cell.direction) || "N/A"}
              </span>
            );
        }
      },
      minWidth: "140px",
      sortable: true,
    },
    {
      fieldName: "type",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "type" ? "hideIcon" : ""
          }`}
        >
          Type{" "}
          <span className="table-header-icon">
            {getSortIcon("type", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { type: string }) => row.type,
      cell: (row: { type: string }) => {
        switch (row.type) {
          case "SMS":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-black text-black"
              >
                {row.type}
              </span>
            );
          case "MMS":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary-subtle text-muted"
              >
                {row.type}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {row.type}
              </span>
            );
        }
      },
      minWidth: "80px",
      sortable: true,
    },
    {
      fieldName: "sendersPhoneNumber",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "sendersPhoneNumber" ? "hideIcon" : ""
          }`}
        >
          From{" "}
          <span className="table-header-icon">
            {getSortIcon(
              "sendersPhoneNumber",
              props.sortColumn,
              props.isSortAsc
            )}
          </span>
        </span>
      ),
      selector: (row: { sendersPhoneNumber: number | string }) =>
        row.sendersPhoneNumber,
      minWidth: "130px",
      sortable: true,
    },
    {
      fieldName: "recieversPhoneNumber",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "recieversPhoneNumber" ? "hideIcon" : ""
          }`}
        >
          To{" "}
          <span className="table-header-icon">
            {getSortIcon(
              "recieversPhoneNumber",
              props.sortColumn,
              props.isSortAsc
            )}
          </span>
        </span>
      ),
      selector: (row: { recieversPhoneNumber?: number | string }) =>
        row.recieversPhoneNumber,
      cell: (row: { recieversPhoneNumber?: number | string }) => {
        let phoneNumbers = row?.recieversPhoneNumber?.toString();
        let phoneArray = phoneNumbers
          ? phoneNumbers.split(",").map((num) => num.trim())
          : [];

        const maxInitialDisplay = 1;
        const hasMoreNumbers = phoneArray.length > maxInitialDisplay;

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {phoneArray.slice(0, maxInitialDisplay).map((num, index) => (
              <span key={index}>{num}</span>
            ))}
            {hasMoreNumbers && (
              <>
                <span
                  className="btn btn-link p-0"
                  style={{
                    fontSize: "0.85rem",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  and {phoneArray.length - 1} more
                </span>
                {showModal && (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.09)",
                        zIndex: 1040,
                      }}
                      onClick={() => setShowModal(false)}
                    />
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      backdrop={false}
                      style={{ zIndex: 1050 }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Phone Numbers</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {phoneArray.map((num, index) => (
                          <div key={index}>{num}</div>
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
      minWidth: "130px",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Body</span>,
      selector: (row: { messageBody: string }) => row.messageBody,
      cell: (row: { messageBody: string }) => (
        <Tooltip
          placement="top"
          overlayClassName={row.messageBody.length > 1000 ? "largeTooltip" : ""}
          overlay={
            <p className="mb-0" style={{ wordBreak: "break-word" }}>
              {row.messageBody}
            </p>
          }
        >
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
        </Tooltip>
      ),
      minWidth: "50px",
    },
    {
      fieldName: "attachments",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "attachments" ? "hideIcon" : ""
          }`}
        >
          Attachments{" "}
          <span className="table-header-icon">
            {getSortIcon("attachments", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { mediaURL: any; attachments: any }) =>
        row.attachments || row.mediaURL,
      cell: (row: {
        type: string;
        mediaURL: any;
        attachments: any;
        _id: string;
      }) => (row.type === "MMS" ? <AttachmentsPopover row={row} /> : "-"),
      minWidth: "150px",
      sortable: true,
    },
    {
      fieldName: "clickLog",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "clickLog" ? "hideIcon" : ""
          }`}
        >
          Short Urls{" "}
          <span className="table-header-icon">
            {getSortIcon("clickLog", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { clickLog: any }) => row.clickLog || [],
      cell: (row: { clickLog: any }) =>
        row?.clickLog?.length > 0 ? <ShortLinksPopover row={row} /> : "-",
      minWidth: "130px",
      sortable: true,
    },
    {
      fieldName: "clickLog",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "clickLog" ? "hideIcon" : ""
          }`}
        >
          Clickthrough{" "}
          <span className="table-header-icon">
            {getSortIcon("clickLog", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { clickLog: any[] }) => row.clickLog || [],
      cell: (row: { clickLog: any[] }) => {
        const hasClicks = row.clickLog?.some((link) => link.clicked);
        return row.clickLog?.length > 0 ? (
          <span
            className={`badge  ${hasClicks ? "bg-secondary" : "bg-primary"}`}
          >
            {hasClicks ? "Yes" : "No"}
          </span>
        ) : (
          "-"
        );
      },
      minWidth: "150px",
      sortable: true,
    },
    {
      fieldName: "segments",
      name: (
        <span
          className={`font-weight-bold fs-sm d-flex align-items-center ${
            props.sortColumn === "segments" ? "hideIcon" : ""
          }`}
        >
          Segments{" "}
          <Tooltip
            placement="bottomRight"
            overlay="Segments are calculated when a message is Sent. If a message fails prior to entering the “Sent” state, the message segments are not calculated."
          >
            <i className="ri-information-line text-secondary fs-2xl ms-2" />
          </Tooltip>{" "}
          <span className="table-header-icon">
            {getSortIcon("segments", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { segments?: string }) => row?.segments || "-",
      minWidth: "150px",
      sortable: true,
    },
    {
      fieldName: "latestStatus",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            props.sortColumn === "latestStatus" ? "hideIcon" : ""
          }`}
        >
          Status{" "}
          <span className="table-header-icon">
            {getSortIcon("latestStatus", props.sortColumn, props.isSortAsc)}
          </span>
        </span>
      ),
      selector: (cell: { latestStatus: any }) => cell.latestStatus,
      cell: (cell: { latestStatus: any }) => {
        switch (cell.latestStatus?.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "dequeued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#5c5c5c" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "created":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#a8a5a5" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "delivered":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "sent":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-black"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-black p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "mo_message_received":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                <b data-tag="allowRowEvents">
                  {capitalizeString(
                    cell.latestStatus.replace("mo_message_", "")
                  )}
                </b>
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString("failed")}
              </span>
            );
        }
      },
      minWidth: "130px",
      sortable: true,
    },
  ];

  return <Datatable {...props} columns={columns} />;
};
export default withRouter(MessageTable);
