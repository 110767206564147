import { getFileApi } from "helpers/apiroute_helper";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import {
  BsExclamationCircle,
  BsFileEarmarkPdf,
  BsFileEarmarkWord,
  BsFileEarmarkPlay,
  BsFileEarmarkMusic,
  BsFileEarmark,
  BsImage,
} from "react-icons/bs";

interface PreviewState {
  url: string | null;
  contentType: string | null;
  loading: boolean;
  error: string | null;
}

const AttachmentMedia: React.FC<{ attachment: string }> = React.memo(
  ({ attachment }) => {
    const [preview, setPreview] = useState<PreviewState>({
      url: null,
      contentType: null,
      loading: true,
      error: null,
    });

    useEffect(() => {
      const fetchPreview = async () => {
        try {
          if (attachment) {
            // For attachment paths, first get the public URL
            const response: any = await getFileApi(attachment);
            setPreview({
              url: URL.createObjectURL(
                new Blob([response], { type: response?.type })
              ),
              contentType: response?.type || "application/octet-stream",
              loading: false,
              error: null,
            });
          }
        } catch (error: any) {
          console.error("Preview fetch error:", error);
          setPreview((prev) => ({
            ...prev,
            loading: false,
            error: "Failed to load preview",
          }));
        }
      };

      // Only fetch if attachment URL changes
      if (attachment) {
        fetchPreview();
      }
    }, [attachment]); // Only depend on url and type

    const handlePreviewClick = () => {
      if (preview.url) {
        window.open(preview.url, "_blank");
      }
    };

    if (preview.loading) {
      return (
        <div
          className="preview-placeholder  d-flex align-items-center justify-content-center"
          style={{
            width: 80,
            height: 80,
            backgroundColor: "#f8f9fa",
            borderRadius: "6px",
            color: "black",
          }}
        >
          <Spinner className="text-primary" animation="border" size="sm" />
        </div>
      );
    }

    if (preview.error) {
      return (
        <div
          className="preview-error d-flex align-items-center justify-content-center"
          style={{
            width: 80,
            height: 80,
            backgroundColor: "#f8f9fa",
            borderRadius: "6px",
          }}
        >
          <BsExclamationCircle size={24} className="text-danger" />
        </div>
      );
    }

    // Handle different content types
    if (preview.contentType?.startsWith("image/")) {
      return (
        <img
          src={preview.url || ""}
          alt="Preview"
          style={{
            width: 80,
            height: 80,
            objectFit: "cover",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          onClick={handlePreviewClick}
          onError={() => {
            setPreview((prev) => ({
              ...prev,
              error: "Failed to load image",
            }));
          }}
        />
      );
    }

    // For non-image files, show an icon based on content type
    const getFileIcon = () => {
      const contentType = preview.contentType?.toLowerCase() || "";
      if (contentType.includes("pdf")) {
        return <BsFileEarmarkPdf size={30} />;
      } else if (
        contentType.includes("word") ||
        contentType.includes("document")
      ) {
        return <BsFileEarmarkWord size={30} />;
      } else if (contentType.includes("video")) {
        return <BsFileEarmarkPlay size={30} />;
      } else if (contentType.includes("audio")) {
        return <BsFileEarmarkMusic size={30} />;
      } else if (contentType.includes("image")) {
        return <BsImage size={30} />;
      }
      return <BsFileEarmark size={30} />;
    };

    return (
      <div
        className="file-preview d-flex flex-column align-items-center justify-content-center"
        style={{
          width: 80,
          height: 80,
          backgroundColor: "#f8f9fa",
          borderRadius: "6px",
          padding: "8px",
          cursor: "pointer",
        }}
        onClick={handlePreviewClick}
      >
        {getFileIcon()}
        <span
          className="text-truncate mt-2"
          style={{ fontSize: "10px", maxWidth: "100%" }}
        >
          {attachment || "File"}
        </span>
      </div>
    );
  },
  // Custom comparison function for memo
  (prevProps, nextProps) => {
    return prevProps.attachment === nextProps.attachment;
  }
);

export default AttachmentMedia;
