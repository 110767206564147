import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Container,
  Form,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  createPortNumberRequestThunk,
  fileUploadThunk,
  openModal,
} from "slices/thunk";
import * as Yup from "yup";
// import NPR1 from "./NPR1";
import NPR2 from "./NPR2";
import NPR4 from "./NPR4";
import NPR3 from "./NPR3";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";
import { getCurrentDateTime, removeEmptyAndNullValues } from "helpers/common";
import logoDark from "assets/images/logo-dark.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FocusError } from "focus-formik-error";
import { useSearchParams } from "react-router-dom";
import { subgroupListApi } from "helpers/apiroute_helper";

const groupArray = (array: any, size: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const NewPortRequest = (props: any) => {
  document.title = "Signal House Portal New Port Request";

  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const brandId = searchParams.get("brandId");
  const campaignId = searchParams.get("campaignId");

  const selectEnums = createSelector(
    (state: any) => state.Ports,
    (state: any) => state.AuthUser,
    (state: any) => state.Groups,
    (ports, authUser, groups) => ({
      loading: ports.loading,
      user: authUser.authUser,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, user, groupDetails } = useSelector(selectEnums);

  const [subgroupTemp, setSubgroupTemp] = useState<any>([]);
  const [diffThanOwner, setDiffThanOwner] = useState<any>(false);
  const [accordionState1, setAccordionState1] = useState<any>("0");
  const [accordionState2, setAccordionState2] = useState<any>(null);
  const [accordionState3, setAccordionState3] = useState<any>("0");
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      numbersPortability: "",
      portingRequestType: "Personal",
      businessName: "",
      portingService: "Messaging Services",
      ownersName: `${user?.firstName || ""} ${user?.lastName || ""}` || "",
      ownersEmail: user?.emailId || "",
      authorizedPersonsName:
        `${user?.firstName || ""} ${user?.lastName || ""}` || "",
      phoneNumberToBePorted: [],
      phoneNumber: "",
      ownersStreetAddress: user?.billingAddress?.addressLine1 || "",
      ownersExtendedAddress: user?.billingAddress?.addressLine2 || "",
      ownersCity: user?.billingAddress?.city || "",
      ownersState: user?.billingAddress?.state || "",
      ownersCountry: user?.billingAddress?.country || "",
      ownersPostalCode: user?.billingAddress?.postalCode || "",
      searchAddress: "",
      serviceAddress: user?.billingAddress?.addressLine1 || "",
      serviceExtendedAddress: user?.billingAddress?.addressLine2 || "",
      serviceCity: user?.billingAddress?.city || "",
      serviceState: user?.billingAddress?.state || "",
      serviceCountry: user?.billingAddress?.country || "",
      servicePostalCode: user?.billingAddress?.postalCode || "",
      cellNumberOrLandline: "Landline/VOIP",
      accountNumber: "",
      portNoAssociatedWithAcc: "Yes",
      pinOrPasscode: "",
      groupId: groupDetails?.records?.[0]?.group_id || "",
      subGroupId: subGroupId ? [subGroupId] : [],
      brandId: brandId || "",
      campaignId: campaignId || "",
      activationDate: "",
      howToActivate: "Automatically",
      fastPort: "Yes",
      comments: "",
      signature: {},
      letterOfAuthorization: "",
      billingStatement: "",
    },
    validationSchema: Yup.object({
      portingRequestType: Yup.string().required("Select Any One"),
      businessName: Yup.string().when(
        "portingRequestType",
        ([portingRequestType]) => {
          return portingRequestType !== "Business"
            ? Yup.string().notRequired()
            : Yup.string().required("Business Name is Required");
        }
      ),
      ownersName: Yup.string().required("Owner Name is Required"),
      ownersEmail: Yup.string().required("Email Address is Required"),
      authorizedPersonsName: Yup.string().required(
        "Authorized Name is Required"
      ),
      phoneNumber: Yup.string().when("portingService", () => {
        return !diffThanOwner
          ? Yup.string()
              .notRequired()
              .matches(
                /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
                "Phone number must start with 1-4 digit country code followed by 7-15 digit phone number, numbers only"
              )
          : Yup.string()
              .required("Please Enter Phone number")
              .matches(
                /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
                "Phone number must start with 1-4 digit country code followed by 7-15 digit phone number, numbers only"
              );
      }),
      phoneNumberToBePorted: Yup.array()
        .min(1, "At least one number must be added")
        .required("Enter Phone Number"),
      ownersStreetAddress: Yup.string().required("Address is Required"),
      ownersCity: Yup.string().required("Please Select City"),
      ownersState: Yup.string().required("Please Select State"),
      ownersCountry: Yup.string().required("Please Select Country"),
      ownersPostalCode: Yup.string().required("Postal Code is Required"),
      serviceAddress: Yup.string().required("Address is Required"),
      serviceCity: Yup.string().required("Please Select City"),
      serviceState: Yup.string().required("Please Select State"),
      serviceCountry: Yup.string().required("Please Select Country"),
      servicePostalCode: Yup.string().required("Postal Code is Required"),
      cellNumberOrLandline: Yup.string().required("Select Any One"),
      portNoAssociatedWithAcc: Yup.string().required("Select Any One"),
      groupId: Yup.string().required("Please Select Group"),
      // subGroupId: Yup.array()
      //   .min(1, "At least one subgroup must be selected")
      //   .required("Please Select Subgroups"),
      // brandId: Yup.string().required("Please Select Brand"),
      // campaignId: Yup.string().required("Please Select Campaign"),
      pinOrPasscode: Yup.string().when("portingService", ([portingService]) => {
        return portingService !== "Entire Service"
          ? Yup.string().notRequired()
          : Yup.string().required("PIN / Passcode is Required");
      }),
      accountNumber: Yup.string().when(
        "cellNumberOrLandline",
        ([cellNumberOrLandline]) => {
          return cellNumberOrLandline !== "Cell"
            ? Yup.string().notRequired()
            : Yup.string().required("Account Number is Required");
        }
      ),
      howToActivate: Yup.string().required("Select Any One"),
      fastPort: Yup.string().required("Select Any One"),
      letterOfAuthorization: Yup.string().test(
        "is-string",
        "Signature is Required",
        function (value) {
          if (typeof value === "string" && value.trim() !== "") {
            return true;
          }
          return this.createError({ message: "Signature is Required" });
        }
      ),
      billingStatement: Yup.string().when(
        "portingService",
        ([portingService]) => {
          return portingService !== "Entire Service"
            ? Yup.string().notRequired()
            : Yup.string().required("Billing Statement is Required");
        }
      ),
    }),
    onSubmit: (values) => {
      dispatch(
        openModal({
          path: "PortRequestSummary",
          data: {
            title: "Summary",
            footer: false,
            formikValues: {
              ...values,
              phoneNumber: values.phoneNumber
                ? values.phoneNumber?.toString()
                : "",
            },
            handleNewPortReq,
            modalClass: "summary_modal",
          },
        })
      );
    },
  });

  useEffect(() => {
    subGroupId && fetchSubGroupNames(subGroupId);
  }, [subGroupId]);

  useEffect(() => {
    if (submitButtonClicked && Object.keys(validation.errors).length > 0) {
      setAccordionState1("0");
      ("subGroupId" in validation.errors ||
        "brandId" in validation.errors ||
        "campaignId" in validation.errors ||
        "comments" in validation.errors) &&
        setAccordionState2("0");
      setAccordionState3("0");
      setSubmitButtonClicked(false);
    }
  }, [submitButtonClicked, validation.errors]);

  const fetchSubGroupNames = async (ids: any) => {
    if (ids.length > 0) {
      try {
        let data: any = await subgroupListApi(
          1,
          100,
          removeEmptyAndNullValues({
            groupId: validation.values.groupId || "",
            subGroupId: ids,
          })
        );

        if (!data?.code && !data?.errorCode) {
          setSubgroupTemp(data?.records || []);
        }
      } catch (error: any) {
        console.error("Error fetching sub group names:", error);
      }
    } else {
      setSubgroupTemp([]);
    }
  };

  const handleNewPortReq = () => {
    const cb = () => {
      onCloseSubject.next({});
      props.router.navigate("/port-numbers");
      validation.resetForm();
      setSubgroupTemp([]);
    };

    submitLoadingSubject.next(true);
    dispatch(
      createPortNumberRequestThunk(
        removeEmptyAndNullValues({
          ...validation.values,
          phoneNumber: validation.values.phoneNumber
            ? validation.values.phoneNumber?.toString()
            : "",
        }),
        cb
      )
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const handleCancelClick = () => {
    validation.resetForm();
    setSubgroupTemp([]);
  };

  const handlePdfGenerate = async (cb2: any) => {
    const content: any = document.getElementById("pdfContent");
    content.style.display = "block";

    try {
      const pdf = new jsPDF("p", "mm", "a4");
      const pageHeight = 295; // A4 page height in mm
      const pageWidth = 210; // A4 page width in mm
      let position = 0;

      const canvas = await html2canvas(content, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 0.75); // Use JPEG and set quality to 0.75
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      pdf.addImage(
        imgData,
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "JPEG",
          0,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );
        heightLeft -= pageHeight;
      }

      const blobPDF = new Blob([pdf.output("blob")], {
        type: "application/pdf",
      });

      // pdf.save("document.pdf");
      // const blobUrl = URL.createObjectURL(blobPDF);
      // window.open(blobUrl);

      const cb = async (files: any) => {
        await validation.setTouched({
          letterOfAuthorization: true,
        });
        validation.setFieldValue("letterOfAuthorization", files?.[0]?.path);
        validation.setFieldValue("signature", {});
      };

      const formData = new FormData();
      formData.append("authorizationPdf", blobPDF);
      dispatch(fileUploadThunk(formData, true, cb)).then(() => {
        cb2();
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      cb2();
    } finally {
      // Hide the content again
      content.style.display = "none";
      cb2();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="New Port Request"
            isBack={true}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : "/port-numbers"
              )
            }
          />
          <div className="position-relative wrapper">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                setSubmitButtonClicked(true);
              }}
            >
              <FocusError formik={validation} />

              {/* <Accordion
                className="accordion-flush card"
                defaultActiveKey="0"
                alwaysOpen={false}
                activeKey={
                  Object.keys(validation.errors).length > 0 ? "0" : undefined
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header id="flush-headingOne">
                    <h6 className="card-title mb-0">1. Check Portability</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: "var(--tb-card-color)" }}>
                    <NPR1 validation={validation} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}

              <Accordion
                className="accordion-flush card"
                defaultActiveKey={accordionState1}
                alwaysOpen={false}
                activeKey={accordionState1}
                onSelect={(eventKey: any) => {
                  setAccordionState1(eventKey);
                }}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header id="flush-headingOne">
                    <h6 className="card-title mb-0">Porting Details</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: "var(--tb-card-color)" }}>
                    <NPR2
                      validation={validation}
                      diffThanOwner={diffThanOwner}
                      setDiffThanOwner={setDiffThanOwner}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion
                className="accordion-flush card"
                defaultActiveKey={accordionState2}
                alwaysOpen={false}
                activeKey={accordionState2}
                onSelect={(eventKey: any) => {
                  setAccordionState2(eventKey);
                }}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header id="flush-headingOne">
                    <h6 className="card-title mb-0">Advanced Settings</h6>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: "var(--tb-card-color)" }}>
                    <NPR3
                      validation={validation}
                      subgroupTemp={subgroupTemp}
                      setSubgroupTemp={setSubgroupTemp}
                      groupDetails={groupDetails}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion
                className="accordion-flush card"
                defaultActiveKey={accordionState3}
                alwaysOpen={false}
                activeKey={accordionState3}
                onSelect={(eventKey: any) => {
                  setAccordionState3(eventKey);
                }}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header id="flush-headingOne">
                    <div className="d-sm-flex align-items-center justify-content-between w-100 ml-auto mr-0">
                      <h6 className="card-title mb-0">
                        Additional Requirements
                      </h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: "var(--tb-card-color)" }}>
                    <NPR4
                      validation={validation}
                      handlePdfGenerate={handlePdfGenerate}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  className="btn btn-outline-primary"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={loading}>
                  {loading && <Spinner size="sm" animation="border" />} View
                  Summary
                </Button>
              </div>
            </Form>

            <Card
              className="overflow-hidden"
              id="pdfContent"
              style={{
                width: "210mm",
                display: "none", // Initially hidden
              }}
            >
              <Card.Body className="z-1 position-relative p-5 ms-4 me-4">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <img
                      src={logoDark}
                      className="card-logo"
                      alt="logo light"
                      height="120"
                    />
                  </div>
                  <div className="flex-shrink-0 my-auto">
                    <h5 className="fs-2xl mb-0">
                      Signal House, LLC Letter of Authorization
                    </h5>
                  </div>
                </div>
                <div className="mt-5 fs-lg">
                  <p>1. Name (as it appears on your bill):</p>
                  <table className="table table-bordered border-black">
                    <tbody>
                      <tr>
                        <td className="w-50">
                          Name:{" "}
                          <span>{validation.values.ownersName || ""}</span>
                        </td>
                        <td className="w-50">
                          Email:{" "}
                          <span>{validation.values.ownersEmail || ""}</span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="w-100">
                          Business Name (if the service is in company’s name):{" "}
                          <span>{validation.values.businessName || ""}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 pt-2 fs-lg">
                  <p>
                    2. Address on file with your current carrier (cannot be a PO
                    Box):
                  </p>
                  <table className="table table-bordered border-black">
                    <tbody>
                      <tr>
                        <td colSpan={3} className="w-100">
                          Address:{" "}
                          <span>
                            {validation.values.serviceAddress || ""},{" "}
                            {validation.values.serviceExtendedAddress || ""}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "33%" }}>
                          City:{" "}
                          <span>{validation.values.serviceCity || ""}</span>
                        </td>
                        <td style={{ width: "33%" }}>
                          State:{" "}
                          <span>{validation.values.serviceState || ""}</span>
                        </td>
                        <td style={{ width: "33%" }}>
                          Zip:{" "}
                          <span>
                            {validation.values.servicePostalCode || ""}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 pt-2 fs-lg">
                  <p>
                    3. Phone numbers to port from your current provider to
                    Signal House, LLC:
                  </p>
                  <div style={{ minHeight: "112px" }}>
                    <div style={{ width: "100%" }}>
                      {groupArray(
                        validation.values.phoneNumberToBePorted,
                        3
                      )?.map((dt) => (
                        <div style={{ display: "flex" }}>
                          {dt?.map((dt2: string) => (
                            <div
                              style={{ width: "33%", textAlign: "center" }}
                              className="border border-black pdftablecell"
                            >
                              {dt2}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div>
                  <p className="mt-4 pt-2 mb-5 pb-2 fs-md">
                    By signing the below, I verify that I am, or represent (for
                    a business), the above-named service customer, authorized to
                    change the primary carrier(s) for the telephone number(s)
                    listed, and am at least 18 years of age. The name and
                    address I have provided is the name and address on record
                    with my local telephone company for each telephone number
                    listed. I authorize Signal House, LLC or its designated
                    agent to act on my behalf and notify my current carrier(s)
                    to change my preferred carrier(s) for the listed number(s)
                    and service(s), to obtain any information Signal House, LLC
                    deems necessary to make the carrier change(s).
                  </p>

                  <div className="d-flex align-items-end justify-content-between">
                    <div
                      className="invoice-signature text-center"
                      style={{ minWidth: 150 }}
                    >
                      {validation.values.signature?.type && (
                        <img
                          src={URL.createObjectURL(validation.values.signature)}
                          alt=""
                          id="sign-img"
                          height="50"
                        />
                      )}
                      <h6 className="mb-0 mt-2 fs-md border-top border-black pt-2">
                        Signature
                      </h6>
                    </div>
                    <div
                      className="invoice-signature text-center"
                      style={{ minWidth: 150 }}
                    >
                      <h6 className="mb-0 mt-2 fs-md border-top border-black pt-2">
                        Print
                      </h6>
                    </div>
                    <div
                      className="invoice-signature text-center"
                      style={{ minWidth: 150 }}
                    >
                      <p className="mb-0 fs-lg">
                        {getCurrentDateTime("DD/MM/YYYY")}
                      </p>
                      <h6 className="mb-0 mt-2 fs-md border-top border-black pt-2">
                        Date
                      </h6>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(NewPortRequest);
