import {
  paymentHistoryApi,
  createPaymentIntentApi,
  getPaymentMethodApi,
  attachPaymentMethodIdApi,
  deletePaymentMethodIdApi,
  createCustomerApi,
  payWithPaymentMethodApi,
  getAutoRechargeConfigApi,
  configAutoRechargeApi,
  updateAutoRechargeConfigApi,
  getPaymentDetailApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  paymentHistoryDataSuccessful,
  paymentHistoryDataFailed,
  createPaymentIntentSuccessful,
  createPaymentIntentFailed,
  paymentMethodDataSuccessful,
  paymentMethodDataFailed,
  attachPaymentMethodIdSuccessful,
  attachPaymentMethodIdFailed,
  deletePaymentMethodIdSuccessful,
  deletePaymentMethodIdFailed,
  createCustomerSuccessful,
  createCustomerFailed,
  payWithPaymentMethodSuccessful,
  payWithPaymentMethodFailed,
  autoRechargeDataSuccessful,
  autoRechargeDataFailed,
  configAutoRechargeSuccessful,
  configAutoRechargeFailed,
  updateAutoRechargeConfigSuccessful,
  updateAutoRechargeConfigFailed,
} from "./reducer";
import { toast } from "react-toastify";
import { getAuthUserThunk } from "slices/thunk";

export const getPaymentHistoryThunk =
  (page?: number, limit?: number) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await paymentHistoryApi(page, limit);

      if (data?.code || data?.message) {
        dispatch(paymentHistoryDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(paymentHistoryDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(paymentHistoryDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const createPaymentIntentThunk =
  (amount: number, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await createPaymentIntentApi(amount);

      if (data?.code || data?.message) {
        dispatch(createPaymentIntentFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(createPaymentIntentSuccessful(data));
        cb && cb(data);
      }
    } catch (error: any) {
      dispatch(createPaymentIntentFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getPaymentMethodThunk = () => async (dispatch: any) => {
  dispatch(apiLoading2());

  const createInitCust = () => {
    dispatch(createCustomerThunk()).then(() => {
      dispatch(getPaymentMethodThunk());
    });
  };

  try {
    const data: any = await getPaymentMethodApi();

    if (data?.code || data?.errorCode) {
      dispatch(paymentMethodDataFailed(data));
      if (
        data?.errorCode === "SH-WALLET-INVALID_DATA" &&
        data?.message === "Customer not found"
      ) {
        createInitCust();
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } else {
      dispatch(paymentMethodDataSuccessful(data?.records));
    }
  } catch (error: any) {
    dispatch(paymentMethodDataFailed(error));

    if (
      error?.response?.data?.errorCode === "SH-WALLET-INVALID_DATA" &&
      error?.response?.data?.message === "Customer not found"
    ) {
      createInitCust();
    } else {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  }
};

export const attachPaymentMethodIdThunk =
  (paymentMethodId: string, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await attachPaymentMethodIdApi(paymentMethodId);

      if (data?.errorCode) {
        dispatch(attachPaymentMethodIdFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(attachPaymentMethodIdSuccessful(data));
        toast.success("Payment method added successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(attachPaymentMethodIdFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const deletePaymentMethodIdThunk =
  (paymentMethodId: string, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await deletePaymentMethodIdApi(paymentMethodId);

      if (data?.code || data?.errorCode) {
        dispatch(deletePaymentMethodIdFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(deletePaymentMethodIdSuccessful(data));
        toast.success("Payment method deleted successfully");
        dispatch(getPaymentMethodThunk());
        cb && cb();
      }
    } catch (error: any) {
      dispatch(deletePaymentMethodIdFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const createCustomerThunk = (cb?: any) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    let data: any = await createCustomerApi();

    if (data?.errorCode) {
      dispatch(createCustomerFailed(data));
      // toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(createCustomerSuccessful(data));
      // toast.success(data?.message || "Customer created successfully");
    }
    cb && cb();
  } catch (error: any) {
    dispatch(createCustomerFailed(error));
    // toast.error(error?.response?.data?.message || "Something went wrong");
    cb && cb();
  }
};

export const payWithPaymentMethodThunk =
  (paymentMethodId: string, amount: number, cb?: any) =>
  async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await payWithPaymentMethodApi(paymentMethodId, amount);

      if (data?.transactionId) {
        const timer = setInterval(() => {
          dispatch(
            getPaymentDetailThunk(data?.transactionId, cb, () =>
              clearInterval(timer)
            )
          );
        }, 2000);
      } else {
        dispatch(payWithPaymentMethodFailed(data));
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error: any) {
      dispatch(payWithPaymentMethodFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getPaymentDetailThunk =
  (transactionId: string, cb?: any, stopInterval?: any) =>
  async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await getPaymentDetailApi(transactionId);

      if (data?.some((dt: any) => dt?.eventType === "charge.succeeded")) {
        dispatch(payWithPaymentMethodSuccessful(data));
        toast.success("Amount added successfully");
        dispatch(getAuthUserThunk());
        stopInterval();
        cb && cb();
      } else if (data?.some((dt: any) => dt?.eventType === "charge.failed")) {
        dispatch(payWithPaymentMethodFailed(data));
        toast.error(data?.message || "Something went wrong");
        stopInterval();
      }
    } catch (error: any) {
      dispatch(payWithPaymentMethodFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getAutoRechargeConfigThunk = () => async (dispatch: any) => {
  dispatch(apiLoading2());

  try {
    const data: any = await getAutoRechargeConfigApi();

    if (data?.code || data?.errorCode) {
      dispatch(autoRechargeDataFailed(data));
      data?.errorCode !== "SH-WALLET-INVALID_DATA" &&
        toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(autoRechargeDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(autoRechargeDataFailed(error));
    error?.response?.data?.errorCode !== "SH-WALLET-INVALID_DATA" &&
      toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const configAutoRechargeThunk =
  (confData: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await configAutoRechargeApi(confData);

      if (data?.errorCode) {
        dispatch(configAutoRechargeFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(configAutoRechargeSuccessful(data));
        dispatch(getAutoRechargeConfigThunk());
        toast.success(data?.message);
        cb && cb(data);
      }
    } catch (error: any) {
      dispatch(configAutoRechargeFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateAutoRechargeConfigThunk =
  (confData: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await updateAutoRechargeConfigApi(confData);

      if (data?.code || data?.errorCode) {
        dispatch(updateAutoRechargeConfigFailed(data?.message));
        toast.error("Something went wrong");
      } else {
        dispatch(updateAutoRechargeConfigSuccessful({}));
        dispatch(getAutoRechargeConfigThunk());
        toast.success(data?.message);
        cb && cb(data);
      }
    } catch (error: any) {
      dispatch(updateAutoRechargeConfigFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
