import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import React, { useState, useEffect } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NumberProperties from "./NumberProperties";
import ConfigureNumbers from "./ConfigureNumbers";
import EventsLog from "./EventsLog";
import SmsAndMmsLogs from "../Messaging/SmsAndMmsLogs";
import { setOwnNumberDetailInitialState } from "slices/thunk";
import { getLocal } from "helpers/services/auth/auth";

const OwnNumberDetails: React.FC = (props: any) => {
  const { phoneNumber } = useParams();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const [tag, setActiveTag] = useState<any>("properties");
  const dispatch = useDispatch<any>();

  // Retrieve phone number details from the Redux store
  const phoneNumberDetails = useSelector(
    (state: any) => state?.Numbers?.numberDetails
  );

  useEffect(() => {
    return () => {
      dispatch(setOwnNumberDetailInitialState());
    };
  }, []);

  const renderStatusBadge = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case "mt":
      case "false":
      case "in-progress":
        return (
          <span
            className="badge bg-primary-subtle text-primary"
            style={{
              width: "80px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            {status?.replace("-", " ")}
          </span>
        );
      case "ready":
        return (
          <span
            className="badge bg-secondary-subtle text-secondary"
            style={{
              width: "80px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            {status}
          </span>
        );
      case "pending":
        return (
          <span
            className="badge bg-dark-subtle text-dark"
            style={{
              width: "80px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            {status}
          </span>
        );
      default:
        return (
          <span
            className="badge bg-danger-subtle text-danger"
            style={{
              width: "80px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            {status || "N/A"}
          </span>
        );
    }
  };

  return (
    <div className="container-fluid page-content">
      <BreadCrumb
        title={
          <>
            {phoneNumber || "N/A"}{" "}
            <span style={{ marginBottom: "6px", marginLeft: "6px" }}>
              <span>
                {phoneNumberDetails?.tenDLCWhiteListStatus &&
                  renderStatusBadge(
                    phoneNumberDetails.tenDLCWhiteListStatus || "N/A"
                  )}
              </span>
            </span>
          </>
        }
        isBack={true}
        backClick={() => {
          const currentPath = props.router?.location?.pathname;

          if (currentPath.startsWith("/release-number-details/")) {
            getLocal("numberSortingRoute")
              ? props.router?.navigate(getLocal("numberSortingRoute"))
              : props.router?.navigate("/release-number");
          } else if (subGroupId) {
            props.router?.navigate(
              `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
            );
          } else {
            getLocal("numberSortingRoute")
              ? props.router?.navigate(getLocal("numberSortingRoute"))
              : props.router?.navigate("/mynumbers");
          }
        }}
      />

      <Card>
        <Card.Body>
          <Tab.Container activeKey={tag} defaultActiveKey="properties">
            <Nav
              as="ul"
              variant="tabs"
              className="nav-justified nav-border-top nav-border-top-primary mb-3"
              role="tablist"
            >
              <Nav.Item onClick={() => setActiveTag("properties")} as="li">
                <Nav.Link eventKey="properties">
                  <i className="ri-home-5-line align-middle me-1"></i>{" "}
                  Properties
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setActiveTag("configure")} as="li">
                <Nav.Link eventKey="configure">
                  <i className="ri-settings-2-line align-middle me-1"></i>{" "}
                  Configure
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setActiveTag("messages-log")} as="li">
                <Nav.Link eventKey="messages-log">
                  <i className="ri-message-3-line align-middle me-1"></i>{" "}
                  Messages Log
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setActiveTag("events-log")} as="li">
                <Nav.Link eventKey="events-log">
                  <i className="ri-calendar-event-line align-middle me-1"></i>{" "}
                  Events Log
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="properties">
                <NumberProperties router={props.router} />
              </Tab.Pane>
              <Tab.Pane eventKey="configure">
                {tag === "configure" && <ConfigureNumbers />}
              </Tab.Pane>
              <Tab.Pane eventKey="messages-log">
                {tag === "messages-log" && (
                  <SmsAndMmsLogs phoneNumber={phoneNumber} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="events-log">
                {tag === "events-log" && (
                  <EventsLog phoneNumber={phoneNumber} />
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default withRouter(OwnNumberDetails);
