import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
  loading: false,
  loading2: false,
  analyticsAllV1: {},
  analyticsSmsV1: {},
  analyticsMmsV1: {},
  analyticsAllbound: {},
  analyticsOutbound: {},
  analyticsInbound: {},
  smsStatusLogs: {},
  mmsStatusLogs: {},
  statusLogs: {},
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
};

const analyticsCountSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    analyticsSmsV1Successful(state, action) {
      state.loading = false;
      state.analyticsSmsV1 = action.payload;
      // state.analyticsAllV1 = {
      //   ...state.analyticsAllV1,
      //   ["SMS"]: action?.payload?.data || {},
      // };
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    analyticsSmsV1Failed(state, action) {
      state.loading = false;
      state.analyticsSmsV1 = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    analyticsMmsV1Successful(state, action) {
      state.loading = false;
      state.analyticsMmsV1 = action.payload;
      state.analyticsAllV1 = {
        ...state.analyticsAllV1,
        ["SMS"]: state.analyticsSmsV1?.data || {},
        ["MMS"]: action?.payload?.data || {},
      };
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    analyticsMmsV1Failed(state, action) {
      state.loading = false;
      state.analyticsMmsV1 = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    analyticsOutboundSuccessful(state, action) {
      state.loading = false;
      state.analyticsOutbound = action.payload;
      // state.analyticsAllbound = {
      //   ...state.analyticsAllbound,
      //   ["outbound"]: action?.payload?.data || {},
      // };
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    analyticsOutboundFailed(state, action) {
      state.loading = false;
      state.analyticsOutbound = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    analyticsInboundSuccessful(state, action) {
      state.loading = false;
      state.analyticsInbound = action.payload;
      state.analyticsAllbound = {
        ...state.analyticsAllbound,
        ["outbound"]: state.analyticsOutbound?.data || {},
        ["inbound"]: action?.payload?.data || {},
      };
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    analyticsInboundFailed(state, action) {
      state.loading = false;
      state.analyticsInbound = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    smsStatusLogsSuccessful(state, action) {
      state.loading2 = false;
      state.smsStatusLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    smsStatusLogsFailed(state, action) {
      state.loading2 = false;
      state.smsStatusLogs = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    mmsStatusLogsSuccessful(state, action) {
      state.loading2 = false;
      state.mmsStatusLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    mmsStatusLogsFailed(state, action) {
      state.loading2 = false;
      state.mmsStatusLogs = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    statusLogsSuccessful(state, action) {
      state.loading2 = false;
      state.statusLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    statusLogsFailed(state, action) {
      state.loading2 = false;
      state.statusLogs = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  analyticsSmsV1Successful,
  analyticsSmsV1Failed,
  analyticsMmsV1Successful,
  analyticsMmsV1Failed,
  analyticsOutboundSuccessful,
  analyticsOutboundFailed,
  analyticsInboundSuccessful,
  analyticsInboundFailed,
  smsStatusLogsSuccessful,
  smsStatusLogsFailed,
  mmsStatusLogsSuccessful,
  mmsStatusLogsFailed,
  statusLogsSuccessful,
  statusLogsFailed,
} = analyticsCountSlice.actions;

export default analyticsCountSlice.reducer;
